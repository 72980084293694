import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import HealthEdit from './HealthEdit';

const Health = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.EMP_HEALTH} type = "Select"  name = "EMPLOYEE HEALTH" value = {data.EMP_HEALTH}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "VALUE" value = {data.VALUE}/>          
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <HealthEdit options = {props.ad.EMP_HEALTH} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Health