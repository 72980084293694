import classes from '../Pages.module.css';
import {Row,Col, Tabs, Skeleton, message} from 'antd';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";
import BasicChargeDetails from './BasicChargeDetails';
import ChargeDetails from './ChargeDetails';

const { TabPane } = Tabs;

const AdvanceFormView = (props) => {

    const employeeData = useContext(DataContext)
    const history = useHistory();

    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState(null)
    const [ad, setAD] = useState(null)
    const [storeData, setStoreData] = useState(null)

    useEffect(() => {

        setAllData(null)
        setStoreData(null)
        setAD(null)
        setLoading(false)
        axios
        .get(employeeData.URL + '/api/v1/charges/additional-data',{
            withCredentials: true
        })
        .then((response) => {

            console.log(response);
            setAD(ad => {
                let newad = response.data.data
                return({
                    ...newad,
                  
                });
            })
        })
        .catch((error) => {
            console.log(error);
        })

        axios
            .get(employeeData.URL + '/api/v1/charges/' + props.match.params.id,{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAllData(allData => {
                    const newData = response.data.data
                    return newData
                })

                setStoreData(allData => {
                    const newData = response.data.data
                    return newData
                })

            })
            .catch((error) => {
                console.log(error);
            })
        
            
    }, [])

    const handleSave = (event) => {

        var formv = true
        let count = 0;
        allData.details.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In SALARY ADVANCE DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })
        
        if(formv){

            console.log("Submitting");
           
            const postData = {
              "header": allData.header.rows,
              "details": allData.details.rows,
            }

            const postDataS = {
                "header": storeData.header.rows,
                "details": storeData.details.rows,
            }
            
            postData.details.forEach((lang,index) => {

                if(!lang.ORDER_NO){
                    postData.details[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.details[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.details.forEach((lang,index) => {
                const dataIndex = postData.details.findIndex(element => element.ORDER_NO === lang.ORDER_NO)
                if(dataIndex === -1){
                    postData.details.push({
                        ORDER_NO: lang.ORDER_NO,
                        PARAM: "DELETE"
                    })
                }
            })
            
            console.log(JSON.stringify(postData, undefined, 2));
            setLoading(true)
            axios
              .patch(employeeData.URL + '/api/v1/charges/' + postData.header[0]['C_CAT'], postData,  {
                  withCredentials: true,
                  credentials: 'include',
              })
              .then((response) => {
                  message.success({
                      content: 'CHARGE DEFINITION Updated Successfully!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '2vh',
                      },
                  });
                  setLoading(false)
                  history.replace('/sales/chargedefMaster/charge-details')
              })
              .catch((err) => {
                  message.error({
                      content: 'An Error Occurred!!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '2vh',
                      },
                  });
                  setLoading(false)
              });
          }
    }

    const handleCancel = (event) => {

        setLoading(false)
        history.replace('/sales/chargedefMaster/charge-details')
    }

    return(
        allData && storeData && ad
        ?
        <Row className = {classes['RowP']}>
            <Col lg = {editMode ? 13 : 19} md = {editMode ? 13 : 19} className = {classes['Col']} >

            </Col>
            {
                editMode
                ?
                <>
                   <Col md = {4} >
                        {
                          loading
                          ?
                          <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                          :
                          <button style = {{ backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                        }
                        
                    </Col>
                    <Col md = {4} >
                        {
                          loading
                          ?
                          <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                          :
                          <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancel(event)} className = {classes['ProfileButton']}>Cancel</button>
                        }
                    </Col>
                </>
                :
                <Col md = {4} >
                    <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Details</button>
                </Col>
            }
            <Col lg = {1} md = {1} >

            </Col>
            <Col lg = {24} md = {24} className = {classes['Col']}>
                <p></p>
                <div className = {classes['EditView']}>
                    <Tabs defaultActiveKey="0" centered style = {{ height: "100%"}} tabPosition={"left"} type = "line" >
                        <TabPane tab= {<span>Basic Details</span>} key="0">
                            <BasicChargeDetails create = {false} ad = {ad} setData = {setAllData} data = {allData.header.rows[0]} editMode = {editMode} />
                        </TabPane>
                        <TabPane tab= {<span>Charge Def Defintion</span>} key="1">
                            <p></p>
                            <ChargeDetails ad = {ad} editMode = {editMode}  setData = {setAllData} data = {allData.details.rows}/>
                        </TabPane>
                    </Tabs>
                </div>
            </Col>       
        
        </Row>
        :
        <>
            <Skeleton active = {true} />
            <Skeleton active = {true} />
            <Skeleton active = {true} />
            <Skeleton active = {true} />
        </>
    );
}

export default AdvanceFormView;