import { Card, Avatar } from 'antd';
import classes from './Dashboard.module.css';
import {MdEmail} from 'react-icons/md';
import {HiReceiptTax} from 'react-icons/hi';
import {AiFillPhone} from 'react-icons/ai';
import { Link } from 'react-router-dom';

const ProfileCard = (props) => {
    return(
        <Card className = {classes['PCard']}>
            <Avatar
                size={{
                xs: 24,
                sm: 32,
                md: 80,
                lg: 84,
                xl: 100,
                xxl: 100,
                }}
                style={{ color: 'white', backgroundColor: '#A2C4C6' }}
            >{props.data.vendorDetails.NAME ? props.data.vendorDetails.NAME[0] : '-'}</Avatar> 
            <p></p>
            <p className = {classes['Name']} >{props.data.vendorDetails.NAME ? props.data.vendorDetails.NAME : '-'}</p>
            {/* <p style = {{whiteSpace: "nowrap"}}><AiFillPhone className = {classes['Icon']}/> <MdEmail className = {classes['Icon']}/> <FaReceipt className = {classes['Icon']}/></p> */}
            <p><AiFillPhone className = {classes['Icon']}/><br></br>{props.data.vendorDetails.EMAIL ? props.data.vendorDetails.EMAIL : '-'}</p>
            <p></p>
            <p><MdEmail className = {classes['Icon']}/><br></br>{props.data.vendorDetails.MOBILE ? props.data.vendorDetails.MOBILE : '-'}</p>
            <p><HiReceiptTax className = {classes['Icon']}/><br></br>{props.data.vendorDetails.GST_NO ? props.data.vendorDetails.GST_NO : '-'}</p>
            <p></p>
            <Link to = "/vendor-portal/profile"><button className = {classes['ProfileButton']}>My Profile</button></Link>
        </Card>
    );
}

export default ProfileCard