import PieChart, {
    Connector,
  } from 'devextreme-react/pie-chart';
import { Chart, Series, CommonSeriesSettings, ArgumentAxis, Label, ValueAxis, Legend, Export, Tooltip, ZoomAndPan} from 'devextreme-react/chart';
import './style.css'

function titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
}

const DevCharts = (props) => {

    if(props.chartType !== "pie")
    return(
        <Chart id="chart"
            title= {titleCase(props.matdata.split("_").join(" ")) + " by " + titleCase(props.Y.split("_").join(" "))}
            dataSource={props.chartType === "pie" ? props.pieData : props.data}
            palette="Ocean"
        >     

            <CommonSeriesSettings
                argumentField={Object.keys(props.data[0])[1]}
                type={props.chartType}
                barPadding={0.1}
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >

            </CommonSeriesSettings>
            <ZoomAndPan
                valueAxis="both"
                argumentAxis="both"
                allowMouseWheel={true}
                panKey="shift" 
            />
            {
                Object.keys(props.data[0]).map((key,index) => {

                    if(index !== 1 && key !== "Total" && key !== "SNO")
                    return(
                        <Series
                            key = {index}
                            argumentField={Object.keys(props.data[0])[1]}
                            valueField={key}
                            name={key}
                        />
                    );
                    else return null
                })
            }
            <ArgumentAxis>
                <Label
                    wordWrap="none"
                    overlappingBehavior="rotate"
                />
            </ArgumentAxis>
            <ValueAxis
                tickInterval={1000}
                grid = {{visible: false}}
            />
            <Tooltip
                enabled={true}
            />
            <Legend visible={true}
                    horizontalAlignment="right"
                    verticalAlignment="top"></Legend>
            <Export enabled={true} />
        </Chart>
    );

    else{

        return(
            <>  
                <p></p>

                {
                    props.pieCol && props.pieCol !== "" &&
                    <PieChart
                        id="pieC"
                        type="doughnut"
                        dataSource={props.pieData}
                        palette="Ocean"
                        title={titleCase(props.matdata.split("_").join(" ")) + " for " + titleCase(props.pieCol.split("_").join(" "))}
                    >
                        <Series
                        argumentField="name"
                        valueField="value"
                        >
                        <Label visible={true}>
                            <Connector visible={true} width={1} />
                        </Label>
                        </Series>
                        <Tooltip
                            enabled={true}
                        />
                        <Export enabled={true} />
                    </PieChart>
                }
                {
                    props.pieRow && props.pieRow !== "" &&
                    <PieChart
                        id="pieR"
                        type="doughnut"
                        dataSource={props.pieRData}
                        palette="Ocean"
                        title={titleCase(props.matdata.split("_").join(" ")) + " for " + titleCase(props.pieRow.split("_").join(" "))}
                    >
                        <Series
                        argumentField="name"
                        valueField="value"
                        >
                        <Label visible={true}>
                            <Connector visible={true} width={1} />
                        </Label>
                        </Series>
                        <Tooltip
                            enabled={true}
                        />
                        <Export enabled={true} />
                    </PieChart>
                }
              
            </>
            
        )
    }
}

export default DevCharts