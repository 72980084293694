import classes from '../Pages.module.css';
import {Row,Col, Avatar, Tabs, Skeleton,  message, Modal, Input, Form, Button} from 'antd';
import { useState, useEffect, useContext} from 'react';
import CustomerDetails from './CustomerDetails';
import ContactDetails from './ContactDetails';
import OtherDetails from './OtherDetails';
import BrokerDetails from './BrokerDetails';
import DeliverySiteDetails from './DeliverySiteDetails';
import {FaLinkedin, FaFacebook} from 'react-icons/fa';
import {RiWhatsappFill} from 'react-icons/ri';
import {Link} from 'react-router-dom';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useHistory } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";

const { TabPane } = Tabs;

const Analysis = (props) => {

    const customerData = useContext(DataContext)
    const history = useHistory();

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState(null)
    const [storeData, setStoreData] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [ad, setAd] = useState(null)
    const [password, setPassword] = useState({
        "currentPassword": "",
        "password": "",
        "passwordConfirm": ""
    })

    useEffect(() => {

        setAllData(null)
        setAd(null)
        setStoreData(null)
        axios
            .get(customerData.URL + '/api/v1/customer/profile',{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);   
                setAllData(allData => {
                    const newData = response.data.data
                    return newData
                })   
                
                setStoreData(storeData => {
                    const newData = response.data.data
                    return newData
                })   
            })
            .catch((error) => {
                console.log(error);
            })
        
        axios
            .get(customerData.URL + '/api/v1/customer/additional-data',{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);  
                setAd(ad => {
                    const newData = response.data.data

                    return newData
                })                 
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const handleSave = (event) => {

        var formv = true
        let count = 0;
        allData.contactDetails.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In CONTACT DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })

        count = 0;
        allData.brokerDetails.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In BROKER DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })

        count = 0;
        allData.deliverySites.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In DELIVERY SITE DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })
        
        if(formv){

            console.log("Submitting");                                                                                                                  
           
            const postData = {
                "customerDetails": allData.customerDetails,
                "contactDetails": allData.contactDetails.rows,
                "deliverySites": allData.deliverySites.rows,
                "brokerDetails": allData.brokerDetails.rows,
            }

            const postDataS = {
                "customerDetails": storeData.customerDetails,
                "contactDetails": storeData.contactDetails.rows,
                "deliverySites": storeData.deliverySites.rows,
                "brokerDetails": storeData.brokerDetails.rows,
            }
            
            postData.contactDetails.forEach((lang,index) => {

                if(!lang.UNIQUE_CODE){
                    postData.contactDetails[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.contactDetails[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.contactDetails.forEach((lang,index) => {
                const dataIndex = postData.contactDetails.findIndex(element => element.UNIQUE_CODE === lang.UNIQUE_CODE)
                if(dataIndex === -1){
                    postData.contactDetails.push({
                        UNIQUE_CODE: lang.UNIQUE_CODE,
                        PARAM: "DELETE"
                    })
                }
            })

            postData.brokerDetails.forEach((lang,index) => {

                if(!lang.UNIQUE_CODE){
                    postData.brokerDetails[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.brokerDetails[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.brokerDetails.forEach((lang,index) => {
                const dataIndex = postData.brokerDetails.findIndex(element => element.UNIQUE_CODE === lang.UNIQUE_CODE)
                if(dataIndex === -1){
                    postData.brokerDetails.push({
                        UNIQUE_CODE: lang.UNIQUE_CODE,
                        PARAM: "DELETE"
                    })
                }
            })

            postData.deliverySites.forEach((lang,index) => {

                if(!lang.DEL_SITE_CODE){
                    postData.deliverySites[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.deliverySites[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.deliverySites.forEach((lang,index) => {
                const dataIndex = postData.deliverySites.findIndex(element => element.DEL_SITE_CODE === lang.DEL_SITE_CODE)
                if(dataIndex === -1){
                    postData.deliverySites.push({
                        DEL_SITE_CODE: lang.DEL_SITE_CODE,
                        PARAM: "DELETE"
                    })
                }
            })
            
            console.log(JSON.stringify(postData, undefined, 2));
            setLoading(true)

            axios
              .patch(customerData.URL + '/api/v1/customer/profile', postData,  {
                  withCredentials: true,
                  credentials: 'include',
              })
              .then((response) => {
                  message.success({
                      content: 'Customer Updated Successfully!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '2vh',
                      },
                  });
                  setLoading(false)
                  history.replace('/customer-portal/profile')
              })
              .catch((err) => {
                  message.error({
                      content: 'An Error Occurred!!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '2vh',
                      },
                  });
                  setLoading(false)
              });
          }
    }

    const handleCancelSave = (event) => {
        
        setAllData(storeData)
        setEditMode(false)
    }

    const showModal = (event) => {
        setVisible(true);
    };

    const handleOk = () => {

        console.log(password);
        setConfirmLoading(true)
        axios
            .patch(customerData.URL + '/api/v1/users/changePassword', password,  {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                message.success({
                    content: 'Password Changed Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setConfirmLoading(false);  
                setVisible(false)                      
            })
            .catch((err) => {
                setConfirmLoading(false);
                message.error({
                    content: err.response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            });
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        setPassword({
            "currentPassword": "",
            "password": "",
            "passwordConfirm": ""
        })
    };

    
    const onChange = (e, param) => {
        if(param === "currentPassword") setPassword(prevState => ({
            ...prevState,
            currentPassword: e.target.value
        }))
        if(param === "password") setPassword(prevState => ({
            ...prevState,
            password: e.target.value
        }))
        if(param === "passwordConfirm") setPassword(prevState => ({
            ...prevState,
            passwordConfirm: e.target.value
        }))
    }

    return(
        <>  
            <Modal
                    title= "Change Password"
                    visible={visible}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                          Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form
                        layout="vertical"
                    >
                        <Form.Item 
                            label = {<div className={classes['Label']}>Current Password</div>}
                        >
                            <Input 
                                placeholder="Enter Current Password"
                                className = {classes["Input"]}
                                value = {password.currentPassword}
                                onChange = {(event) => onChange(event, "currentPassword")}
                            />
                        </Form.Item>
                        <Form.Item 
                            label = {<div className={classes['Label']}>New Password</div>}
                        >
                            <Input 
                                placeholder="Enter New Password"
                                className = {classes["Input"]}
                                value = {password.password}
                                onChange = {(event) => onChange(event, "password")}
                            />
                        </Form.Item>
                        <Form.Item 
                            label = {<div className={classes['Label']}>Confirm Password</div>}
                        >
                            <Input 
                                placeholder="Confirm Password"
                                className = {classes["Input"]}
                                value = {password.passwordConfirm}
                                onChange = {(event) => onChange(event, "passwordConfirm")}
                            />
                        </Form.Item>
                        
                    </Form>

                </Modal>
            {
                allData && storeData && ad
                ?
                <Row className = {classes['RowP']}>
                    <Col md = {16} className = {classes['Col']}>
                        <div className = {classes['EditView']}>
                            <Tabs defaultActiveKey="1" centered style = {{ height: "100%"}}>
                                <TabPane tab= {<span>Customer Details</span>} key="1">
                                    <CustomerDetails data = {allData.customerDetails} setData = {setAllData} ad = {ad} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Contact Details</span>} key="2">
                                    <ContactDetails data = {allData.contactDetails.rows} setData = {setAllData} ad = {ad} editMode = {editMode} />
                                </TabPane>
                                {
                                    editMode
                                    ?
                                    null
                                    :
                                    <TabPane tab= {<span>Broker Details</span>} key="3">
                                        <BrokerDetails editMode = {editMode} data = {allData.brokerDetails.rows} ad = {ad} setData = {setAllData}/>
                                    </TabPane>
                                }
                                <TabPane tab= {<span>Delivery Sites</span>} key="4">
                                    <DeliverySiteDetails editMode = {editMode} data = {allData.deliverySites.rows} ad = {ad} setData = {setAllData}/>
                                </TabPane>
                                <TabPane tab= {<span>Other Details</span>} key="5">
                                    <OtherDetails data = {allData.customerDetails} setData = {setAllData} ad = {ad} editMode = {editMode} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                    <Col md = {8} className = {classes['Col']}>
                        <p></p>
                        <Avatar
                            size={{
                            xs: 24,
                            sm: 32,
                            md: 80,
                            lg: 84,
                            xl: 100,
                            xxl: 100,
                            }}
                            style={{ color: 'white', backgroundColor: '#A2C4C6', margin: "3rem 0rem 1rem 0rem"}}
                        >{allData.customerDetails.DISTRIBUTOR_NAME[0].toUpperCase()}</Avatar> 
                        <p></p>
                        <p className = {classes['Name']} >{allData.customerDetails.DISTRIBUTOR_NAME}</p>
                        <p></p>
                        <Row className = {classes['RowS']}>
                            <Col lg = {4} md = {5} style = {{fontSize: "1.2rem"}}><Link to = "#"><FaLinkedin style = {{color: "#0077b5"}} /></Link></Col>
                            <Col lg = {4} md = {5} style = {{fontSize: "1.2rem"}}><Link to = "#"><FaFacebook style = {{color: "#4267B2"}} /></Link></Col>
                            <Col lg = {4} md = {5} style = {{fontSize: "1.2rem"}}><Link to = "#"><RiWhatsappFill style = {{color: "#128C7E"}} /></Link></Col>
                        </Row>
                        <p></p>
                        {
                            editMode
                            ?
                            <>  
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                                }
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancelSave(event)} className = {classes['ProfileButton']}>Cancel</button>
                                }
                                
                            </>
                            :
                            <>
                                <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Profile</button>
                                <button style = {{backgroundColor: "#CDB7F6", color: "#000028"}} onClick={(event) => showModal(event)} className = {classes['ProfileButton']}>Change Password</button>
                            </>
                        }      
                    </Col>
                </Row>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
    </>
    );
}

export default Analysis