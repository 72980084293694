import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import SiteDetailsEdit from './SiteDetailsEdit';

const OtherDetails = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((site, index) => {
            return(
                <div key = {index}>
                    <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                        <DataField editMode = {props.editMode} lg = {24} md = {24} name = "SITE NAME" value = {site.NAME}/>    
                        <DataField editMode = {props.editMode} lg = {24} md = {24} name = "ADDRESS" value = {site.ADD_1}/>  
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "LOCALITY" value = {site.LOCALITY}/>         
                        <DataField editMode = {props.editMode} lg = {12} md = {24} options = {props.ad.CITY} type = "Select" name = "CITY" value = {site.CITY}/>
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "PIN" value = {site.PIN}/>
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "STATE" value = {site.STATE_NAME}/>   
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "GST No." value = {site.GST_NUMBER}/>        
                    </Row>
                </div>
            );
        })
            
        :
            <SiteDetailsEdit cityOptions = {props.ad.CITY} data = {props.data} setData = {props.setData}/>
    );
}

export default OtherDetails