import { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import ToolRow from '../ToolRow/ToolRow';
import axios from 'axios';
import TableRenderer from "../TableRenderer/TableRenderer";
import { Skeleton } from 'antd';
import DataContext from '../../Context/dataContext';
import ViewEditor from "../ViewEditor/ViewEditor";
import DevCharts from '../DevCharts/DevCharts'

const months = { "01": "JAN", "02": "FEB", "03": "MAR", "04": "APR", "05": "MAY", "06": "JUN", "07": "JUL", "08": "AUG", "09": "SEP", "10": "OCT", "11": "NOV", "12": "DEC"}

const Sales = (props) => {

    const mainData = useContext(DataContext)
    const history = useHistory()
    const [columns, setColumns] = useState([])
    const [parentColumns, setParentColumns] = useState([])
    const [metaData, setMetaData] = useState([])
    const [rows, setRows] = useState([])
    const [fRows, setFRows] = useState([])
    const [reportData, setReportData] = useState({ matPos : 0})
    const [matrixColumns, setMatrixColumns] = useState([])
    const [matrixDataColumns, setMatrixDataColumns] = useState([])
    const [pieData, setPieData] = useState([])
    const [pieRData, setPieRData] = useState([])
    const [dFilter, setDFilter] = useState("all")
    const [loading, setLoading] = useState(false)
    const [ft, setFT] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [page, setPage] = useState(1)

    useEffect(() => {
        setDFilter("all")
        setFT(false)
    }, [props.editMode])

    useEffect(() => {

        if(page !== null && dFilter !== "all"){
            if(dFilter !== "fromTo")
            {
                setFT(false)
                setToDate("")
                setFromDate("")
                setLoading(true)
                axios
                .get(mainData.URL + '/api/v1/reports/'+ props.match.params.id + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString() + "&date=" + dFilter + "&page=" + page.toString(), {
                    withCredentials: true
                })
                .then((response) => {
                    setFRows(fRows => {
                        let newRows = response.data.data.reportData.rows.map((row,index) => {return(
                            {
                                "SNO": index + 1,
                                ...row
                            }
                        );})
                        return newRows;
                    })
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                });
            }

            else{
                setFT(true)
                if(fromDate !== "" && toDate !== ""){
            
                    setLoading(true)
                    let from = fromDate.split("-")[0] + "-" + months[fromDate.split("-")[1]] + "-" + fromDate.split("-")[2]
                    let to = toDate.split("-")[0] + "-" + months[toDate.split("-")[1]] + "-" + toDate.split("-")[2]
        
                    axios
                        .get(mainData.URL + '/api/v1/reports/'+ props.match.params.id + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString() + 
                                "&from=" + from + "&to=" + to + "&page=" + page.toString(), 
                        {
                            withCredentials: true
                        })
                        .then((response) => {
        
                            setFRows(fRows => {
                                let newRows = response.data.data.reportData.rows.map((row,index) => {return(
                                    {
                                        "SNO": index + 1,
                                        ...row
                                    }
                                );})
                                return newRows;
                            })
                            setLoading(false)
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        }

    }, [dFilter, page, fromDate, toDate])

    useEffect(() => {

        props.onModuleChangeHandler(props.match.params.cat.split("-").join(" "));
        props.onTitleChangeHandler(props.match.params.id.split("-").join(" ")); 
        mainData.setTableColumns(parentColumns)
        mainData.setParentReportData(reportData)
        if(props.match.params.edit !== "edit" && props.editMode) props.set();
        if(!props.editMode) if(props.match.params.edit === "edit") { history.replace('/reports/sales/'+props.match.params.id);}

    },[props, mainData, columns, reportData, parentColumns]);

    useEffect(() => {  

        if(props.match.params.edit !== "edit" && page !== null && dFilter === "all"){

            setFT(false);
            setToDate("")
            setFromDate("")
            mainData.setChartType("");
            mainData.setCharts(false)
            mainData.setColumnsToTotal(null)
            setReportData({
                report_cns: 0,
                report_matPos: 0,
            })
            setColumns([])
            setRows([])
            setMetaData([])
            setParentColumns([])
            axios
            .get(mainData.URL + '/api/v1/reports/'+ props.match.params.id + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString() + "&page=" + page.toString(),{
                withCredentials: true
            })
            .then((response) => {
                
                console.log(response);

                if(response.data.data.reportData.basis.REPORT_FORMAT.toLowerCase() === "matrix"){

                    setColumns(columns => {
                        let newCols = response.data.data.reportData.metaData.map((col) => {
                            return({
                                name: col.name,
                                title: col.name.split("_").join(" ").toLowerCase()
                            });
                        })
                        const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                        return newNewCols;
                    })

                    setRows(rows => {
                        let newRows = response.data.data.reportData.rows.map((row,index) => {return(
                            {
                                "SNO": index + 1,
                                ...row
                            }
                        );})
                        return newRows;
                    })

                    setReportData({
                        report_code: response.data.data.reportData.basis.REPORT_CODE,
                        report_name: response.data.data.reportData.basis.REPORT_NAME,
                        report_format:response.data.data.reportData.basis.REPORT_FORMAT,
                        report_desc: response.data.data.reportData.basis.DESCRIPTION,
                        report_filter: response.data.data.reportData.basis.FILTER_BY ? response.data.data.reportData.basis.FILTER_BY.split(" AND ") : [],
                        report_Tsort: response.data.data.reportData.basis.SORTING_BY ? response.data.data.reportData.basis.SORTING_BY.split(", ") : [],
                        report_Ssort: response.data.data.reportData.basis.SUMMARY_SORT_BY ? response.data.data.reportData.basis.SUMMARY_SORT_BY.split(", ") : [],
                        report_group: [],
                        report_matX: response.data.data.reportData.basis.MAT_X,
                        report_matY: response.data.data.reportData.basis.MAT_Y,
                        report_realMatX: response.data.data.reportData.basis.REAL_MAT_X,
                        report_matData: response.data.data.reportData.basis.MAT_DATA,
                        report_matAction: response.data.data.reportData.basis.MAT_ACTION,
                        report_cns: response.data.data.reportData.basis.CNS_APPLY,
                        report_matPos: response.data.data.reportData.basis.MAT_POS,
                        report_colToTotal: response.data.data.reportData.basis.COLS_TO_TOTAL ? response.data.data.reportData.basis.COLS_TO_TOTAL.split(", ") : [],
                    })

                    setMetaData(metaData => {
                        let newData = response.data.data.reportData.basis.dataTypes
                        return newData;
                    })

                    setParentColumns(parentColumns => {
                        let newPCols = response.data.data.reportData.basis.VIEW_FIELDS.split(", ")
                        return newPCols
                    })

                    setMatrixColumns(matrixColumns => {
                        let newMCols = response.data.data.reportData.basis.ALL_MAT_X.split(", ")
                        return newMCols
                    })

                    setMatrixDataColumns(matrixDataColumns => {
                        let newMDCols = response.data.data.reportData.basis.ALL_MAT_DATA.split(", ")
                        return newMDCols
                    })
                    
                    mainData.setTargetKeys(targetKeys => {
                        let newKeys = response.data.data.reportData.basis.REPORT_FIELDS.split(", ").map(
                            (col) => { let val = response.data.data.reportData.basis.VIEW_FIELDS.split(", ").findIndex((element) => element === col); return(val);})
                        console.log(newKeys);
                        return newKeys
                    })
                }

                else{

                    setColumns(columns => {
                        let newCols = response.data.data.reportData.metaData.map((col) => {
                            return({
                                name: col.name,
                                title: col.name.split("_").join(" ").toLowerCase()
                            });
                        })

                        const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                        return newNewCols;
                    })
    
                    setRows(rows => {
                        let newRows = response.data.data.reportData.rows.map((row,index) => {return(
                            {
                                "SNO": index + 1,
                                ...row
                            }
                        );})
                        return newRows;
                    })
    
                    setMetaData(metaData => {
                        let newData = response.data.data.reportData.basis.dataTypes
                        return newData;
                    })
    
                    setParentColumns(parentColumns => {
                        let newPCols = response.data.data.reportData.basis.VIEW_FIELDS.split(", ")
                        return newPCols
                    })

                    setMatrixColumns(matrixColumns => {
                        let newMCols = response.data.data.reportData.basis.ALL_MAT_X ? response.data.data.reportData.basis.ALL_MAT_X.split(", ") : [-1]
                        return newMCols
                    })

                    setMatrixDataColumns(matrixDataColumns => {
                        let newMDCols = response.data.data.reportData.basis.ALL_MAT_DATA ? response.data.data.reportData.basis.ALL_MAT_DATA.split(", ") : [-1]
                        return newMDCols
                    })
    
                    setReportData({
                        report_code: response.data.data.reportData.basis.REPORT_CODE,
                        report_name: response.data.data.reportData.basis.REPORT_NAME,
                        report_format:response.data.data.reportData.basis.REPORT_FORMAT,
                        report_desc: response.data.data.reportData.basis.DESCRIPTION,
                        report_filter: response.data.data.reportData.basis.FILTER_BY ? response.data.data.reportData.basis.FILTER_BY.split(" AND ") : [],
                        report_Tsort: response.data.data.reportData.basis.SORTING_BY ? response.data.data.reportData.basis.SORTING_BY.split(", ") : [],
                        report_Ssort: response.data.data.reportData.basis.SUMMARY_SORT_BY ? response.data.data.reportData.basis.SUMMARY_SORT_BY.split(", ") : [],
                        report_group: response.data.data.reportData.basis.GROUP_BY ? response.data.data.reportData.basis.GROUP_BY.split(", ") : [],
                        report_matX: response.data.data.reportData.basis.MAT_X,
                        report_matY: response.data.data.reportData.basis.MAT_Y,
                        report_realMatX: response.data.data.reportData.basis.REAL_MAT_X,
                        report_matData: response.data.data.reportData.basis.MAT_DATA,
                        report_matAction: response.data.data.reportData.basis.MAT_ACTION,
                        report_cns: response.data.data.reportData.basis.CNS_APPLY,
                        report_matPos: response.data.data.reportData.basis.MAT_POS,
                        report_colToTotal: response.data.data.reportData.basis.COLS_TO_TOTAL ? response.data.data.reportData.basis.COLS_TO_TOTAL.split(", ") : [],
                    })
                    
                    mainData.setTargetKeys(targetKeys => {

                        let newKeys = response.data.data.reportData.metaData.map(
                            (col) => { let val = response.data.data.reportData.basis.VIEW_FIELDS.split(", ").findIndex((element) => element === col.name); return(val);})
                        console.log(newKeys);
                        return newKeys
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }      
        
    }, [props.match.params.id, props.match.params.edit, mainData.company, mainData.site, page, dFilter])

    const onDChange = (value, param) => {

        setDFilter(value)
    }

    const onDateChange = (date, dateString) => {
        
        setFromDate(dateString[0])
        setToDate(dateString[1])

    }

    const onPageChange = (value) => {
        setPage(value)
    } 

    return(
        <>
            { !props.editMode && <ToolRow page = {page} onPageChange = {onPageChange} ft = {ft} fromDate = {fromDate} toDate = {toDate} onDateChange = {onDateChange} dFilter = {dFilter} onDChange = {onDChange} setPieData = {setPieData} setPieRData = {setPieRData} parentColumns = {parentColumns} metaData = {metaData} columns = {columns} data = {rows} editMode = {props.editMode} editModeHandler = {props.editModeHandler}/>}
            {
                reportData.report_format && reportData.report_colToTotal && reportData.report_group && parentColumns.length > 0 && matrixColumns.length > 0 && matrixDataColumns.length > 0 && metaData.length > 0 && columns.length > 0 && mainData.targetKeys.length > 0
                ?
                <>
                    {
                        props.editMode ?
                        <ViewEditor matrixColumns = {matrixColumns} matrixDataColumns = {matrixDataColumns} parentColumns = {parentColumns} metaData = {metaData} columns = {columns} data = {rows} /> :
                        <>
                            {
                                mainData.charts
                                ?
                                <> 
                                    <DevCharts data = {rows} pieData = {pieData} pieRData = {pieRData} pieRow = {mainData.pieRow} pieCol = {mainData.pieColumn} chartType = {mainData.chartType} X = {reportData.report_realMatX} Y = {reportData.report_matY} matdata = {reportData.report_matData}/>
                                </>
                                :
                                null
                            }
                            {
                                loading
                                ?
                                <>
                                    <Skeleton active = {true} />
                                    <Skeleton active = {true} />
                                    <Skeleton active = {true} />
                                </>
                                :
                                <TableRenderer metaData = {metaData} dFilter = {dFilter} fRows = {fRows} format = {reportData.report_format} ctt = {reportData.report_colToTotal} grouping = {reportData.report_group} columns = {columns} data = {rows} />
                            }
                        </>
                    }
                </>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
                    
        </>
        
    );
}

export default Sales