import classes from './SiderContentC.module.css'
import { Menu, message} from 'antd';
import { FaSignOutAlt, FaHandsHelping, FaMoneyCheckAlt, FaBusinessTime, FaReceipt,} from "react-icons/fa"
import signInSignUpLogo from '../../../assets/signInSignUp.png'
import {GiBuyCard,} from 'react-icons/gi'
import { AiOutlineDownSquare } from "react-icons/ai";
import {IoReceipt} from 'react-icons/io5'
import {MdDashboard, MdPersonPin} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { useHistory } from 'react-router';

const { SubMenu } = Menu;

const SiderContentC = (props) => {
    
    const history = useHistory()

    const handleLogout = (event) => {

        axios
        .get(props.url + '/api/v1/users/logout',{
            withCredentials: true
        })
        .then((response) => {
            message.success({
                content: 'Goodbye!!!! See You Again!!!',
                className: 'custom-class',
                style: {
                marginTop: '20vh',
                },
            });
            history.replace('/vendor')
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <>  
            <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo"/>
            <Menu className = {classes['Menu']}>
                <Menu.Item icon={<MdDashboard />}>   
                    <NavLink to = '/vendor-portal'>
                        Dashboard
                    </NavLink>
                </Menu.Item>
                <Menu.Item icon={<FaHandsHelping />}>   
                    <NavLink to = '/vendor-portal/sauda'>
                        Sauda
                    </NavLink>
                </Menu.Item>
                <Menu.Item icon={<GiBuyCard />}>   
                    <NavLink to = '/vendor-portal/purchase-order'>
                        Purchase Order
                    </NavLink>
                </Menu.Item>
               
                <Menu.Item icon={<AiOutlineDownSquare/>}>   
                    <NavLink to = '/vendor-portal/purchase-return'>
                        Purchase Return
                    </NavLink>
                </Menu.Item>
                <Menu.Item icon={<IoReceipt />}>   
                    <NavLink to = '/vendor-portal/purchase-bills'>
                        Purchase Bills
                    </NavLink>
                </Menu.Item>
                <Menu.Item icon={<FaMoneyCheckAlt />}>   
                    <NavLink to = '/vendor-portal/payments'>
                        Payments
                    </NavLink>
                </Menu.Item>
                <SubMenu key="custOut" title="Outstanding" icon = {<FaBusinessTime />}>
                    <Menu.Item key="outNetDue" >
                        <NavLink to = '/vendor-portal/outstanding/net-due'>
                            Net Due
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="outAge" >
                        <NavLink to = '/vendor-portal/outstanding/ageing-analysis'>
                            Ageing Analysis
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item icon={<FaReceipt />}>   
                    <NavLink to = '/vendor-portal/ledger'>
                        Ledger
                    </NavLink>
                </Menu.Item>
                <Menu.Item icon={<MdPersonPin />}>   
                    <NavLink to = '/vendor-portal/profile'>
                        Profile
                    </NavLink>
                </Menu.Item>
                <Menu.Item onClick = {(event) => handleLogout(event)} icon={<FaSignOutAlt style = {{color : "red", opacity: "0.7"}}/>}>   
                    <NavLink to = '/vendor-portal'>
                        Logout
                    </NavLink>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default SiderContentC