import {Row, Col, Card} from 'antd';
import classes from './Dashboard.module.css';
import DataCards from './DataCards';
import ProfileCard from './ProfileCard';
import {useEffect, useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import Charts from './Charts'

const Dashboard = (props) => {
    
    return(
        <>
            <Row gutter = {16} className = {classes['SiteLayoutBackground']}>
                <Col lg = {12} md = {24} className = {classes['ColC']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
                <Col lg = {12} md = {24} className = {classes['ColC']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
            </Row>
            <p></p>
            <Row gutter = {16} className = {classes['SiteLayoutBackground']}>
                <Col lg = {8} md = {8} className = {classes['ColX']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
                <Col lg = {8} md = {8} className = {classes['ColX']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
                <Col lg = {8} md = {8} className = {classes['ColX']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
            </Row>
            <p></p>
            <Row gutter = {16} className = {classes['SiteLayoutBackground']}>
                <Col lg = {16} md = {24} className = {classes['ColC']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
                <Col lg = {8} md = {24} className = {classes['ColC']}>
                    <Card className = {classes['Card']} >

                    </Card>
                </Col>
            </Row>
            <p></p>
        </>
    );
}

export default Dashboard