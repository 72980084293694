import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import BrokerDetailsEdit from './BrokerDetailsEdit';

const BrokerDetails = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((broker, index) => {
            return(
                <div key = {index}>
                    <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {24} md = {24} name = "BROKER NAME" value = {broker.BROKER_NAME}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "COMMISSION" value = {broker.COMMISSION}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "COMMISSION TYPE" value = {broker.COMMISSION_TYPE}/>          
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <BrokerDetailsEdit data = {props.data} setData = {props.setData} />
    );
}

export default BrokerDetails