import {Row, Col, Card} from 'antd';
import classes from './Dashboard.module.css';
import DataCards from './DataCards';
import ProfileCard from './ProfileCard';
import {useEffect, useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import Charts from './Charts'

const Dashboard = (props) => {

    const vendorData = useContext(DataContext)
    console.log(vendorData.analysis);
    
    return(
        <>
           <Row className = {classes['SiteLayoutBackground']}>
                <Col xl = {18} lg = {19} md = {24}>
                    <DataCards data = {vendorData.analysis}/>
                    <Row>
                        <Col lg = {{span: 24, offset: 0}} md = {24} xs = {24} className = {classes['ColC']}>
                            <Card className = {classes['Card']}>
                                <Charts chartType = "line" data = {vendorData.analysis.graphData.rows} pieData = {vendorData.analysis.pieData.rows} />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xl = {{span: 6, offset: 0}} lg = {{span: 5, offset: 0}} md = {24} className = {classes['ColX']}>  
                    <ProfileCard data = {vendorData.analysis} />
                </Col>
                <Col lg = {{span: 24, offset: 0}} md = {24} xs = {24} className = {classes['ColC']}>
                    <Card className = {classes['Card']}>
                        <Charts chartType = "pie" data = {vendorData.analysis.graphData.rows} pieData = {vendorData.analysis.pieData.rows} />
                    </Card>
                </Col>
            </Row>
            <p></p>
        </>
    );
}

export default Dashboard