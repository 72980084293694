import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const VendorDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = {...data['vendorDetails']}
            newdata[param] = e.target.value
            return({
                ...data,
                vendorDetails : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = {...data['vendorDetails']}
            newdata[param] = val
            return({
                ...data,
                vendorDetails : newdata
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = {...data['vendorDetails']}
            newdata[param] = dateString
            return({
                ...data,
                vendorDetails : newdata
            })
        })
    }

    return(
        <div>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "PARTY_NAME" name = "VENDOR NAME" value = {props.data.PARTY_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true} handleChange = {handleChange} param = "PARTY_CODE" name = "VENDOR CODE" value = {props.data.PARTY_CODE} /> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "CONTACT_PERSON" name = "CONTACT PERSON" value = {props.data.CONTACT_PERSON}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "A" name = "GROUP" value = "---"/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true}  handleChange = {handleChange} param = "TYPE_DESCRIPTION" name = "VENDOR TYPE" value = {props.data.TYPE_DESCRIPTION}/>             
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "ADD1" name = "ADDRESS 1" value = {props.data.ADD1}/> 
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "ADD2" name = "ADDRESS 2" value = {props.data.ADD2}/>          
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "CITY" name = "CITY" value = {props.data.CITY}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "PIN_NO" name = "PIN" value = {props.data.PIN_NO}/>
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "PH1" name = "PHONE 1" value = {props.data.PH1}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "PH2" name = "PHONE 2" value = {props.data.PH2}/>          
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "MOBILE" name = "MOBILE" value = {props.data.MOBILE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "FAX" name = "FAX" value = {props.data.FAX}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "EMAIL" name = "EMAIL" value = {props.data.EMAIL}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "URL" name = "URL" value = {props.data.URL}/>
            </Row>
            <p></p>
            <Row className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "BANK_NAME" name = "BANK NAME" value = {props.data.BANK_NAME}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true}  handleChange = {handleChange} param = "ACCOUNT_CODE" name = "ACCOUNT CODE" value = {props.data.ACCOUNT_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "IFSC_CODE" name = "IFSC Code" value = {props.data.IFSC_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "PAYMENT_DAYS" name = "PAYMENT DAYS" value = {props.data.PAYMENT_DAYS}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "COMMISSION" name = "COMMISSION" value = {props.data.COMMISSION}/>          
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "TIN_NO" name = "TIN No." value = {props.data.TIN_NO}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "PAN_NO" name = "PAN No." value = {props.data.PAN_NO}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "GST_REGISTERED" name = "GST REGISTERED" value = {props.data.GST_REGISTERED}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "COMPO_NO" name = "COMPOSITION NO." value = {props.data.COMPO_NO}/>          
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "VERIFIED" name = "VERIFIED" value ={props.data.VERIFIED}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date"  param = "VERIFY_DATE" name = "VERIFIED DATE" value ={props.data.VERIFY_DATE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "A" name = "NEXT CATEGORY" value = "---"/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true} handleChange = {handleDChange} type = "Date"  param = "NEXT_DATE" name = "NEXT DATE"  value ={props.data.NEXT_DATE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "SHORTAGE" name = "SHORTAGE"  value ={props.data.SHORTAGE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "SHORTAGE_ALLOWED" name = "SHORTAGE ALLOWED"  value ={props.data.SHORTAGE_ALLOWED}/>    
            </Row>
            <p></p>
        </div>
    );
}

export default VendorDetails