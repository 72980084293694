import {Row,Col, Form, InputNumber} from 'antd'
import classes from '../Pages.module.css';

const AgeForm = (props) => {

    const handleChange = (value,index) => {

        props.setForm(form => {
            let newform = [...form];
            newform[index] = value
            return(newform);
        })
    }

    return(
        <>
            <Form
                layout = "vertical">
                <Row gutter = {32} style = {{margin: "0.5rem"}} className = { classes['RowDE']}>
                    <Col md = {6}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                value = {0}
                                disabled = {true}
                                onChange = {event => handleChange(event,0)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", textAlign: "center", fontWeight: "900", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                        <p className = {classes['Text']}>TO</p>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                value = {props.form[0]}
                                onChange = {event => handleChange(event,0)}
                                style={{ width: "100%" , float: "left", backgroundColor : "transparent", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                    </Col> 
                    
                    <Col md = {6}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                disabled = {true}
                                value = {props.form[0] + 1}
                                onChange = {event => handleChange(event,0)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                        <p className = {classes['Text']}>TO</p>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                value = {props.form[1]}
                                onChange = {event => handleChange(event,1)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                    </Col> 
                    <Col md = {6}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                disabled = {true}
                                value = {props.form[1] + 1}
                                onChange = {event => handleChange(event,1)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                        <p className = {classes['Text']}>TO</p>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                value = {props.form[2]}
                                onChange = {event => handleChange(event,2)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                    </Col> 
                    <Col md = {6}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                value = {props.form[2] + 1}
                                disabled = {true}
                                onChange = {event => handleChange(event,2)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                        <p className = {classes['Text']}>TO</p>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                        >
                            <InputNumber 
                                min = {0}
                                placeholder="Enter Data"
                                bordered = {false}
                                value = {props.form[3]}
                                onChange = {event => handleChange(event,3)}
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                            />
                        </Form.Item>
                        <p></p>
                    </Col>   
                    <Col lg = {8} md = {24}>
                        <p></p>
                        <button onClick = {(event) => props.handleSave(event)}  className = {classes['ProfileButtonF']}>Calculate Ageing</button>
                        <p></p>
                    </Col>   
                </Row>
            </Form>
        </>
    )
}

export default AgeForm;