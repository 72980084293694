import { Scrollbars } from 'react-custom-scrollbars';
import classes from './ReportRepresentation.module.css';
import { Collapse } from 'antd';
import { Select } from 'antd';
import {useState} from 'react'
import {FaSortAlphaDown, FaSortAlphaUp} from 'react-icons/fa';
import { MinusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;

const Sortings = (props) => {

    const [dynamicColumns, setDynamicColumns] = useState([props.columns])

    // useEffect(() => {

    //     console.log(props.targetKeys);
    //     console.log(props.sortStrings);
    //     let sortInfo = []
    //     props.sortStrings.forEach((ss, index) => {
    //         let vals = ss.split(" ")
    //         sortInfo.push(
    //             {
    //                 key: (index + 1).toString(),
    //                 col: vals[0].toUpperCase(),
    //                 ascending: vals[1] === "ASC" ? true : false,
    //                 descending: vals[1] === "DESC" ? true : false,
    //             }
    //         )
    //     })

    //     console.log(sortInfo);
    //     props.setSortInfo(sortInfo);

    // }, [])

    const onChange = (value, key) => {

        const index = props.sortInfo.findIndex((element) => element.key === key)
        const removeIndex = dynamicColumns.findIndex((element) => element.value === value)

        setDynamicColumns((dynamicColumns) => {
            let newColumns = [...dynamicColumns]
            newColumns.splice(removeIndex, 1)
            return newColumns;
        })

        if(index === -1) {

            props.setSortInfo(sortInfo => {
                let newInfo = [...sortInfo]
                newInfo.push({
                    key: key,
                    col: value.toUpperCase(),
                    ascending: true,
                    descending: false,
                })
                return newInfo;
            })
            
        }

        else{

            props.setSortInfo(sortInfo => {
                let newInfo = [...sortInfo]
                newInfo[index].col = value.toUpperCase()
                return newInfo;
            })  
        }
    }

    const handleSort = (event, key) => {

        const index = props.sortInfo.findIndex((element) => element.key === key)
        if(index !== -1) {
            props.setSortInfo(sortInfo =>{
                let newInfo = [...sortInfo]
                if(newInfo[index]["ascending"]) { newInfo[index]["descending"] = true; newInfo[index]["ascending"] = false; } 
                else { newInfo[index]["descending"] = false; newInfo[index]["ascending"] = true; }
                return newInfo;
            })        
        }
    }

    const deleteItem = (event) => {
        props.setSortInfo(sortInfo =>{
            let newInfo = [...sortInfo]
            newInfo.pop()
            return newInfo;
        })  
    }
    return(
       
        <Scrollbars  autoHide autoHeight autoHeightMax={ ( window.innerHeight >= 748 ? window.innerHeight*0.55 : window.innerHeight*0.54 )}>
            <div className = {classes['TypeRow']}>
                <Collapse defaultActiveKey={['1']} ghost style = {{textAlign : "left", fontWeight : "bolder"}}>
                    <Panel header="Sort Information By" key="1">
                        <div style = {{textAlign: "left", fontWeight : "bolder"}}>
                            <Select
                                value = {props.sortInfo[0] ? props.sortInfo[0].col : null}
                                showSearch
                                bordered = {false}
                                dropdownStyle = {{textTransform: "capitalize"}}
                                onChange = {(value) => onChange(value, "1")}
                                style={{ width: "60%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    props.targetKeys.map((key) => {
                                        return(
                                            <Option style = {{textTransform: "capitalize"}} key = {key} value={props.parentColumns[key]}>{props.parentColumns[key].split('_').join(" ").toLowerCase()}</Option>
                                        );
                                    })
                                }
                            </Select>
                            {props.sortInfo.length === 1 && <MinusCircleOutlined className = {classes['Remove']} onClick = {(event) => deleteItem(event) }/>}
                            {
                                props.sortInfo[0] 
                                ? 
                                <button onClick = {(event) => handleSort(event,"1")} style = {{float : "left"}} className = {classes['SortButton']}>
                                    {props.sortInfo[0]["ascending"] ? <FaSortAlphaDown style = {{fontSize : "1.3rem"}} /> : <FaSortAlphaUp style = {{fontSize : "1.3rem"}} />}
                                </button> : null 
                            }
                        </div>
                    </Panel>
                </Collapse>
                {
                   props.sortInfo[0] && (props.sortInfo[0]["ascending"] || props.sortInfo[0]["descending"])
                   ? 
                   <Collapse defaultActiveKey={['2']} ghost>
                        <Panel header="And Then By" key="2" style = {{textAlign : "left", fontWeight : "bolder"}}>
                            <div style = {{textAlign: "left", fontWeight : "bolder"}}>
                                    <Select
                                        showSearch
                                        value = {props.sortInfo[1] ? props.sortInfo[1].col : null}
                                        bordered = {false}
                                        dropdownStyle = {{textTransform: "capitalize"}}
                                        onChange = {(value) => onChange(value, "2")}
                                        style={{ width: "60%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {
                                            props.targetKeys.map((key) => {

                                                if(props.parentColumns[key] !== props.sortInfo[0].col){
                                                    return(
                                                        <Option style = {{textTransform: "capitalize"}} key = {props.parentColumns[key]} value={props.parentColumns[key]}>{props.parentColumns[key].split('_').join(" ").toLowerCase()}</Option>
                                                    );
                                                }
                                                
                                                return null;
                                                
                                            })
                                        }
                                    </Select>
                                    {props.sortInfo.length === 2 && <MinusCircleOutlined className = {classes['Remove']} onClick = {(event) => deleteItem(event) }/>}
                                    {
                                        props.sortInfo[1] 
                                        ? 
                                        <button onClick = {(event) => handleSort(event,"2")} style = {{float : "left"}} className = {classes['SortButton']}>
                                            {props.sortInfo[1]["ascending"] ? <FaSortAlphaDown style = {{fontSize : "1.3rem"}} /> : <FaSortAlphaUp style = {{fontSize : "1.3rem"}} />}
                                        </button> : null 
                                    }
                                </div>
                        </Panel>
                    </Collapse>
                    :
                    null
                }
                
                {
                   props.sortInfo[1] && (props.sortInfo[1]["ascending"] || props.sortInfo[1]["descending"])
                   ? 
                   <Collapse defaultActiveKey={['3']} ghost>
                        <Panel header="And Then By" key="3" style = {{textAlign : "left", fontWeight : "bolder"}}>
                            <div style = {{textAlign: "left", fontWeight : "bolder"}}>
                                    <Select
                                        showSearch
                                        value = {props.sortInfo[2] ? props.sortInfo[2].col : null}
                                        bordered = {false}
                                        dropdownStyle = {{textTransform: "capitalize"}}
                                        onChange = {(value) => onChange(value, "3")}
                                        style={{ width: "60%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {
                                            props.targetKeys.map((key) => {
                                                
                                                if(props.parentColumns[key] !== props.sortInfo[0].col && props.parentColumns[key] !== props.sortInfo[1].col){
                                                    return(
                                                        <Option key = {props.parentColumns[key]} value={props.parentColumns[key]}>{props.parentColumns[key].split('_').join(" ").toLowerCase()}</Option>
                                                    );
                                                }
                                                 
                                                return null;
                                            })
                                        }
                                    </Select>
                                    {props.sortInfo.length === 3 && <MinusCircleOutlined className = {classes['Remove']} onClick = {(event) => deleteItem(event) }/>}
                                    {
                                        props.sortInfo[2] 
                                        ? 
                                        <button onClick = {(event) => handleSort(event,"3")} style = {{float : "left"}} className = {classes['SortButton']}>
                                            {props.sortInfo[2]["ascending"] ? <FaSortAlphaDown style = {{fontSize : "1.3rem"}} /> : <FaSortAlphaUp style = {{fontSize : "1.3rem"}} />}
                                        </button> : null 
                                    }
                                </div>
                        </Panel>
                    </Collapse>
                    :
                    null
                }

                
            </div>
        </Scrollbars>
               
    );
}

export default Sortings