import { Col, Row, Tooltip, Dropdown, Menu, message, Modal, Form, Input, Select, Card, DatePicker, InputNumber } from 'antd';
import classes from './ToolRow.module.css';
import {  FaPenAlt, FaFileExport, FaChartArea} from 'react-icons/fa';
import { FcAreaChart, FcBarChart, FcLineChart, FcPieChart, FcScatterPlot} from 'react-icons/fc'
import { saveAs } from 'file-saver'
import DataContext from '../../Context/dataContext';
import {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AiFillDelete } from 'react-icons/ai'
import SyncLoader from "react-spinners/SyncLoader";

const { RangePicker } = DatePicker;

const { Option } = Select;

const ToolRow = (props) => {

    const mainData = useContext(DataContext);
    const url = "/reports/" + window.location.href.split("/")[4] + "/" + window.location.href.split("/")[5] + "/edit";
    const [visible, setVisible] = useState(false);
    const [chartModal, setChartModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [type, setType] = useState("Excel Sheet");

    const showModal = (event, val) => {
        setType(val)
        setVisible(true);
    };
    
    const chartModalHandler = () => {
        setChartModal(true)
        mainData.setCharts(false)
    }

    const printPageExcel = (event) => {
        
        setExporting(true)
        axios
            .post(mainData.URL + '/api/v1/excel/download/'+ mainData.parentReportData.report_code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(), 
            {"filter": mainData.filterString.slice(0,-5)},
            { responseType: 'arraybuffer'},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {

                const data = response.data;
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, mainData.parentReportData.report_name + ".xlsx")
                message.success({
                    content: 'Excel Sheet Generated Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setExporting(false)

                
            })
            .catch((err) => {
                console.log(err);
                setExporting(false)
            });
    }

    const printPagePdf = (event) => {
        setExporting(true)
        axios
            .post(mainData.URL + '/api/v1/pdf/download/'+ mainData.parentReportData.report_code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(),
            {"filter": mainData.filterString.slice(0,-5)},
            {responseType: 'arraybuffer'},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                const data = response.data;
                const blob = new Blob([data], { type: 'application/pdf' })
                saveAs(blob, mainData.parentReportData.report_name + ".pdf")
                message.success({
                    content: 'PDF Generated Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setExporting(false)

                
            })
            .catch((err) => {
                console.log(err);
                setExporting(false)
            });
        

    }

    const mailPDF = (email) => {
        setExporting(true)
        axios
            .post(mainData.URL + '/api/v1/pdf/email/'+ mainData.parentReportData.report_code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(), 
            { "email": email, "filter": mainData.filterString.slice(0,-5)},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                setIsValid(true)
                setConfirmLoading(false)
                setEmail("")
                setVisible(false)
                message.success({
                    content: 'PDF Mailed Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setExporting(false)

                
            })
            .catch((err) => {
                console.log(err);
                setExporting(false)
            });
    }

    const mailExcel = (email) => {
        setExporting(true)
        axios
            .post(mainData.URL + '/api/v1/excel/email/'+ mainData.parentReportData.report_code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(),
            { "email": email, "filter": mainData.filterString.slice(0,-5)},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                setIsValid(true)
                setConfirmLoading(false)
                setEmail("")
                setVisible(false)
                message.success({
                    content: 'Excel Sheet Mailed Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setExporting(false)

                
            })
            .catch((err) => {
                console.log(err);
                setExporting(false)
            });
    }

    const handleOk = () => {
        setConfirmLoading(true);
        if(email.includes("@")){
            if(type === "Excel Sheet") mailExcel(email)
            else if(type === "PDF") mailPDF(email)
        }
        else{
            setIsValid(false)
            setConfirmLoading(false)
        }
    };

    const handleChartOk = () => {

        setConfirmLoading(true);
        if(mainData.chartType === "pie"){

            if(mainData.pieColumn === "" && mainData.pieRow === ""){

                setConfirmLoading(false);
                message.error({
                    content: 'Either Row or Column Should be Selected!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
            else if(!mainData.pieColumn && !mainData.pieRow){
                setConfirmLoading(false);
                message.error({
                    content: 'Either Row or Column Should be Selected!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
            else
            {

                if(mainData.pieColumn !== undefined && mainData.pieColumn !== "")
                {
                    
                    props.setPieData(
                        props.data.map((element) => {
                            return(
                                {
                                    name: element[Object.keys(props.data[0])[1]],
                                    value: element[mainData.pieColumn]
                                }
                            );
                        })
                    )
                }

                if(mainData.pieRow !== undefined && mainData.pieRow !== "")
                {
                    const index = props.data.findIndex((element) => element[Object.keys(props.data[0])[1]] === mainData.pieRow)
                    let pieRData = []
                    
                    Object.keys(props.data[index]).forEach((key) => {
                        
                        if(key !== Object.keys(props.data[0])[1] && key !== "SNO" && key !== "Total")
                            pieRData.push({
                                name: key,
                                value: props.data[index][key]
                            });
                    })

                    props.setPieRData(pieRData)
                    
                }

                mainData.setCharts(true);
                setChartModal(false)
                setConfirmLoading(false);
            }
        }

        if(mainData.chartType !== "" && mainData.chartType !== "pie") 
        {
            mainData.setCharts(true);
            setChartModal(false)
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        setIsValid(true)
        setEmail("")
    };

    const handleChartCancel = () => {
        mainData.setChartType("")
        props.setPieData([])
        props.setPieRData([])
        setChartModal(false);
        setConfirmLoading(false)
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const chartSelect = (event, val) => {
        mainData.setChartType(val)
        mainData.setPieRow("")
        mainData.setPieColumn("")
    }

    const onChange = (value, param) => {
        if(param === "col") mainData.setPieColumn(value)
        if(param === "row") mainData.setPieRow(value)
    }

    const onClear = (value, param) => {
        if(param === "col") mainData.setPieColumn("")
        if(param === "row") mainData.setPieRow("")
    }

    const menu = (
        
        <Menu style = {{textAlign: "left"}}>
          <Menu.Item key = "e1">
            <button onClick = {printPagePdf} className = {classes['StyledButtonX']} style = {{color: "black"}}>Print</button>
          </Menu.Item>
          <Menu.Item key = "e2">
            <button onClick = {printPageExcel} className = {classes['StyledButtonX']} style = {{color: "black"}}>Download as Excel</button>
          </Menu.Item>
          <Menu.Item key = "e3">
            <button onClick = {(event) => showModal(event, "Excel Sheet")} className = {classes['StyledButtonX']} style = {{color: "black"}}>Mail as Excel</button>
          </Menu.Item>
          <Menu.Item key = "e4">
            <button onClick = {printPagePdf} className = {classes['StyledButtonX']} style = {{color: "black"}}>Download as PDF</button>
          </Menu.Item>
          <Menu.Item key = "e5">
            <button onClick = {(event) => showModal(event, "PDF")} className = {classes['StyledButtonX']} style = {{color: "black"}}>Mail as PDF</button>
          </Menu.Item>
        </Menu>
    );

    return(
        <>
             <div className={classes['SiteLayoutBackground']}>
                <Modal
                    title={ "Mail " + type }
                    visible={visible}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                >
                    <Form
                        layout="vertical"
                    >
                        <Form.Item 
                            label = {<div className={isValid ? classes['Label'] : classes['InvalidLabel']}>Receiver's Mail ID</div>}
                        >
                            <Input 
                                placeholder="Enter Email ID"
                                onChange = {onEmailChange}
                                value = {email}
                                className = {isValid ? classes["Input"] : classes["InvalidInput"]}
                            />
                            {!isValid ? <p style = {{textAlign : "left", color: "red"}}>Enter a Valid Email!!!!</p> : null}
                        </Form.Item>
                        
                    </Form>

                </Modal>
                <Modal
                    title= "Select Chart Type"
                    visible={chartModal}
                    onOk={handleChartOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleChartCancel}
                >
                    <Row gutter={10}>
                        <Col md = {20}>
                            <Row gutter={10}>
                                <Col xl = {6} lg = {6} md = {6} xs={{span : 24}}>
                                    <a>
                                        <Card onClick = {(event) => chartSelect(event, "area") } 
                                            className = {mainData.chartType === "area" ? classes['CardClicked'] : classes['Card']} 
                                            title={<div style = {{color: mainData.chartType === "area" ? "white" : "#1777C4"}}>Area</div>} bordered={false}>
                                            <FcAreaChart className = {classes['StyledIconX']} />
                                        </Card>
                                    </a>     
                                </Col>
                                <Col xl = {6} lg = {6} md = {6} xs={{span : 24}}>
                                    <a>
                                        <Card onClick = {(event) => chartSelect(event, "bar") } 
                                            className = {mainData.chartType === "bar" ? classes['CardClicked'] : classes['Card']} 
                                            title={<div style = {{color: mainData.chartType === "bar" ? "white" : "#1777C4"}}>Bar</div>} bordered={false}>
                                        <FcBarChart className = {classes['StyledIconX']} />
                                        </Card>
                                    </a>     
                                </Col>
                                <Col xl = {6} lg = {6} md = {6} xs={{span : 24}}>
                                    <a>
                                        <Card onClick = {(event) => chartSelect(event, "scatter") } 
                                            className = {mainData.chartType === "scatter" ? classes['CardClicked'] : classes['Card']} 
                                            title={<div style = {{fontSize: "0.8rem", lineHeight: "1.5rem",color: mainData.chartType === "scatter" ? "white" : "#1777C4"}}>Scatter</div>} bordered={false}>
                                        <FcScatterPlot className = {classes['StyledIconX']} />
                                        </Card>
                                    </a>     
                                </Col>
                                <Col xl = {6} lg = {6} md = {6} xs={{span : 24}}>
                                    <a>
                                        <Card onClick = {(event) => chartSelect(event, "line") } 
                                            className = {mainData.chartType === "line" ? classes['CardClicked'] : classes['Card']} 
                                            title={<div style = {{color: mainData.chartType === "line" ? "white" : "#1777C4"}}>Line</div>} bordered={false}>
                                        <FcLineChart className = {classes['StyledIconX']} />
                                        </Card>
                                    </a>     
                                </Col>
                            </Row>
                        </Col>
                        <Col xl = {4} lg = {4} md = {4} xs={{span : 24}}>
                            <Row>
                                <a>
                                    <Card onClick = {(event) => chartSelect(event, "pie") } 
                                        className = {mainData.chartType === "pie" ? classes['CardClicked'] : classes['Card']} 
                                        title={<div style = {{color: mainData.chartType === "pie" ? "white" : "#1777C4"}}>Pie</div>} bordered={false}>
                                        <FcPieChart className = {classes['StyledIconX']} />
                                    </Card>
                                </a> 
                            </Row>    
                        </Col>
                    </Row>
                    <p></p>
                    {
                        mainData.chartType === "pie" && chartModal === true && props.metaData.length > 0
                        ?
                        <Form
                            layout="vertical"
                        >
                            <Form.Item 
                                label = {<div className={classes['Label']}>Select Column</div>}
                            >
                                <Select
                                    value = {mainData.pieColumn}
                                    clearIcon = {<AiFillDelete style = {{color : "red"}}/>}
                                    allowClear = {true}
                                    disabled = {mainData.pieRow && mainData.pieRow !== ""}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    onChange = {(value) => onChange(value, "col")}
                                    onClear = {(value) => onClear(value, "col")}
                                    style={{ width: "100%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                    placeholder="Search to Select"
                                >
                                    {
                                        Object.keys(props.data[0]).map((key,index) => {
                                            if(index !== 1 && key !== "Total" && key !== "SNO")
                                            return(
                                                <Option style = {{textTransform: "capitalize"}} key = {key} value={key}>{key.split('_').join(" ").toLowerCase()}</Option>
                                            );
                                            else return null
                                        })
                                    }
                                    
                                </Select>
                            </Form.Item>
                            <p>- OR -</p>
                            <Form.Item 
                                label = {<div className={classes['Label']}>Select Row</div>}
                            >
                                <Select
                                    value = {mainData.pieRow}
                                    clearIcon = {<AiFillDelete style = {{color : "red"}}/>}
                                    disabled = {mainData.pieColumn && mainData.pieColumn !== ""}
                                    allowClear = {true}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    onChange = {(value) => onChange(value, "row")}
                                    onClear = {(value) => onClear(value, "row")}
                                    style={{ width: "100%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                    placeholder="Search to Select"
                                >
                                    {
                                        props.data.map((data,index) => {

                                            return(
                                                <Option style = {{textTransform: "capitalize"}} key = {data[Object.keys(props.data[0])[1]]} value={data[Object.keys(props.data[0])[1]]}>{data[Object.keys(props.data[0])[1]].split('_').join(" ").toLowerCase()}</Option>
                                            );
                                        })
                                    }
                                    
                                </Select>
                            </Form.Item>
                        </Form>
                        :
                        null
                    }
                </Modal>
                <Row gutter={10} className={classes['SiteLayoutBackground']}>
                    <Col md = {mainData.parentReportData.report_format === "matrix" ? (props.ft ? 1 : 9) : (props.ft ? 3 : 11)} xs={{span : 24}} className={classes['Col']}>

                    </Col>
                    <Col md = {4}>
                        {
                            props.editMode || mainData.parentReportData.report_matPos !== 1
                            ?
                            null
                            :
                            <Select
                                value = {props.dFilter}
                                showSearch
                                bordered = {false}
                                dropdownStyle = {{textTransform: "capitalize"}}
                                onChange = {(value) => props.onDChange(value, "col")}
                                style={{ width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold"}}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "all" value="all">All</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "today" value="today">Today</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "yesterday" value="yesterday">Yesterday</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "week" value="week">This Week</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "month" value="month">This Month</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "finYear" value="finYear">This Year</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "fromTo" value="fromTo">From - To</Option>
                            </Select>
                        }

                    </Col>
                    {
                        props.ft ?
                        <>
                            <Col xl = {8} lg = {8} md = {8} xs={{span : 24}} className={classes['Col']}>
                                <RangePicker
                                    bordered={false}
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" , backgroundColor : "white", textTransform: "capitalize", color: "#2EA2EC", fontWeight: "bold"}}
                                    onChange={(date, dateString) => props.onDateChange(date, dateString)} />
                            </Col>
                        </>
                        :
                        null
                    }
                    <Col md = {2} className={classes['Col']}>
                        <p style={{ width: "100%" , backgroundColor : "white", textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", textAlign: "right"}} >Page</p>
                    </Col>
                    <Col md = {2} className={classes['Col']}>
                        <InputNumber 
                            bordered={false}
                            value = {props.page}
                            style={{ width: "100%" , backgroundColor : "white", textTransform: "capitalize", color: "#2EA2EC", fontWeight: "bold"}}
                            onChange={props.onPageChange} />
                    </Col>
                    <Col xl = {3} lg = {3} md = {3} xs={{span : 24}} className={classes['Col']}>
                        {
                            props.editMode
                            ?
                            null
                            :
                            <>
                                {
                                    exporting
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {13}/>
                                    :
                                    <Dropdown overlay = {menu} placement = "bottomCenter" arrow>
                                        <button className = {classes['StyledButtonPdf']} style = {{color: "rgba(255,163,77,1)"}}><FaFileExport className = {classes['StyledIcon']} />  Export</button>
                                    </Dropdown>
                                }
                            </> 
                        }
                    </Col>
                    {
                        mainData.parentReportData.report_format === "matrix"
                        ?
                        <Col xl = {2} lg = {2} md = {2} xs={{span : 24}} className={classes['Col']}>
                            {
                                props.editMode
                                ?
                                null
                                :   
                                <> 
                                    <Tooltip placement="bottom" title="Create Charts" color="#1777C4" key="chart"> 
                                        <button onClick = {chartModalHandler} className = {classes['StyledButtonEdit']} style = {{color: "#1777C4"}}> <Link className = {classes['StyledLink']}><FaChartArea className = {classes['StyledIcon']} /></Link></button>
                                    </Tooltip>
                                </>
                            }
                        </Col>
                        :
                        null
                    }
                    <Col xl = {2} lg = {2} md = {2} xs={{span : 24}} className={classes['Col']}>
                        {
                            props.editMode
                            ?
                            null
                            :
                            <Tooltip placement="bottom" title="Edit View" color="#1777C4" key="edit"> 
                               <button onClick = {props.editModeHandler} className = {classes['StyledButtonEdit']} style = {{color: "#1777C4"}}> <Link className = {classes['StyledLink']} to = {url}><FaPenAlt className = {classes['StyledIcon']} /></Link></button>
                            </Tooltip>
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ToolRow