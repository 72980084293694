import { Layout } from 'antd';
import './VendorPortalPage.css';
import classes from './VendorPortalPageExtra.module.css';
import SiderContentC from '../../components/VendorPortal/SiderContentC/SiderContentC';
import { Scrollbars } from 'react-custom-scrollbars';
import {useState} from 'react';
import FieldDataC from '../../components/VendorPortal/FieldDataC/FieldDataC';
import { DataProvider } from '../../Context/dataContext';

const {Content, Sider } = Layout;

const VendorPortalPage = (props) => {

    const [collapsed, setCollapsed] = useState(true);
    
    const vendorData = {
        URL: props.url,
        analysis: props.analysis
    }

    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };

    return(
        <Layout style={{ minHeight: '100vh', backgroundColor: "transparent"}}>

            <Sider className = {classes['Sider']} collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <SiderContentC url = {props.url} />
            </Sider>
           
            <Layout className = {classes['SiteLayout']}>
                <DataProvider value = {vendorData}>
                    <Scrollbars autoHeight autoHeightMax={ ( window.innerHeight*1 )}>
                        <Content>
                            <FieldDataC />
                        </Content>
                    </Scrollbars>
                </DataProvider>
            </Layout>
        </Layout>
    );  
}

export default (VendorPortalPage);