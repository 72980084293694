import classes from './FieldDataC.module.css'
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Sauda from '../Sauda/Sauda';
import PurchaseOrder from '../PurchaseOrder/PurchaseOrder';
import PurchaseBills from '../PurchaseBills/PurchaseBills';
import Payments from '../Payments/Payments';
import NetDue from '../Outstanding/NetDue';
import Ageing from '../Outstanding/Ageing'
import Ledger from '../Ledger/Ledger';
import Profile from '../Profile/Profile';
import PurchaseReturn from "../PurchaseReturn/PurchaseReturn";

const FieldDataC = (fieldProps) => {

    return(
        <div className={classes['Fields']}>
            <Switch>
                <Route path="/vendor-portal" exact 
                    render={(props) => (
                        <Dashboard {...props} />
                    )}
                />
                <Route path="/vendor-portal/sauda" exact 
                    render={(props) => (
                        <Sauda {...props} />
                    )}
                />
                <Route path="/vendor-portal/purchase-order" exact 
                    render={(props) => (
                        <PurchaseOrder {...props} />
                    )}
                />
                  <Route path="/vendor-portal/purchase-return" exact 
                    render={(props) => (
                        <PurchaseReturn {...props} />
                    )}
                />
                <Route path="/vendor-portal/purchase-bills" exact 
                    render={(props) => (
                        <PurchaseBills {...props} />
                    )}
                />
                <Route path="/vendor-portal/payments" exact 
                    render={(props) => (
                        <Payments {...props} />
                    )}
                />
                <Route path="/vendor-portal/outstanding/net-due" exact 
                    render={(props) => (
                        <NetDue {...props} />
                    )}
                />
                <Route path="/vendor-portal/outstanding/ageing-analysis" exact 
                    render={(props) => (
                        <Ageing {...props} />
                    )}
                />
                <Route path="/vendor-portal/ledger" exact 
                    render={(props) => (
                        <Ledger {...props} />
                    )}
                />
                <Route path="/vendor-portal/profile" exact 
                    render={(props) => (
                        <Profile {...props} />
                    )}
                />
            </Switch>
        </div>
    );
}

export default FieldDataC