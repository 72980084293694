import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const OtherDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = {...data['customerDetails']}
            newdata[param] = e.target.value
            return({
                ...data,
                customerDetails : newdata
            })
        })
    }
    
    return(
        <div>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true}  handleChange = {handleChange} param = "EXTERNAL_ENTITY_GROUP_CODE" name = "GROUP NAME" value = {props.data.EXTERNAL_ENTITY_GROUP_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true}  handleChange = {handleChange} param = "EXT_ENTITY_SUBGROUP_CODE" name = "SUB GROUP" value = {props.data.EXT_ENTITY_SUBGROUP_CODE}/>             
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24}  disabled = {true} handleChange = {handleChange} param = "COMMISION_RATE" name = "COMMISSION RATE" value = {props.data.COMMISION_RATE}/>            
                <DataField editMode = {props.editMode} lg = {12} md = {24}  disabled = {true} handleChange = {handleChange} param = "SHORTAGE_ALLOWED" name = "SHORTAGE ALLOWED" value = {props.data.SHORTAGE_ALLOWED}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24}  disabled = {true} handleChange = {handleChange} param = "EXT_ENTITY_RATING_CODE" name = "RATING" value = {props.data.EXT_ENTITY_RATING_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24}  disabled = {true} handleChange = {handleChange} param = "QUOTA" name = "MONTHLY QUOTA" value = {props.data.QUOTA}/>  
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true}  handleChange = {handleChange} param = "LST_DATE" name = "LST DATE" value = {props.data.LIST_DATE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true}  handleChange = {handleChange} param = "LST_NO" name = "LST NO." value = {props.data.LIST_NO}/>        
            </Row>
            <p></p>
        </div>
    );
}

export default OtherDetails