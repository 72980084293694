import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import ExperienceEdit from './ExperienceEdit';
import moment from 'moment';

const Experience = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24} name = "COMPANY NAME" value = {data.COMPANY_NAME}/> 
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.EMPLOYEE_DESIG_CODE} type = "Select"  name = "DESIGNATION" value = {data.DESIGNATION_CODE}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "FROM DATE" value = {data.FROM_DATE ? data.FROM_DATE.format('DD-MM-YYYY') : null}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "TO DATE" value = {data.TO_DATE ? data.TO_DATE.format('DD-MM-YYYY') : null}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "GROSS SALARY" value = {data.GROSS_SALARY}/>      
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <ExperienceEdit desigOptions = {props.ad.EMPLOYEE_DESIG_CODE} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Experience