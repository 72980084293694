import { Scrollbars } from 'react-custom-scrollbars';
import classes from './ReportRepresentation.module.css';
import { Checkbox } from 'antd';
import { useContext, useState  } from 'react';
import DataContext from '../../Context/dataContext';
import { useEffect } from 'react';

const Total_Columns = ["Columns", "Sum", "Average", "Lowest Value", "Largest Value"]

const ColumnsToTotal = (props) => {

    const mainData = useContext(DataContext)

    useEffect(() => {

        let toTotalCols = []
        props.toTotalStrings.forEach((element) => {

            const index = toTotalCols.findIndex((col) => col.col === element.split(" ")[0])

            if(index === -1){
                toTotalCols.push({
                    key: element.split(" ")[0],
                    col: element.split(" ")[0],  
                    sum: element.split(" ")[1] === "SUM" ? true : false,
                    average: element.split(" ")[1] === "AVG" ? true : false,
                    lowest_value: element.split(" ")[1] === "MIN" ? true : false,
                    largest_value: element.split(" ")[1] === "MAX" ? true : false,
                })
            }

            else{
                if(element.split(" ")[1] === "SUM") toTotalCols[index].sum = true
                if(element.split(" ")[1] === "AVG") toTotalCols[index].average = true
                if(element.split(" ")[1] === "MIN") toTotalCols[index].lowest_value = true
                if(element.split(" ")[1] === "MAX") toTotalCols[index].largest_value = true
            }
        })

        props.setColumnsToTotal(toTotalCols);

    }, [])

    const onCheckChange = (event, col, param) => {

        const colIndex = mainData.columnsToTotal.findIndex((element) => element.col === col)

        if(colIndex === -1){
            mainData.setColumnsToTotal(columnsToTotal => {
                const newColumns = [...columnsToTotal]
                newColumns.push({
                    key: col,
                    col: col,  
                    sum: false,
                    average: false,
                    lowest_value: false,
                    largest_value: false,

                })
                newColumns[newColumns.length - 1][param] = event.target.checked
                return newColumns
            }) 
        }
        else{

            mainData.setColumnsToTotal(columnsToTotal => {
                const newColumns = [...columnsToTotal]
                newColumns[colIndex][param] = event.target.checked
                return newColumns
            }) 
        }
    }

    let i = -1;
    
    return(
        <div className = {classes['TableView']} id = "styledTableTwo">
            <Scrollbars  autoHeight autoHeightMax={ ( window.innerHeight >= 748 ? window.innerHeight*0.5 : window.innerHeight*0.5 )}>
                {
                    mainData.columnsToTotal
                    ?
                    <table className = {classes['TableView']}>
                        <thead style={{ alignItems: 'baseline'}}>
                            <tr>
                                {
                                    Total_Columns.map((column,index) => {
                                        return(
                                        <th key = {index} className={classes['TableHeader']}>
                                            <div className={classes['HeadElement']}>{column}</div>
                                        </th>  
                                    );})
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.targetKeys.map((key,index) => {
            
                                    let col = props.parentColumns[key]
                                    let metaIndex = props.metaData.findIndex((meta) => meta.COLUMN_NAME === col)
                                    
                                    if(props.metaData[metaIndex].DATA_TYPE === "NUMBER"){
                                        i = i + 1;
                                        return(
                                            <tr key = {index} className={classes['PageRow']}>
                                    
                                                <td><p className={classes['rowPC']} style = {{textTransform: 'capitalize', fontSize: "0.9rem"}}>{col.split('_').join(" ").toLowerCase()}</p></td>
                                                <td><p className={classes['rowPC']} ><Checkbox defaultChecked = {mainData.columnsToTotal[i] ? mainData.columnsToTotal[i]['sum'] : false} className = {classes['CheckBox']} onChange = {(event) => onCheckChange(event, col, "sum")}></Checkbox></p></td>
                                                <td><p className={classes['rowPC']} ><Checkbox defaultChecked = {mainData.columnsToTotal[i] ? mainData.columnsToTotal[i]['average'] : false} className = {classes['CheckBox']} onChange = {(event) => onCheckChange(event, col, "average")}></Checkbox></p></td>
                                                <td><p className={classes['rowPC']} ><Checkbox defaultChecked = {mainData.columnsToTotal[i] ? mainData.columnsToTotal[i]['lowest_value'] : false} className = {classes['CheckBox']} onChange = {(event) => onCheckChange(event, col, "lowest_value")}></Checkbox></p></td>
                                                <td><p className={classes['rowPC']} ><Checkbox defaultChecked = {mainData.columnsToTotal[i] ? mainData.columnsToTotal[i]['largest_value'] : false} className = {classes['CheckBox']} onChange = {(event) => onCheckChange(event, col, "largest_value")}></Checkbox></p></td>
                                    
                                            </tr>
                                        ); 
                                    }
                            
                                    else{
                                        return null;
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    :
                    null
                }
            </Scrollbars>
        </div>
    );
}

export default ColumnsToTotal