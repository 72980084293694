import classes from '../Pages.module.css';
import {Row,Col} from 'antd';
import { useState, useEffect} from 'react';
import ToolRow from '../ToolRow/ToolRow';

const Invoices = (props) => {
    const [type, setType] = useState('pending');
    const [ft, setFT] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [dFilter, setDFilter] = useState("all")

    useEffect(() => {

        if(dFilter !== "all"){
            if(dFilter !== "fromTo")
            {
                setFT(false)
                setToDate("")
                setFromDate("")
            }

            else{
                setFT(true)
                if(fromDate !== "" && toDate !== ""){
            
                }
            }
        }

    }, [dFilter, fromDate, toDate])

    useEffect(() => {  

        if(dFilter === "all"){

            setFT(false);
            setToDate("")
            setFromDate("")
            
        }      
        
    }, [dFilter])

    const handleClick = (event, val) => {
        setType(val)
    }

    const onDChange = (value, param) => {

        setDFilter(value)
    }

    const onDateChange = (date, dateString) => {
        
        setFromDate(dateString[0])
        setToDate(dateString[1])

    }

    return(
        <>            
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Invoices</p></Col>
                <Col className = {classes['Col']} md = {10}>
                    <Row>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "all")} className = {type === "all" ? classes['PageButton'] : classes['PageButton2']}>All</button></Col>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "pending")} className = {type === "pending" ? classes['PageButton'] : classes['PageButton2']}>Pending</button></Col>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "completed")} className = {type === "completed" ? classes['PageButton'] : classes['PageButton2']}>Completed</button></Col>
                    </Row>
                </Col>
            </Row>
            <p></p>
            <ToolRow ft = {ft} fromDate = {fromDate} toDate = {toDate} onDateChange = {onDateChange} dFilter = {dFilter} onDChange = {onDChange}/>
        </>
    );
}

export default Invoices