import {Row,Col, Form, Input, DatePicker, Select} from 'antd'
import classes from '../Pages.module.css';
import moment from 'moment';

const { Option } = Select;

const SalaryForm = (props) => {

    const handleChange = (event,param) => {

        props.setForm(form => {
            let newform = form;
            newform[param] = event.target.value
            return({
                ...form,
                ...newform
            });
        })
    }

    const handleSChange = (e,param) => {
        props.setForm(form => {
            let newform = form;
            newform[param] = e
            return({
                ...form,
                ...newform
            });
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setForm(form => {
            let newform = form;
            newform[param] = dateString
            return({
                ...form,
                ...newform
            });
        })
    }

    return(
        <>
            <Form
                layout = "vertical">
                <Row gutter = {32} style = {{margin: "0.5rem"}} className = { classes['RowDE']}>
                    <Col lg = {6} md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >SITE</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>      
                    <Col lg = {6} md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >SALARY MONTH</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col lg = {6} md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >YEAR</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col lg = {6} md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >SALARY TYPE</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >CALCULATED SALARY FROM</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >INCREMENT CODE</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >DEPARTMENT</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >EMPLOYEE CATEGORY</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >EMPLOYEE NAME</div>}
                        >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Data"
                                />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>
                    <Col lg = {0} md = {6}>
                       
                    </Col>  
                    <Col lg = {8} md = {12}>
                        <p></p>
                        <button onClick = {(event) => props.handleSave(event)}  className = {classes['ProfileButtonF']}>Process Salary</button>
                        <p></p>
                    </Col>   
                    <Col lg = {0} md = {6}>
                        
                    </Col>  
                </Row>
            </Form>
        </>
    )
}

export default SalaryForm;