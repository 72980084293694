import classes from '../Pages.module.css';
import {Row,Col, Skeleton} from 'antd';
import { useState, useEffect, useContext} from 'react';
import CustomerTable from '../CustomerTable/CustomerTable';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import AgeForm from './AgeForm';

const Ageing = (props) => {

    const customerData = useContext(DataContext)

    const [interval, setInterval] = useState([15,30,60,90])
    const [ageing, setAgeing] = useState(null)
    const [ageCol, setAgeCol] = useState([
        {name: "a", title: "Net Due"},
        {name: "c", title: "0 - 15 Days"},
        {name: "d", title: "16 - 30 Days"},
        {name: "e", title: "31 - 60 Days"},
        {name: "f", title: "61 - 90 Days"},
        {name: "g", title: "> 90 Days"},
        {name: "b", title: "Total Credit"},
    ])

    useEffect(() => {  

        setAgeing(null)

        axios
            .get(customerData.URL + '/api/v1/customer/ageing',{
                withCredentials: true
            })
            .then((response) => {
                console.log("response");
                setAgeing(ageing => {
                    const newAgeing = [{
                        'a': response.data.netdue,
                        'b': response.data.arr[0],
                        'c': response.data.arr[1],
                        'd': response.data.arr[2],
                        'e': response.data.arr[3],
                        'f': response.data.arr[4],
                        'g': response.data.totalCredit,
                    }]
                    return newAgeing;
                })
            })
            .catch((error) => {
                console.log(error);
            })
        
    }, [])

    const handleSave = (event) => {

        setAgeing(null)
        setAgeCol([])
        let is = ''
        interval.forEach(element => {
            is = is + element.toString() + ','
        })
        is = is.slice(0,-1)
        axios
            .get(customerData.URL + '/api/v1/customer/ageing?intervals=' + is,{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);
                setAgeing(ageing => {
                    const newAgeing = [{
                        'a': response.data.netdue,
                        'b': response.data.arr[0],
                        'c': response.data.arr[1],
                        'd': response.data.arr[2],
                        'e': response.data.arr[3],
                        'f': response.data.arr[4],
                        'g': response.data.totalCredit,
                    }]
                    return newAgeing;
                })

                setAgeCol([
                    {name: "a", title: "Net Due"},
                    {name: "c", title: "0 - " + interval[0].toString() + " Days"},
                    {name: "d", title: (interval[0] + 1).toString() + " - " + interval[1].toString() + " Days"},
                    {name: "e", title: (interval[1] + 1).toString() + " - " + interval[2].toString() + " Days"},
                    {name: "f", title: (interval[2] + 1).toString() + " - " + interval[3].toString() + " Days"},
                    {name: "g", title: "> " + interval[3].toString() + " Days"},
                    {name: "b", title: "Total Credit"},
                ])
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return(
        <>            
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Ageing Analysis</p></Col>
                <Col className = {classes['Col']} md = {10}>
                    
                </Col>
            </Row>
            <div style = {{height: "10px"}}></div>
            <p></p>
            <AgeForm form = {interval} setForm = {setInterval} handleSave = {handleSave}/>
            <p></p>
            <hr></hr>
            {
                ageing && ageCol.length > 0  
                ?
                <CustomerTable data = {ageing} columns = {ageCol}/>
                :
                <>
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default Ageing