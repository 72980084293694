import classes from './BasicInformation.module.css';
import { Form, Input } from 'antd';
import {useContext} from 'react';
import DataContext from '../../Context/dataContext';
import {useState} from 'react';

const BasicInformation = (props) => {

    const mainData = useContext(DataContext)
    const [isValid, setIsValid] = useState(true)

    const onNameChange = e => {

        if(e.target.value !== "") setIsValid(true)
        else setIsValid(false)

        mainData.setViewName(e.target.value)
    };

    const onDescChange = e => {
        mainData.setViewDesc(e.target.value)
    };

    const handleBlur = e => {
        if(mainData.viewName === ""){
            setIsValid(false)
        }
        else{
            setIsValid(true)
        }
    };

    const handleClick = (event, val) => {
        mainData.setCreateNew(val)
    }
    return(

        <div className = {classes['BasicInfoDiv']}>
            <row>
                <button  onClick = {(event) => handleClick(event, false)} className={mainData.createNew ? classes['Disabled'] : classes['SortButton']}  style = {{color:"white"}}>
                    <strong style = {{ color: "white" }}>Update Existing Report</strong>
                </button>
                <button  onClick = {(event) => handleClick(event, true)} className={mainData.createNew ? classes['SortButton'] :  classes['Disabled']}  style = {{color:"white"}}>
                    <strong style = {{ color: "white" }}>Create New Report</strong>
                </button>
            </row>
            <p></p>
            <p></p>
            <Form
                layout="vertical"
                className = {classes["Form"]}
            >
                <Form.Item 
                    label = {<div className={isValid ? classes['Label'] : classes['InvalidLabel']}>View Name</div>}
                >
                    <Input 
                        placeholder="Enter View Name"
                        onChange = {onNameChange}
                        disabled = {!mainData.createNew}
                        onBlur = {handleBlur}
                        value = {!mainData.createNew ? mainData.parentReportData.report_name: mainData.viewName}
                        className = {isValid ? classes["Input"] : classes["InvalidInput"]}
                    />
                    {!isValid ? <p style = {{textAlign : "left", color: "red"}}>Required!!!</p> : null}
                </Form.Item>
                <p></p>

                <Form.Item 
                    label = {<div className={classes['Label']}>View Description</div>}
                >
                    <Input.TextArea 
                        placeholder="Enter View Description"
                        onChange = {onDescChange}
                        value = {!mainData.createNew ? mainData.parentReportData.report_desc : mainData.viewDesc}
                        disabled = {!mainData.createNew}
                        className = {classes["Input"]}
                    />
                </Form.Item>
            </Form>
        </div>

    );
}

export default BasicInformation