import classes from './SiderContent.module.css'
import { Menu, message } from 'antd';
import { AiFillDatabase} from "react-icons/ai";
import { GiSellCard, GiTwoCoins, GiHiveMind, GiContract, GiBuyCard} from "react-icons/gi"
import {RiStockFill, RiAdminFill} from "react-icons/ri"
import { FaSignOutAlt, FaTruck, FaMoneyCheckAlt, FaHeart, FaTools, FaIndustry,FaWpforms} from "react-icons/fa"
import { MdWork } from 'react-icons/md'
import signInSignUpLogo from '../../assets/signInSignUp.png'
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from "axios";
import { useHistory } from 'react-router';

const icons = {0: <FaIndustry />, 1: <GiSellCard />, 2: <GiBuyCard />, 3: <GiTwoCoins />, 4: <RiStockFill />, 5: <RiAdminFill />, 6: <FaTruck />, 7: <FaMoneyCheckAlt />,
               8: <FaHeart />, 9: <FaTools />, 10: <GiHiveMind />, 11: <MdWork />,12:<FaWpforms />, 12: <GiContract />}

const { SubMenu } = Menu;
const SiderContent = (props) => {
    
    const history = useHistory()

    const handleLogout = (event) => {

        axios
        .get(props.url + '/api/v1/users/logout',{
            withCredentials: true
        })
        .then((response) => {
            message.success({
                content: 'Goodbye!!!! See You Again!!!',
                className: 'custom-class',
                style: {
                marginTop: '20vh',
                },
            });
            history.replace('/employee')
        })
        .catch((err) => {
            console.log(err);
        });
    }
    return(
        <>  
            <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo"/>
            <Scrollbars autoHeight autoHeightMax={ ( window.innerHeight*0.73)}>
                <Menu className = {classes['Menu']} disabled = {props.editMode}>
                    <Menu.Item key="all1" icon={<AiFillDatabase />}>   
                        <NavLink to = '/reports'>
                            All
                        </NavLink>
                    </Menu.Item>
                    {
                        props.modules && props.modules.length > 0
                        ?
                        props.modules.map((module, index) => {

                            return(
                                <SubMenu key={"Module" + index.toString()} icon={icons[index] ? icons[index] : <FaIndustry />} title={module.MODULE_NAME}>
                                    {
                                        props.allReports && props.allReports.length > 0
                                        ?
                                        <>
                                            {
                                                props.allReports.map((report) => { 

                                                    if(report.CATEGORY.toLowerCase() === module.MODULE_NAME.toLowerCase())
                                                        return(
                                                            <Menu.Item key={report.REPORT_CODE} >
                                                                <NavLink to = {'/reports/' + report.CATEGORY.toLowerCase().split(" ").join("-") +'/' + report.SLUG}>
                                                                    {report.REPORT_NAME}
                                                                </NavLink>
                                                            </Menu.Item>
                                                        ); 
                                                    
                                                    else
                                                        return null;
                                                })
                                            }
                                        </>
                                        :
                                        null
                                    }
                                
                                </SubMenu>
                            );
                        })
                        :
                        null
                    }
                </Menu>
                <Menu className = {classes['AddReq']} disabled = {props.editMode}>
                <Menu.Item icon={<FaWpforms />}>   
                    <NavLink to = 'reports/scheduler'>
                        Scheduler Report
                    </NavLink>
                </Menu.Item>
            </Menu>
            </Scrollbars>

          

            <Menu className = {classes['Menu']} disabled = {props.editMode}>
                <Menu.Item onClick = {(event) => handleLogout(event)} key="logout" icon={<FaSignOutAlt />}>   
                    <NavLink to = '/reports'>
                        Logout
                    </NavLink>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default SiderContent