 // eslint-disable-next-line

import classes from './TransactionTable.module.css';
import { Scrollbars } from 'react-custom-scrollbars';
import Paper from '@material-ui/core/Paper';
import './style.css';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { StylesProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  SummaryState,
  IntegratedSummary,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  TableColumnResizing,
  TableColumnReordering,
  DragDropProvider,
  TableGroupRow,
  TableSummaryRow,
  TableEditRow,
  TableEditColumn,
} 
from '@devexpress/dx-react-grid-material-ui';
import {useState} from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader";

const getRowId = row => row.SNO;

const styles = theme => ({
    tableStriped: {
      '& tbody tr:nth-of-type(odd)': {
        backgroundColor: fade(theme.palette.primary.main, 0.15),
        zIndex: "-1000",
      },
      '& tbody tr:hover': {
        backgroundColor: "white",
        boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
        color: "#1777C4",
      },

    },

    tableHead: {
        backgroundColor: "transparent",
    },

    tableContainer: {
        overflow: "visible",
    },

    headerCell: {
        color: "#355676ef",
        fontSize: "0.7rem",
        // margin: "0.5rem 1rem",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        backgroundColor: "white",
        width: "17rem !important",

        '&:hover':{
            boxShadow: "-8px 8px 16px 0 rgba(0,0,0,0.2)",
        }
    },

    headerContent: {
        fontWeight: 'bolder',
        padding: '0rem 0rem',
    },

    pagingContainer: {
        backgroundImage: "linear-gradient(to top, #dfe9f3 0%, white 100%)",
        backgroundColor: "white",
        boxShadow: "-8px 8px 16px 8px rgba(0,0,0,0.2)",
        position: "sticky",
        right: "0",
        bottom:"0",
        height: "3.7rem",
        color: "#355676ef",
        fontWeight: "900 !important",
     
        '&:hover':{
            boxShadow: "-8px 8px 16px 8px rgba(0,0,0,0.2)",
        },

        zIndex: "0"
    },

    groupContainer: {
        backgroundColor: "transparent !important",
        zIndex: "0",
        padding: "0px",
        margin: "0",
        overflow: "hidden",
    },

    groupCell: {
        // backgroundColor: "white",
        height: "fit-content",
        // boxShadow: "-8px 8px 16px 0 rgba(0,0,0,0.4)",
    },

    summRow: {
        backgroundColor: "white",
        boxShadow: "-8px 8px 16px 0 rgba(0,0,0,0.4)",
    },

    summInline: {
        // backgroundColor: "white",
        // boxShadow: "8px 8px 16px 0 rgba(0,0,0,0.4)",
    },
    
    groupRow:{
        boxShadow: "8px 8px 16px 0 rgba(0,0,0,0.1)",
    },

    editCell: {
        backgroundColor: "red",
        padding: "0 0.5rem 0 0.5rem",
        fontSize: "0.8rem",
    },

    editRowRow: {
        backgroundColor: "transparent",
        padding: "1rem !important",
        fontSize: "0.7rem !important"
    },

    tableCell: {
        backgroundColor: "blue",
    }
});

const CurrencyFormatter = ({ value }) => (
    <b className = {classes['Cell']}>
        {value}
    </b>
);

const CurrencyTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
);

const TableComponentBase = ({ classes, ...restProps }) => (
    <Table.Table
      {...restProps}
      className={classes.tableStriped}
    >
    </Table.Table>
);

const HeadComponentBase = ({ classes, ...restProps }) => (
    <Table.TableHead
      {...restProps}
      className={classes.tableHead}
    >
    </Table.TableHead>
);

const ContainerComponentBase = ({ classes, ...restProps }) => (
    <Table.Container
      {...restProps}
      className={classes.tableContainer}
    >
    </Table.Container>
);

const CellComponentBase = ({ classes, ...restProps }) => (
    <TableHeaderRow.Cell
      {...restProps}
      className={classes.headerCell}
    >
    </TableHeaderRow.Cell>
);

const ContentComponentBase = ({ classes, ...restProps }) => (
    <TableHeaderRow.Content
      {...restProps}
      className={classes.headerContent}
    >
    </TableHeaderRow.Content>
);

const PagingContainerBase = ({ classes, ...restProps }) => (
    <PagingPanel.Container
      {...restProps}
      className={classes.pagingContainer}
    >
    </PagingPanel.Container>
);

const GroupRowContainerBase = ({ classes, ...restProps }) => (
    <TableGroupRow.Container
      {...restProps}
      className={classes.groupContainer}
    >
    </TableGroupRow.Container>
);

const GroupCellBase = ({ classes, ...restProps }) => (
    <TableGroupRow.Cell
      {...restProps}
      className={classes.groupCell}
    >
    </TableGroupRow.Cell>
);

const SummaryRowBase = ({ classes, ...restProps }) => (
    <TableSummaryRow.TotalRow
      {...restProps}
      className={classes.summRow}
    >
    </TableSummaryRow.TotalRow>
);

const InlineSummaryBase = ({ classes, ...restProps }) => (
    <TableGroupRow.SummaryCell
      {...restProps}
      className={classes.summInline}
    >
    </TableGroupRow.SummaryCell>
);

const RowComponentBase = ({ classes, ...restProps }) => (
    <TableGroupRow.Row
      {...restProps}
      className={classes.groupRow}
    >
    </TableGroupRow.Row>
);

const EditCellComponentBase = ({ classes, ...restProps }) => (
    <TableEditColumn.Command
      {...restProps}
      className={classes.editCell}
    >
    </TableEditColumn.Command>
);

const EditRowComponentBase = ({ classes, ...restProps }) => (
    <TableEditRow.Cell
      {...restProps}
      className={classes.editRowRow}
    >
    </TableEditRow.Cell>
);

const TableCellComponentBase = ({ classes, ...restProps }) => (
    <Table.Cell
      {...restProps}
      className={classes.tableCell}
    >
    </Table.Cell>
);
  
const NEditor = ({ value, onValueChange }) => (
    <Input 
        value = {value}
        disableUnderline={true}
        onChange={event => onValueChange(event.target.value)}
        style = {{fontSize: "0.7rem", fontWeight: "bold",  fontFamily: "Montserrat", width: "100%"}}
    />
);

const NTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        editorComponent={NEditor}
        {...props}
    />
);

const DEditor = ({ value, onValueChange }) => (
    <Input 
        value = {value}
        disableUnderline={true}
        disabled = {true}
        onChange={event => onValueChange(event.target.value)}
        style = {{fontSize: "0.7rem", fontWeight: "bold",  fontFamily: "Montserrat", width: "100%", color: "black"}}
    />
);

const DTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        editorComponent={DEditor}
        {...props}
    />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);
export const HeadComponent = withStyles(styles, { name: 'HeadComponent' })(HeadComponentBase);
export const ContainerComponent = withStyles(styles, { name: 'ContainerComponent' })(ContainerComponentBase);
export const CellComponent = withStyles(styles, { name: 'CellComponent' })(CellComponentBase);
export const ContentComponent = withStyles(styles, { name: 'ContentComponent' })(ContentComponentBase);
export const PagingContainer = withStyles(styles, { name: 'PagingContainer' })(PagingContainerBase);
export const GroupRowContainer = withStyles(styles, { name: 'GroupRowContainer' })(GroupRowContainerBase);
export const GroupCell = withStyles(styles, { name: 'GroupCell' })(GroupCellBase);
export const SummaryRow = withStyles(styles, { name: 'SummaryRow' })(SummaryRowBase);
export const InlineSummary = withStyles(styles, { name: 'InlineSummary' })(InlineSummaryBase);
export const RowComponent = withStyles(styles, { name: 'RowComponent' })(RowComponentBase);
export const EditCellComponent = withStyles(styles, { name: 'EditCellComponent' })(EditCellComponentBase);
export const EditRowComponent = withStyles(styles, { name: 'EditRowComponent' })(EditRowComponentBase);
export const TableCellComponent = withStyles(styles, { name: 'TableCellComponent' })(TableCellComponentBase);

const TableRenderer = (props) => {

    const employeeData = useContext(DataContext);

    const [loading, setLoading] = useState(false)
    const [columns] = useState(props.columns);
    const [tableColumnExtensions] = useState([])
    const [rows, setRows] = useState(props.data);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(rows.length > 10 ? 10 : 0);
    const [pageSizes] = useState(rows.length > 100 ? [10,50,100,0] : (rows.length > 50 ? [10,50,0] : (rows.length > 10 ? [10,0] : [0])));
    const [defaultColumnWidths] = useState(props.columns.map(col => 
            {
                if(col.name === "ATTEND_CODE" || col.name === 'EMP_CODE')
                    return({columnName: col.name, width: 0});
                else if(col.name === "SNO")
                    return({columnName: col.name, width: 110});
                else
                    return({columnName: col.name, width: 120});
            }
        ))
    const [currencyColumns] = useState(props.columns.map((col) => {return(col.name);}));
    const [columnOrder, setColumnOrder] = useState(props.columns.map((col) => {return(col.name);}));
    const [filters, setFilters] = useState([]); 
    const [grouping, setGrouping] = useState([]); 
    const [totalSummaryItems] = useState([]);
    const [editingStateColumnExtensions] = useState([
        { columnName: 'TIME_IN', editingEnabled: false },
    ]);
    const [groupSummaryItems] = useState([]);
    const [shiftColumns] = useState(['SHIFT_CODE']);
    const [statusColumns] = useState(['STATUS', 'STATUS2']);
    const [leavePeriodColumns] = useState(['LEAVE_PERIOD', 'LEAVE_PERIOD2']);
    const [timeColumns] = useState(['TIME_IN', 'TIME_OUT', 'S_TIME_IN', 'S_TIME_OUT']);
    const [nColumns] = useState(['LEAVE_TYPE', 'LEAVE_TYPE2']);
    const [dColumns] = useState(['SNO', 'ATTEND_CODE', 'EMP_CODE', 'CARD_NO', 'EMPLOYEE_NAME', 'NO_WORKING_HRS', 'OVERTIME_HRS',]);
    const [rowIds, setRowIDs] = useState(props.data.map(row => {return(row.SNO)}))
    const [rowChanges, setRowChanges] = useState({});

    const ShiftTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            editorComponent={ShiftEditor}
            {...props}
        />
    );

    const ShiftEditor = ({ value, onValueChange }) => (
        <Select
            input={<Input />}
            disableUnderline={true}
            value={value}
            onChange={event => onValueChange(event.target.value)}
            style= {{fontSize: "0.7rem", fontWeight: "bold",  fontFamily: "Montserrat", width: "100%"}}
        >
            {
                props.ad.shifts.rows.map(row => {
                    return(
                        <MenuItem key = {row.SHIFT_CODE} value={row.SHIFT_CODE}>
                            {row.SHIFT_DESC}
                        </MenuItem>
                    );
                })
            }
        </Select>
    );

    const StatusTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            editorComponent={StatusEditor}
            {...props}
        />
    );

    const StatusEditor = ({ value, onValueChange }) => (
        <Select
            input={<Input />}
            value={value}
            disableUnderline={true}
            onChange={event => onValueChange(event.target.value)}
            style= {{fontSize: "0.7rem", fontWeight: "bold",  fontFamily: "Montserrat", width: "100%"}}
        >
            {
                props.ad.STATUS.rows.map(row => {
                    return(
                        <MenuItem key = {row.KEY} value={row.KEY}>
                            {row.VALUE}
                        </MenuItem>
                    );
                })
            }
        </Select>
    );

    const LeavePeriodTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            editorComponent={LeavePeriodEditor}
            {...props}
        />
    );

    const LeavePeriodEditor = ({ value, onValueChange }) => (
        <Select
            input={<Input />}
            value={value}
            disableUnderline={true}
            onChange={event => onValueChange(event.target.value)}
            style= {{fontSize: "0.7rem", fontWeight: "bold",  fontFamily: "Montserrat", width: "100%"}}
        >
            {
                props.ad.LEAVE_PERIOD.rows.map((row) => {
                    return(
                        <MenuItem key = {row.KEY} value={row.KEY}>
                            {row.VALUE}
                        </MenuItem>
                    );
                })
            }
        </Select>
    );

    const TimeTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            editorComponent={TimePeriodEditor}
            {...props}
        />
    );

    const TimePeriodEditor = ({ value, onValueChange }) => (
        <TextField
            id="time"
            type="time"
            InputProps={{ disableUnderline: true }}
            size="small"
            value={value}
            onChange={event => onValueChange(event.target.value)}
            style= {{fontSize: "0.7rem", fontWeight: "bold",  fontFamily: "Montserrat", width: "100%"}}
        />
    );

    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
    
        if (changed) {
          changedRows = rows.map(row => (changed[row.SNO] ? { ...row, ...changed[row.SNO] } : row));
        }
        
        setRows(changedRows);
        console.log(changedRows);
    };

    const save = (event) => {
        
        const postData = Object.keys(rowChanges).map((key) => {

            return({
                ...rows[key - 1],
                ...rowChanges[key]
            });
        })

        console.log(JSON.stringify(postData, undefined, 2));

        const newPData = {
            ATTEND_CODE: props.form.ATTEND_CODE ? props.form.ATTEND_CODE : '',
            SITE: props.form.SITE,
            ATTEND_DATE: props.form.ATTEND_DATE,
            DEPARTMENT: props.form.DEPARTMENT ? props.form.DEPARTMENT : '',
            EMP_TYPE: props.form.EMP_TYPE ? props.form.EMP_TYPE : '',
        }
        setLoading(true)
        axios
            .post(employeeData.URL + '/api/v1/transactions/daily-attendance?site='+ newPData.SITE.toString() +'&date=' + newPData.ATTEND_DATE + 
            '&empType=' + newPData.EMP_TYPE.toString() + '&code='+ newPData.ATTEND_CODE.toString() +'&dept=' + newPData.DEPARTMENT.toString(), postData,  {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                console.log(response);    
                setLoading(false)
            })
            .catch((err) => {
                // setLoading(false);
                console.log(err);
            });
    }

    const rowChange = (val) => {

        console.log("Hello");
        Object.keys(val).forEach(key => {

            if(!val[key]['TIME_IN'] && val[key]['SHIFT_CODE']){

                const findIndex = props.ad.shifts.rows.findIndex(shift => shift.SHIFT_CODE === val[key]["SHIFT_CODE"])
                val[key]['TIME_IN'] = props.ad.shifts.rows[findIndex]['IN_TIME']
                val[key]['TIME_OUT'] = props.ad.shifts.rows[findIndex]['TIME_OUT']
            }

            else if(rowChanges[key] && val[key]['SHIFT_CODE'] && rowChanges[key]['SHIFT_CODE'] && (val[key]['SHIFT_CODE'] !== rowChanges[key]['SHIFT_CODE'])){
                const findIndex = props.ad.shifts.rows.findIndex(shift => shift.SHIFT_CODE === val[key]["SHIFT_CODE"])
                val[key]['TIME_IN'] = props.ad.shifts.rows[findIndex]['IN_TIME']
                val[key]['TIME_OUT'] = props.ad.shifts.rows[findIndex]['TIME_OUT']
            }
        })

        Object.keys(val).forEach(key => {

            let d12, d22,d32,d02,ans2,actual,findIndex;

            if(val[key]['SHIFT_CODE']){
                findIndex = props.ad.shifts.rows.findIndex(shift => shift.SHIFT_CODE === val[key]["SHIFT_CODE"])
            }
            else{
                findIndex = props.ad.shifts.rows.findIndex(shift => shift.SHIFT_CODE === rows[key - 1]["SHIFT_CODE"])
            } 

            d12 = new Date(Date.parse("2017-05-02T" + props.ad.shifts.rows[findIndex]['IN_TIME']));
            d22 = new Date(Date.parse("2017-05-02T" + props.ad.shifts.rows[findIndex]['TIME_OUT']));
            d32 = new Date(d22 - d12);
            d02 = new Date(0);
            actual = d32.getHours() - d02.getHours()
                                                                                                                
            if(val[key]["TIME_IN"] && val[key]["TIME_OUT"]){

                d12 = new Date(Date.parse("2017-05-02T" + val[key]["TIME_IN"]));
                d22 = new Date(Date.parse("2017-05-02T" + val[key]['TIME_OUT']));
                d32 = new Date(d22 - d12);
                d02 = new Date(0);
                ans2 = d32.getHours() - d02.getHours()
                                                                                                                    
                val[key]['NO_WORKING_HRS'] = Math.abs(ans2)
                
            }

            else if(val[key]["TIME_IN"] && !val[key]["TIME_OUT"]){

                d12 = new Date(Date.parse("2017-05-02T" + val[key]["TIME_IN"]));
                d22 = new Date(Date.parse("2017-05-02T" + rows[key - 1]['TIME_OUT']));
                d32 = new Date(d22 - d12);
                d02 = new Date(0);
                ans2 = d32.getHours() - d02.getHours()
                                                                                                                    
                val[key]['NO_WORKING_HRS'] = Math.abs(ans2)
                
            }

            else if(!val[key]["TIME_IN"] && val[key]["TIME_OUT"]){

                d12 = new Date(Date.parse("2017-05-02T" + rows[key - 1]["TIME_IN"]));
                d22 = new Date(Date.parse("2017-05-02T" + val[key]['TIME_OUT']));
                d32 = new Date(d22 - d12);
                d02 = new Date(0);
                ans2 = d32.getHours() - d02.getHours()
                                                                                                                    
                val[key]['NO_WORKING_HRS'] = Math.abs(ans2)
                
            }

            if(Math.abs(ans2) > actual){
                val[key]['OVERTIME_HRS'] = Math.abs(ans2) - actual
            }

            else{
                val[key]['OVERTIME_HRS'] = 0
            }
        })

        setRowChanges(val)
    }
    return(
        <>
            <StylesProvider injectFirst>  
                <div className = {classes['TableView']} id = "styledTable">
                    {
                        loading
                        ?
                        <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                        :
                        <button className = {classes['SaveButton']} onClick = {(event) => save (event)} >SAVE</button>  
                    }
                    <p></p>       
                    <Paper square elevation = {0} className = {classes['Paper']}>
                        <Scrollbars autoHeight autoHeightMax={ props.val ? ( window.innerHeight*0.75 ) : ( window.innerHeight*0.75 )}>
                            <Grid
                                rows={rows}
                                columns={columns}
                                getRowId={getRowId}
                                
                            >   
                                {/* <FilteringState 
                                    filters={filters} 
                                    onFiltersChange = {setFilters}
                                /> */}

                                <EditingState 
                                    onCommitChanges={commitChanges} 
                                    columnExtensions={editingStateColumnExtensions}
                                    defaultEditingRowIds={rowIds} 
                                    rowChanges={rowChanges}
                                    onRowChangesChange={(rowChanges) => rowChange(rowChanges)}
                                />
                                <CurrencyTypeProvider
                                    for={currencyColumns}
                                />
                                                            
                                <DragDropProvider />

                                <ShiftTypeProvider
                                    for={shiftColumns}
                                />

                                <StatusTypeProvider
                                    for={statusColumns}
                                />

                                <TimeTypeProvider
                                    for={timeColumns}
                                />
                                <LeavePeriodTypeProvider
                                    for={leavePeriodColumns}
                                />

                                <NTypeProvider
                                    for={nColumns}
                                />
                                
                                <DTypeProvider
                                    for={dColumns}
                                />

                                <SortingState
                                    defaultSorting={[]}
                                />
        
                                <SummaryState
                                    totalItems={totalSummaryItems}
                                    groupItems={groupSummaryItems}
                                />
                                <GroupingState
                                    grouping={grouping}
                                    onGroupingChange={setGrouping}
                                />
                                <PagingState
                                    currentPage={currentPage}
                                    onCurrentPageChange={setCurrentPage}
                                    pageSize={pageSize}
                                    onPageSizeChange={setPageSize}
                                />
                                <IntegratedGrouping />
                                <IntegratedSummary />
                                {/* <IntegratedFiltering /> */}
                                <IntegratedSorting />
                                <IntegratedPaging />
                                
                                <Table cellComponent={TableCellComponent} columnExtensions={tableColumnExtensions} tableComponent={TableComponent} headComponent = {HeadComponent} containerComponent = {ContainerComponent}/>
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths}/>
                                <TableHeaderRow showSortingControls cellComponent = {CellComponent} contentComponent = {ContentComponent}/>
                                <TableGroupRow showColumnsWhenGrouped = {true} rowComponent = {RowComponent} summaryCellComponent = {InlineSummary} cellComponent = {GroupCell} containerComponent = {GroupRowContainer}/>
                                {/* <TableFilterRow /> */}
                                
                                <PagingPanel pageSizes={pageSizes} containerComponent = {PagingContainer}/>
                                <TableSummaryRow totalRowComponent = {SummaryRow}/>
                                <TableColumnReordering
                                    order={columnOrder}
                                    onOrderChange={setColumnOrder}
                                />
                                <TableEditRow rowHeight = {8} cellComponent = {EditRowComponent}/>
                                {/* <TableFixedColumns
                                    rightColumns={leftColumns}
                                /> */}
                            </Grid>
                        </Scrollbars>
                    </Paper>
                
            </div>
            </StylesProvider>
        
        </>
    );
}

export default TableRenderer