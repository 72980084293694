import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import ContactDetailsEdit from './ContactDetailsEdit';

const ContactDetails = (props) => {

    return(
        !props.editMode
        ?
        props.data.map((contact,index) => {
            return(
                <div key = {index}>
                    <Row key = {index} className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24} param = "CONTACT_PERSON" name = "CONTACT PERSON" value = {contact.CONTACT_PERSON}/>
                        <DataField editMode = {false} lg = {12} md = {24} param = "CONTACT_NO" name = "CONTACT NUMBER" value = {contact.CONTACT_NO}/>        
                        <DataField editMode = {false} lg = {12} md = {24} param = "EMAIL_ID" name = "EMAIL" value = {contact.EMAIL_ID}/>
                        <DataField editMode = {false} lg = {12} md = {24} param = "DEPARTMENT" name = "DEPARTMENT" value = {contact.DEPARTMENT}/> 
                    </Row>
                    <p></p>
                </div>
            );
        })
        :
        <ContactDetailsEdit  data = {props.data} setData = {props.setData} />
    );
}

export default ContactDetails