import {Row,Col, Form, Input, DatePicker, Select} from 'antd'
import classes from '../Pages.module.css';
import moment from 'moment';

const { Option } = Select;

const DAForm = (props) => {

    const handleChange = (event,param) => {

        props.setForm(form => {
            let newform = form;
            newform[param] = event.target.value
            return({
                ...form,
                ...newform
            });
        })
    }

    const handleSChange = (e,param) => {
        props.setForm(form => {
            let newform = form;
            newform[param] = e
            return({
                ...form,
                ...newform
            });
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setForm(form => {
            let newform = form;
            newform[param] = dateString
            return({
                ...form,
                ...newform
            });
        })
    }

    return(
        <>
            <Form
                layout = "vertical">
                <Row gutter = {32} style = {{margin: "0.5rem"}} className = { classes['RowDE']}>
                    <Col md = {9}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >EMPLOYEE NAME</div>}
                        >
                                <Select
                                    allowClear = {true}
                                    value = {props.form.ATTEND_CODE}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "ATTEND_CODE")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.ad.employees.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.employees.metaData[0].name]} value={option[props.ad.employees.metaData[0].name]}>{option[props.ad.employees.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>      
                    <Col md = {7}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >SITE</div>}
                        >
                                <Select
                                    allowClear = {false}
                                    value = {props.form.SITE}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "SITE")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.ad.sites.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.sites.metaData[0].name]} value={option[props.ad.sites.metaData[0].name]}>{option[props.ad.sites.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>   
                    <Col md = {8}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >ATTENDANCE DATE</div>}
                        >
                                <DatePicker
                                    value = {!props.form.ATTEND_DATE ? null : moment(props.form.ATTEND_DATE, "DD-MM-YYYY")}
                                    format="DD-MM-YYYY"
                                    className = {classes['Date']}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    bordered = {false}
                                    onChange={(date, dateString) => handleDChange(date, dateString, "ATTEND_DATE")} />
                                    
                        </Form.Item>
                        <p></p>
                    </Col>   
                    <Col lg = {8} md = {12}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >DEPARTMENT</div>}
                        >
                               <Select
                                allowClear = {true}
                                    value = {props.form.DEPARTMENT}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "DEPARTMENT")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.ad.departments.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.departments.metaData[0].name]} value={option[props.ad.departments.metaData[0].name]}>{option[props.ad.departments.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>   
                    <Col lg = {8} md = {12}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >EMPLOYEE TYPE</div>}
                        >
                                <Select
                                    allowClear = {true}
                                    value = {props.form.EMP_TYPE}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "EMP_TYPE")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.ad.empTypes.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.empTypes.metaData[0].name]} value={option[props.ad.empTypes.metaData[0].name]}>{option[props.ad.empTypes.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>              
                    <Col lg = {8} md = {24}>
                        <p></p>
                        <button onClick = {(event) => props.handleSave(event)}  className = {classes['ProfileButtonF']}>Search Employees</button>
                        <p></p>
                    </Col>   
                </Row>
            </Form>
        </>
    )
}

export default DAForm;