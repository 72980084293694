import {Row} from 'antd';
import classes from '../Pages.module.css';
import SalesCFormDataField from './SalesCFormDatafield';


const SalesCFormDetails = (props) => {



    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = dateString
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = val
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 

                  {!props.create && <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "SAUDA CODE" param = "SAUDA_CODE" value = {props.data.SAUDA_CODE ? props.data.SAUDA_CODE : null }/> }
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.DEALER_CODE} type = "Select" name = "DEALER NAME" param = "DEALER_CODE" value = {props.data.DEALER_CODE ? props.data.DEALER_CODE : null }/>
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.SAUDA_QUALITY} type = "Select" name = "SAUDA QUALITY" param = "SAUDA_QUALITY" value = {props.data.SAUDA_QUALITY ? props.data.SAUDA_QUALITY : null }/>
                <SalesCFormDataField editMode = {props.editMode} lg = {8} md = {24}  handleChange = {handleDChange}  name = "SAUDA DATE" param = "SAUDA_DATE" type="Date" value = {props.data.SAUDA_DATE ? props.data.SAUDA_DATE : null }/>
                <SalesCFormDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name= "SAUDA QUANTITY" param = "SAUDA_QTY" value = {props.data.SAUDA_QTY ? props.data.SAUDA_QTY: null }/> 
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.CUST_CODE} type = "Select" name = "CUSTOMER NAME" param = "CUST_CODE" value = {props.data.CUST_CODE ? props.data.CUST_CODE : null }/>
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.INCHARGE_CODE} type = "Select" name = "EMPLOYEE NAME" param = "INCHARGE_CODE" value = {props.data.INCHARGE_CODE ? props.data.INCHARGE_CODE : null }/>
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_CODE} type = "Select" name = "ITEM NAME" param = "ITEM_CODE" value = {props.data.ITEM_CODE ? props.data.ITEM_CODE : null }/>
                <SalesCFormDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name= "SAUDA RATE" param = "SAUDA_RATE" value = {props.data.SAUDA_RATE ? props.data.SAUDA_RATE: null }/> 
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.FREIGHT_TYPE_CODE} type = "Select" name = "FREIGHT TYPE" param = "FREIGHT_TYPE_CODE" value = {props.data.FREIGHT_TYPE_CODE ? props.data.FREIGHT_TYPE_CODE : null }/>
                <SalesCFormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_CAT_CODE} type = "Select" name = "ITEM CATEGORY" param = "ITEM_CAT_CODE" value = {props.data.ITEM_CAT_CODE ? props.data.ITEM_CAT_CODE : null }/>
                <SalesCFormDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "UOM NO" param = "UOM_CODE" value = {props.data.UOM_CODE ? props.data.UOM_CODE : null }/> 
                
               
 </Row>
            
        </div>
    );
}


export default SalesCFormDetails;
