import classes from '../Pages.module.css';
import {Row,Col, Tabs, Skeleton, message} from 'antd';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useState, useEffect, useContext} from 'react';
import SalesCFormDetails from './SalesCFormDetails';

import { useHistory } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";

const { TabPane } = Tabs;

const SalesCFormCreate = (props) => {

    const employeeData = useContext(DataContext)
    const history = useHistory();

    const [ad, setAD] = useState(null)
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(true)
    const [allData, setAllData] = useState(
      {
        "header": {
          "metaData": [
           
            
            {
              "name": "DEALER_CODE"
            },
            {
              "name": "SAUDA_QUALITY"
            },
            {
              "name": "SAUDA_DATE"
            },
            {
              "name": "SAUDA_QTY"
            },
            {
              "name":"CUST_CODE"
            },
            {
              "name":"INCHARGE_CODE"
            },
            {
              "name":"ITEM_CODE"
            },
            {
              "name":"SAUDA_RATE"
            },
          
           {
              "name":"ITEM_CAT_CODE"
           },
           {
            "name":"FREIGHT_TYPE_CODE"
           },
          {
           "name":"UOM_CODE"
         },
           
          ],
          "rows": [
            {
              
              "DEALER_CODE": null,
              "SAUDA_QUALITY": null,
              "SAUDA_DATE": null,
              "SAUDA_QTY": null,
              "CUST_CODE": null,
              "INCHARGE_CODE": null,
              "ITEM_CODE": null,
              "SAUDA_RATE": null,
              "ITEM_CAT_CODE": null,
              "FREIGHT_TYPE_CODE": null,
              "UOM_CODE": null,
              
             
            }
          ]
        },
       
        
      }
    )

    useEffect(() => {

      setAD(null)
      axios
        .get(employeeData.URL + '/api/v1/salesForm/additional-data',{
            withCredentials: true
        })
        .then((response) => {

            console.log(response);
            setAD(ad => {
                let newad = response.data.data
                return({
                  ...newad,
                
                });
            }) 
        })
        .catch((error) => {
            console.log(error);
        })
       
       


    },[])

    const handleSave = (event) => {

      var formv = true
      let count = 0;
      allData.header.rows.forEach(data => {
          
          if(!data){

              formv = formv&&false
              if(count === 0){
                  message.error({
                      content: 'Empty Fields In Sales contract form FORM Tab!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '1vh',
                      },
                  });
              }

              count = count + 1;
          }
      })

      if(formv){

        console.log("Submitting");
       
        const postData = {
          "header": allData.header.rows,
         
        }

        console.log(JSON.stringify(postData, undefined, 2));
        setLoading(true)
        axios
          .post(employeeData.URL + '/api/v1/salesForm/create-sales', postData,  {
              withCredentials: true,
              credentials: 'include',
          })
          .then((response) => {
            console.log("success")
              message.success({
                  content: 'sales contract form Created Successfully!!!',
                  className: 'custom-class',
                  style: {
                      marginTop: '2vh',
                  },
                 
              });
              setLoading(false)
              history.replace("/sales/transaction/salesForm")
          })
          .catch((err) => {
              message.error({
                  content: 'An Error Occurred!!!!',
                  className: 'custom-class',
                  style: {
                      marginTop: '2vh',
                  },
              });
              setLoading(false)
          });
      }

    }

    const handleCancel = (event) => {

      setLoading(false)
      history.replace("/Sales/transaction/salesForm")
    }
    return(
        ad
        ?
        <Row className = {classes['RowP']}>
       
            <Col lg = {editMode ? 13 : 19} md = {editMode ? 13 : 19} className = {classes['Col']} >

            </Col>
            {
                editMode
                ?
                <>
                    <Col md = {4} >
                        {
                          loading
                          ?
                          <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                          :
                          <button style = {{ backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Add Sales Form</button>
                        }
                        
                    </Col>
                    <Col md = {4} >
                        {
                          loading
                          ?
                          <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                          :
                          <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancel(event)} className = {classes['ProfileButton']}>Cancel</button>
                        }
                    </Col>
                     <Col lg = {24} md = {24} className = {classes['Col']}>
        <p></p>
        <div className = {classes['EditView']}>
        <Tabs defaultActiveKey="0" centered style = {{ height: "100%"}} tabPosition={"left"} type = "line" tabBarGutter = "0">
        <TabPane tab= {<span>Basic Details</span>} key="0">
            <SalesCFormDetails create = {true} ad = {ad} setData = {setAllData} data = {allData.header.rows[0]} editMode = {editMode} />
            </TabPane>
            </Tabs>
       

        </div>
    </Col>   
                </>
                :
                <Col md = {4} >
                    <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Details</button>
                </Col>
            }
            <Col lg = {1} md = {1} >

            </Col>
               
        </Row>
        :
        <>
          <Skeleton active = {true}/>
          <Skeleton active = {true}/>
          <Skeleton active = {true}/>
        </>
    );
}

export default SalesCFormCreate;