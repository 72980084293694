import classes from './SiderContentC.module.css'
import { Menu, message } from 'antd';
import { FaSignOutAlt, FaBusinessTime,FaMoneyCheckAlt} from "react-icons/fa"
import signInSignUpLogo from '../../../assets/signInSignUp.png'
import {MdDashboard} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { useHistory } from 'react-router';
import {MdWatchLater, MdAssignmentTurnedIn} from 'react-icons/md';
import {BsFillGrid3X3GapFill, BsFillPeopleFill} from 'react-icons/bs'
import { AiFillFileMarkdown,AiFillPropertySafety } from "react-icons/ai";
import {GiRuleBook} from 'react-icons/gi';
import {AiTwotoneRest,AiOutlineTeam} from "react-icons/ai";

const { SubMenu } = Menu;

const SiderContentS = (props) => {
    
    const history = useHistory()

    const handleLogout = (event) => {

        axios
        .get(props.url + '/api/v1/users/logout',{
            withCredentials: true
        })
        .then((response) => {
            message.success({
                content: 'Goodbye!!!! See You Again!!!',
                className: 'custom-class',
                style: {
                marginTop: '20vh',
                },
            });
            history.replace('/employee')
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <>  
            <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo"/>
            <Menu className = {classes['Menu']}>
            <Menu.Item key="empDashboard" icon={<MdDashboard />}>   
            <NavLink to = '/sales'>
                Dashboard
            </NavLink>
        </Menu.Item>
            <SubMenu key="Masters" title="Masters" icon={<AiTwotoneRest />}>
            <Menu.Item key="itemMaster">   
                    <NavLink to = '/sales/itemMaster/item-details'>
                        Item Master
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="customerMaster">   
                <NavLink to = '/sales/customerMaster/customer-details'>
                    Customer Master
                </NavLink>
            </Menu.Item>
            <Menu.Item key="DealerMaster">   
                <NavLink to = '/sales/DealerMaster/dealer-details'>
                    Dealer Master
                </NavLink>
            </Menu.Item>
            <Menu.Item key="chargedefMaster">   
                <NavLink to = '/sales/chargedefMaster/charge-details'>
                    Chargedef Master
                </NavLink>
            </Menu.Item>

            
                </SubMenu>
               
                <SubMenu key="norms" title="Norms" icon = {<GiRuleBook />}>
                    <Menu.Item key="DiscountNorms" >
                        <NavLink to = '/payroll/norms/leave-norms'>
                            Discount Norms
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="GaugeNorms" >
                        <NavLink to = '/payroll/norms/salary-norms'>
                            Gauge Difference Norms
                        </NavLink>
                    </Menu.Item>   
                    
                </SubMenu>
                <SubMenu key="transactions" title="Transactions" icon = {<MdAssignmentTurnedIn />}>
                    <Menu.Item key="Salesform" >
                        <NavLink to = "/sales/transaction/salesForm">
                            Sales Contract Form
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="SalesOrder" >
                    <NavLink to = "/sales/transaction/salesOrder">
                        Sales Order Form
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="SalesInvoice" >
                    <NavLink to = "/sales/transaction/salesInvoice">
                        Sales Invoice
                    </NavLink>
                </Menu.Item>

                </SubMenu>
               
                <SubMenu key="misc" title="Miscelleneous" icon = {<BsFillGrid3X3GapFill />}>
                    {
                        props.miscList && props.miscList.length > 0
                        ?
                        <>
                            {
                                props.miscList.map(misc => {
                                    return(
                                        <Menu.Item key={misc.TABLE_CODE} >
                                            <NavLink to = {'/payroll/misc/' + misc.SLUG}>
                                                {misc.FORM_NAME}
                                            </NavLink>
                                        </Menu.Item>
                                    );
                                })
                            }
                        </>
                        :
                        null
                    }
                </SubMenu>
                <Menu.Item key="empLogout" onClick = {(event) => handleLogout(event)} icon={<FaSignOutAlt style = {{color : "red", opacity: "0.7"}}/>}>   
                    <NavLink to = '/customer-portal'>
                        Logout
                    </NavLink>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default SiderContentS;