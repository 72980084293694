import {Row,message} from 'antd';
import PropTypes from 'prop-types';
import classes from '../Pages.module.css';
import DataField from './DataField';


const BasicDetails = (props) => {
   
    
    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['dealerMaster']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                dealerMaster : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['dealerMaster']]
            newdata[0][param] = val
            return({
                ...data,
                dealerMaster : newdata
            })
        })
    }
    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['dealerMaster']]
            newdata[0][param] = dateString
            return({
                ...data,
                dealerMaster : newdata
            })
        })
    }



    return(
        
        <div>
            <p></p>
               <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                { !props.create && <DataField editMode = {props.editMode} lg = {12} md = {24}  name = "DEALER CODE" param = "EXTERNAL_ENTITY_CODE" value = {props.data.EXTERNAL_ENTITY_CODE}/> }
                
                <DataField editMode = {props.editMode} lg = {12} md = {24}  type="text" name = "DEALER NAME" param = "EXTERNAL_ENTITY_NAME" value = {props.data.EXTERNAL_ENTITY_NAME} rules={[{message:"missing name"}]}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.EXT_ENTITY_TYPE_CODE} type = "Select" name = "TYPE" param = "EXT_ENTITY_TYPE_CODE" value = {props.data.EXT_ENTITY_TYPE_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.EXTERNAL_ENTITY_GROUP_CODE} type = "Select" name = "GROUP" param = "EXTERNAL_ENTITY_GROUP_CODE" value = {props.data.EXTERNAL_ENTITY_GROUP_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange}  type="text" name = "ADDRESS" param = "ADDRESS" value = {props.data.ADDRESS}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.CITY} type = "Select" name = "CITY" param = "CITY" value = {props.data.CITY}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.LOCALITY_CODE} type = "Select" name = "LOCALITY" param = "LOCALITY_CODE" value = {props.data.LOCALITY_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} type="text" name = "PAN NO" param = "PAN_NO" value = {props.data.PAN_NO}/>   
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} type="number" name = "INTEREST RATE(MO)" param = "INT_PER" value = {props.data.INT_PER}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} type="text" name = "CST NO" param = "CST_NO" value = {props.data.CST_NO}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} type="number" name = "PIN" param = "PIN_NO" value = {props.data.PIN_NO} /> 
               
               
            </Row>
            </div>
          
);
}
            
      


export default BasicDetails;