import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import DeliveryDetailsEdit from './DeliveryDetailsEdit';

const DeliveryDetails = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24} name = "NAME" value = {data.NAME}/> 
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.CITY_CODE} type = "Select" name = "CITY" value = {data.CITY_CODE}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "ADDRESS" value = {data.ADD_1}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "PIN CODE" value = {data.PIN}/> 
                        <DataField editMode = {false} lg = {8} md = {24} options = {props.ad.LOCALITY_CODE} type = "Select" name = "LOCALITY" value = {data.LOCALITY_CODE}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "GST NUMBER" value = {data.GST_NUMBER}/> 
                            
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <DeliveryDetailsEdit cityOptions = {props.ad.CITY} localityOptions = {props.ad.LOCALITY_CODE}  data = {props.data} setData = {props.setData} />
        </>
    );
}

export default DeliveryDetails;