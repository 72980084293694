import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import BrokerDetailsEdit from './BrokerDetailsEdit';

const BrokerDetails = (props) => {
    return(
        !props.editMode
        ?
            props.data.map((broker, index) => {
                return(
                    <div key = {index}>
                        <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                            <DataField editMode = {props.editMode} lg = {24} md = {24} name = "BROKER NAME" value = {broker.BROKER_NAME}/>          
                        </Row>
                    </div>
                );
            })
        :
        <BrokerDetailsEdit data = {props.data} setData = {props.setData} />
    );
}

export default BrokerDetails