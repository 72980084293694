import classes from '../Pages.module.css';
import {Row,Col, Tabs, Skeleton, message} from 'antd';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useState, useEffect, useContext} from 'react';
import BasicDetails from './BasicDetails'
import AddressDetails from './AddressDetails';
import Education from './Education';
import Language from './Language'
import Experience from './Experience'
import Health from './Health';
import Dependent from './Dependent';
import Training from './Training';
import Membership from './Membership';
import Department from './Department';
import OtherDetails from './OtherDetails';
import City from './City';
import Skill from './Skill';
import Designation from './Designation';
import DocumentVerification from './DocumentVerification';
import moment from 'moment';
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory } from 'react-router';

const { TabPane } = Tabs;

const EmployeeView = (props) => {

    const employeeData = useContext(DataContext)

    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [allData, setAllData] = useState(null)
    const [storeData, setStoreData] = useState(null)
    const [ad, setAD] = useState(null)

    useEffect(() => {

        setAllData(null)
        setStoreData(null)
        setAD(null)

        axios
            .get(employeeData.URL + '/api/v1/employees/' + props.match.params.id,{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);

                setAllData(allData => {
                    let newDetails = response.data.data
                    return({
                        ...newDetails,
                      
                    })

                })

                setStoreData(storeData => {
                    let newDetails = response.data.data
                   

                  
                    return({
                        ...newDetails,
                       
                    })
                
                    
                })

            })
            .catch((error) => {
                console.log(error);
            })
        
            
        axios
            .get(employeeData.URL + '/api/v1/employees/additional-data',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAD(ad => {
                    let newad = response.data.data
                    return ({
                        ...newad,
                       
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            })
        
    }, [])

    const handleSave = (event) => {
        
        const postData = {
            ...allData,
          
        }

        const postDataS = {
            ...storeData,
            department: storeData.department.map(dep => {
                            return(
                                {
                                    ...dep,
                                    DATE_JOIN: dep.DATE_JOIN ? dep.DATE_JOIN.format('DD-MM-YYYY') : null,
                                    DATE_RELEASE: dep.DATE_RELEASE ? dep.DATE_RELEASE.format('DD-MM-YYYY') : null,
                                }
                            )
                        }),
            designation: allData.designation.map(dep => {
                return(
                    {
                        ...dep,
                        DATE_JOIN: dep.DATE_JOIN ? dep.DATE_JOIN.format('DD-MM-YYYY') : null,
                        DATE_RELEASE: dep.DATE_RELEASE ? dep.DATE_RELEASE.format('DD-MM-YYYY') : null,
                    }
                )
            }),
            dependent: storeData.dependent.map(dep => {
                            return(
                                {
                                    ...dep,
                                    DOB: dep.DOB ? dep.DOB.format('DD-MM-YYYY') : null,
                                }
                            )
                        }),
            experience: storeData.experience.map(dep => {
                            return(
                                {
                                    ...dep,
                                    FROM_DATE: dep.FROM_DATE ? dep.FROM_DATE.format('DD-MM-YYYY') : null,
                                    TO_DATE: dep.TO_DATE ? dep.TO_DATE.format('DD-MM-YYYY') : null,
                                }
                            )
                        }),
            membership: storeData.membership.map(dep => {
                            return(
                                {
                                    ...dep,
                                    SINCE_DATE: dep.SINCE_DATE ? dep.SINCE_DATE.format('DD-MM-YYYY') : null,
                                    EXPIRY_DATE: dep.EXPIRY_DATE ? dep.EXPIRY_DATE.format('DD-MM-YYYY') : null,
                                }
                            )
                        }),
            training:  storeData.training.map(dep => {
                            return(
                                {
                                    ...dep,
                                    START_DATE: dep.START_DATE ? dep.START_DATE.format('DD-MM-YYYY') : null,
                                }
                            )
                        }),
        }


        console.log(postData);
        console.log(postDataS);
        console.log(allData);

        postData.education.forEach((lang,index) => {

            if(!lang.EMP_EDUC_CODE){
                postData.education[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.education[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.education.forEach((lang,index) => {
            const dataIndex = postData.education.findIndex(element => element.EMP_EDUC_CODE === lang.EMP_EDUC_CODE)
            if(dataIndex === -1){
                postData.education.push({
                    EMP_EDUC_CODE: lang.EMP_EDUC_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.languages.forEach((lang,index) => {

            if(!lang.LANGUAGE_DET_CODE){
                postData.languages[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.languages[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.languages.forEach((lang,index) => {
            const dataIndex = postData.languages.findIndex(element => element.LANGUAGE_DET_CODE === lang.LANGUAGE_DET_CODE)
            if(dataIndex === -1){
                postData.languages.push({
                    LANGUAGE_DET_CODE: lang.LANGUAGE_DET_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.experience.forEach((lang,index) => {

            if(!lang.EXP_CODE){
                postData.experience[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.experience[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.experience.forEach((lang,index) => {
            const dataIndex = postData.experience.findIndex(element => element.EXP_CODE === lang.EXP_CODE)
            if(dataIndex === -1){
                postData.experience.push({
                    EXP_CODE: lang.EXP_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.health.forEach((lang,index) => {

            if(!lang.EMP_HEALTH_CODE){
                postData.health[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.health[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.health.forEach((lang,index) => {
            const dataIndex = postData.health.findIndex(element => element.EMP_HEALTH_CODE === lang.EMP_HEALTH_CODE)
            if(dataIndex === -1){
                postData.health.push({
                    EMP_HEALTH_CODE: lang.EMP_HEALTH_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.dependent.forEach((lang,index) => {

            if(!lang.DEPENDENT_CODE){
                postData.dependent[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.dependent[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.dependent.forEach((lang,index) => {
            const dataIndex = postData.dependent.findIndex(element => element.DEPENDENT_CODE === lang.DEPENDENT_CODE)
            if(dataIndex === -1){
                postData.dependent.push({
                    DEPENDENT_CODE: lang.DEPENDENT_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.training.forEach((lang,index) => {

            if(!lang.TRAINING_CODE){
                postData.training[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.training[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.training.forEach((lang,index) => {
            const dataIndex = postData.training.findIndex(element => element.TRAINING_CODE === lang.TRAINING_CODE)
            if(dataIndex === -1){
                postData.training.push({
                    TRAINING_CODE: lang.TRAINING_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.membership.forEach((lang,index) => {

            if(!lang.MEMBERSHIP_CODE){
                postData.membership[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.membership[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.membership.forEach((lang,index) => {
            const dataIndex = postData.membership.findIndex(element => element.MEMBERSHIP_CODE === lang.MEMBERSHIP_CODE)
            if(dataIndex === -1){
                postData.membership.push({
                    MEMBERSHIP_CODE: lang.MEMBERSHIP_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.department.forEach((lang,index) => {

            if(!lang.EMP_DET_CODE){
                postData.department[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.department[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.department.forEach((lang,index) => {
            const dataIndex = postData.department.findIndex(element => element.EMP_DET_CODE === lang.EMP_DET_CODE)
            if(dataIndex === -1){
                postData.department.push({
                    EMP_DET_CODE: lang.EMP_DET_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.designation.forEach((lang,index) => {

            if(!lang.EMP_DESIG_CODE){
                postData.designation[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.designation[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.designation.forEach((lang,index) => {
            const dataIndex = postData.designation.findIndex(element => element.EMP_DESIG_CODE === lang.EMP_DESIG_CODE)
            if(dataIndex === -1){
                postData.designation.push({
                    EMP_DESIG_CODE: lang.EMP_DESIG_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.skill.forEach((lang,index) => {

            if(!lang.EMP_SKILL_CODE){
                postData.skill[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.skill[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.skill.forEach((lang,index) => {
            const dataIndex = postData.skill.findIndex(element => element.EMP_SKILL_CODE === lang.EMP_SKILL_CODE)
            if(dataIndex === -1){
                postData.skill.push({
                    EMP_SKILL_CODE: lang.EMP_SKILL_CODE,
                    PARAM: "DELETE"
                })
            }
        })

        postData.city.forEach((lang,index) => {

            if(!lang.UNIQUE_ID){
                postData.city[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.city[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.city.forEach((lang,index) => {
            const dataIndex = postData.city.findIndex(element => element.UNIQUE_ID === lang.UNIQUE_ID)
            if(dataIndex === -1){
                postData.city.push({
                    UNIQUE_ID: lang.UNIQUE_ID,
                    PARAM: "DELETE"
                })
            }
        })

        postData.documentVerification.forEach((lang,index) => {

            if(!lang.UNIQUE_ID){
                postData.documentVerification[index] = {
                    ...lang,
                    PARAM: "INSERT"
                }
            }

            else{
                postData.documentVerification[index] = {
                    ...lang,
                    PARAM: "UPDATE"
                } 
            }
        })

        postDataS.documentVerification.forEach((lang,index) => {
            const dataIndex = postData.documentVerification.findIndex(element => element.UNIQUE_ID === lang.UNIQUE_ID)
            if(dataIndex === -1){
                postData.documentVerification.push({
                    UNIQUE_ID: lang.UNIQUE_ID,
                    PARAM: "DELETE"
                })
            }
        })

        console.log(JSON.stringify(postData, undefined,2));

        axios
        .patch(employeeData.URL + '/api/v1/employees/' + props.match.params.id, postData,  {
            withCredentials: true,
            credentials: 'include',
        })
        .then((response) => {
            message.success({
                content: 'Employee Updated Successfully!!!',
                className: 'custom-class',
                style: {
                    marginTop: '2vh',
                },
            });
            setLoading(false)
            history.replace('/payroll/employee-details')
        })
        .catch((err) => {
            message.error({
                content: 'An Error Occurred!!!!',
                className: 'custom-class',
                style: {
                    marginTop: '2vh',
                },
            });
            setLoading(false)
        });

        setEditMode(false)
    }

    const handleCancel = (event) => {

        setLoading(false)
        history.replace('/payroll/employee-details')
    }

    return(
        <>
            {
                allData && ad
                ?
                <Row className = {classes['RowP']}>
                    <Col lg = {editMode ? 13 : 19} md = {editMode ? 13 : 19} className = {classes['Col']} >

                    </Col>
                    {
                        editMode
                        ?
                        <>
                            <Col md = {4} >
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{ backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                                }
                            </Col>
                            <Col md = {4} >
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancel(event)} className = {classes['ProfileButton']}>Cancel</button>
                                }
                                
                            </Col>
                        </>
                        :
                        <Col md = {4} >
                            <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Details</button>
                        </Col>
                    }
                    <Col lg = {1} md = {1} >

                    </Col>
                    <Col lg = {24} md = {24} className = {classes['Col']}>
                        <div className = {classes['EditView']}>
                            <Tabs defaultActiveKey="0" centered style = {{ height: "100%"}} tabPosition={"left"} type = "line" tabBarGutter = "0">
                                <TabPane tab= {<span>Basic Details</span>} key="0">
                                    <BasicDetails create = {false} ad = {ad} setData = {setAllData} data = {allData.employeeMaster[0]} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Address</span>} key="1">
                                    <AddressDetails ad = {ad} setData = {setAllData} data = {allData.employeeMaster[0]} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Education</span>} key="2">
                                    <Education ad = {ad} setData = {setAllData} data = {allData.education} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Language</span>} key="3">
                                    <Language ad = {ad} setData = {setAllData} data = {allData.languages} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Experience</span>} key="4">
                                    <Experience ad = {ad} setData = {setAllData} data = {allData.experience} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Health</span>} key="5">
                                    <Health ad = {ad} setData = {setAllData} data = {allData.health} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Dependent</span>} key="6">
                                    <Dependent ad = {ad} setData = {setAllData} data = {allData.dependent} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Training</span>} key="7">
                                    <Training ad = {ad} setData = {setAllData} data = {allData.training} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Membership</span>} key="8">
                                    <Membership ad = {ad} setData = {setAllData} data = {allData.membership} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Department</span>} key="10">
                                    <Department ad = {ad} setData = {setAllData} data = {allData.department} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Designation</span>} key="11">
                                    <Designation ad = {ad} setData = {setAllData} data = {allData.designation} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Skills</span>} key="12">
                                <Skill  ad = {ad} setData = {setAllData} data = {allData.skill} editMode = {editMode} />  
                                </TabPane>
                                <TabPane tab= {<span>City</span>} key="13">
                                  <City  ad = {ad} setData = {setAllData} data = {allData.city} editMode = {editMode} />  
                                </TabPane>
                                <TabPane tab= {<span>Document Verification</span>} key="14">
                                <DocumentVerification  ad = {ad} setData = {setAllData} data = {allData.documentVerification} editMode = {editMode} /> 
                                </TabPane>
                                <TabPane tab= {<span>Other Details</span>} key="15">
                                    <OtherDetails ad = {ad} setData = {setAllData} data = {allData.employeeMaster[0]} editMode = {editMode} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>

                    
                
                </Row>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
           
        </>
    );
}

export default EmployeeView