import { Scrollbars } from 'react-custom-scrollbars';
import { Form, Button, Space, Select, message, InputNumber, Input } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import classes from './Filter.module.css'
import {useState, useContext, useEffect } from 'react';
import DataContext from '../../Context/dataContext';

const { Option } = Select;

const Filter = (props) => {

    const [dataType, setDataType] = useState([]);
    const [isBetween, setIsBetween] = useState([]);
    const [initialFilter, setInitialFilter] = useState(null);
    const [count, setCount] = useState(0);
    const mainData = useContext(DataContext)

    useEffect(() => {

        setDataType([]);
        setIsBetween([]);

        let types = [];
        let betweens = [];
        let inFilt = [];
        let filterStrings = mainData.parentReportData.report_filter

        filterStrings.forEach((filter,index) => {
            let elements = filter.split(" ");
            const metaIndex = props.metaData.findIndex((element) => element.COLUMN_NAME === elements[0])
            types.push(props.metaData[metaIndex].DATA_TYPE)
            if(elements[1] === "BETWEEN"){
                betweens.push(true)
                elements.push(filterStrings[index + 1])
                filterStrings.splice(index+1,1)
                inFilt.push({
                    columnName: elements[0],
                    filterParam: "BETWEEN",
                    filterValue: parseInt(elements[2]),
                    filterValue1: parseInt(elements[3]),
                })
            }
            else{
                betweens.push(false)
                if(props.metaData[metaIndex].DATA_TYPE === "NUMBER"){
                    inFilt.push({
                        columnName: elements[0],
                        filterParam: elements[1],
                        filterValue: parseInt(elements[2]),
                    })
                }
                else{
                    elements[2] = elements[2].slice(1,-1)
                    inFilt.push({
                        columnName: elements[0],
                        filterParam: elements[1],
                        filterValue: elements[2],
                    })
                }
            }
        })

        setDataType(types);
        setIsBetween(betweens);
        setInitialFilter(inFilt);
        setCount(inFilt.length)
        mainData.setFilterInfo({filters: inFilt});

    }, [])
    const handleValueChanges = (changedValues, allValues, index) => {

    }

    const handleFieldsChanges = (changedFields, allFields) => {

    }

    const handleSelectChange = (event,index) => {

        const metaIndex = props.metaData.findIndex((element) => element.COLUMN_NAME === event)
        const n = dataType.length;

        if(n < (index + 1)){
            setDataType(dataType => {

                let newDataType = [...dataType];
                newDataType.push(props.metaData[metaIndex].DATA_TYPE);

                return newDataType;
            })
        }

        else{
            setDataType(dataType => {

                let newDataType = [...dataType];
                newDataType[index] = props.metaData[metaIndex].DATA_TYPE;

                return newDataType;
            })
        }

    }

    const handleBetween = (event,index) => {

        const n = isBetween.length;

        if(n < (index + 1)){
            setIsBetween(isBetween => {

                let newIsBetween = [...isBetween];
                if(event === 'BETWEEN') newIsBetween.push(true);
                else newIsBetween.push(false);
                return newIsBetween;
            })
        }

        else{
            setIsBetween(isBetween => {

                let newIsBetween = [...isBetween];
                if(event === 'BETWEEN') newIsBetween[index] = true;
                else newIsBetween[index] = false;
                return newIsBetween;
            })
        }

    }

    const onFinish = values => {

        mainData.setFilterInfo(values);
        message.success({
            content: 'Filters Added Successfully!!!!',
            className: 'custom-class',
            style: {
              marginTop: '20vh',
            },
        });
        console.log('Received values of form:', values);
    };

    const addExtra = () => {

        setDataType(dataType => {

            let newDataType = [...dataType];
            newDataType.push("Dtype");

            return newDataType;
        })

        setIsBetween(isBetween => {

            let newIsBetween = [...isBetween];
            newIsBetween.push(false)
            return newIsBetween;
        })

        setCount(count => {
            let newC = count;
            newC = newC + 1

            return newC;
        })

    }

    const popInitial = () => {

        setCount(count => {
            let newC = count;
            newC = newC - 1

            return newC;
        })
    }

    return(
        <Scrollbars  autoHide autoHeight autoHeightMax={ ( window.innerHeight >= 748 ? window.innerHeight*0.55 : window.innerHeight*0.54 )}>
            <h6 style = {{textAlign: "left", fontWeight: 900}}>Add and Apply Filters</h6>
            <p></p>
            <p></p>
            {
                initialFilter
                ?
                <Form layout="vertical" name="filter_form" onFinish={onFinish} onValuesChange = {handleValueChanges} onFieldsChange = {handleFieldsChanges} autoComplete="off">
                <Form.List name="filters" initialValue = {initialFilter}>
                    {(fields, { add, remove }) => { 
                        
                        return(
                        <>
                        {fields.map(({ key, name, fieldKey, ...restField}) => (
                        <Space key={key} className = {classes['Space']} align="center">
                            <Form.Item
                                {...restField}
                                name={[name, 'columnName']}
                                fieldKey={[fieldKey, 'columnName']}
                                label = {<div className={classes['Label']}>Column</div>}
                                rules={[{ required: true, message: 'Missing Column Name' }]}
                            >
                                <Select
                                    style = {{width: window.innerWidth*0.25, backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}  
                                    showSearch
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    bordered = {false}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange = {(event) => handleSelectChange(event,key)}
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {
                                        mainData.targetKeys.map((targetkey, index) => {
                                            
                                            if(!props.parentColumns[targetkey].includes("DATE"))
                                                return(
                                                    <Option  style = {{textTransform: "capitalize"}}  key = {index} value={props.parentColumns[targetkey]}>{props.parentColumns[targetkey].split("_").join(" ").toLowerCase()}</Option>
                                                );

                                            else
                                                return null;
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                {...restField}
                                name={[name, 'filterParam']}
                                fieldKey={[fieldKey, 'filterParam']}
                                label = {<div className={classes['Label']}>Filter Criteria</div>}
                                rules={[{ required: true, message: 'Missing Filter Criteria' }]}
                            >
                                <Select
                                    showSearch
                                    onChange = {(event) => handleBetween(event, key)}
                                    bordered = {false}
                                    style = {{width: window.innerWidth*0.25, backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"}} 
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {
                                        dataType.length >= (key + 1) && dataType[key] === "NUMBER"
                                        ?
                                        <>
                                            <Option value=">">Greater Than</Option>
                                            <Option value="<">Less Than</Option>
                                            <Option value="=">Equal To</Option>
                                            <Option value="<>">Not Equal To</Option>
                                            <Option value=">=">Greater Than Or Equal TO</Option>
                                            <Option value="<=">Less Than Or Equal TO</Option>
                                            <Option value="BETWEEN">Between</Option>
                                        </>
                                        :
                                        <>
                                            <Option value="=">Is</Option>
                                            <Option value="<>">Is Not</Option>
                                        </>
                                    }

                                </Select>

                            </Form.Item>
                            
                            {
                                dataType.length >= (key + 1) && dataType[key] === "NUMBER"
                                ?
                                <>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'filterValue']}
                                        fieldKey={[fieldKey, 'filterValue1']}
                                        label = {<div className={classes['Label']}>{isBetween[key] ? "Filter Value 1" : "Filter Value"}</div>}
                                        rules={[{ required: true, message: 'Missing Filter Value' }]}
                                    >
                                        
                                        <InputNumber 
                                            initialValues={1} 
                                            bordered={false} 
                                            style = {{width: isBetween[key] ? window.innerWidth*0.12 : window.innerWidth*0.25, backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"}} 
                                            placeholder={isBetween[key] ? "Enter Value 1" : "Enter Value"}
                                        />     

                                    </Form.Item>
                                    {
                                        isBetween[key]
                                        ?
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'filterValue1']}
                                            fieldKey={[fieldKey, 'filterValue']}
                                            label = {<div className={classes['Label']}>Filter Value</div>}
                                            rules={[{ required: true, message: 'Missing Filter Value' }]}
                                        >
                                            
                                            <InputNumber 
                                                initialValues={1} 
                                                bordered={false} 
                                                style = {{width: window.innerWidth*0.12, backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"}} 
                                                placeholder="Enter Value"
                                            />     

                                        </Form.Item>
                                        :
                                        null
                                    }
                                </>
                                :
                                <Form.Item
                                    {...restField}
                                    name={[name, 'filterValue']}
                                    fieldKey={[fieldKey, 'filterValue']}
                                    label = {<div className={classes['Label']}>Filter Value</div>}
                                    rules={[{ required: true, message: 'Missing Filter Value' }]}
                                >
                                    <Input 
                                        bordered = {false}
                                        style = {{width: window.innerWidth*0.25, backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"}} 
                                        placeholder="Enter Value"   
                                    />
                                </Form.Item>
                             }

                            <MinusCircleOutlined className = {classes['Remove']} onClick={() => { remove(name); popInitial(); }} />
                            <PlusCircleOutlined className = {classes['Add']} onClick={() => {add(); addExtra()}} />
                        </Space>
                        ))}
                        <p></p>
                        {
                            count === 0
                            ?
                            <Form.Item>
                                <Button type="dashed" className = {classes['DashedButton']}onClick={() => {add(); addExtra()}}  icon={<PlusOutlined />}>
                                    Add Filter
                                </Button>
                            </Form.Item>
                            :
                            null
                        }
                    </>
                    )}}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save Filters
                    </Button>
                </Form.Item>
            </Form>
                :
                null
            }
        </Scrollbars>
    );
}

export default Filter