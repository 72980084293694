import classes from './SignInPage.module.css'
import signInSignUpLogo from '../../assets/signInSignUp.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { Form, Input} from 'antd';
import {useState} from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';

const SignInPageC = (props) => {

    const history = useHistory()
    const [userCode, setUserCode] = useState("")
    const [password, setPassword] = useState("")
    const [codeIsValid, setCodeIsValid] = useState(true)
    const [passwordValid, setPasswordValid] = useState(true)

    const userChange = (e) => { 

        if(e.target.value !== "") setCodeIsValid(true)
        else setCodeIsValid(false)

        setUserCode(e.target.value)
    }

    const passwordChange = (e) => { 
        
        if(e.target.value !== "") setPasswordValid(true)
        else setPasswordValid(false)

        setPassword(e.target.value)
    }

    const submitHandler = (e) => {

        if(userCode === "") setCodeIsValid(false)

        if(password === "") setPasswordValid(false)

        if(userCode !== "" && password !== ""){

            setCodeIsValid(true)
            setPasswordValid(true)
            
            console.log("Submitting");

            const postData = {
                userCode: userCode,
                password: password,
                userType: "customer",
            }

            console.log(postData);
            props.setAnalysis(null)
            axios
            .post(props.url + '/api/v1/users/login', postData,  {
                withCredentials: true,
                credentials: 'include',
            })
            .then((responseA) => {

                axios
                .get(props.url + '/api/v1/customer/analysis',{withCredentials: true})
                .then((response) => {
                    
                    props.setAnalysis( response.data.data )

                    message.success({
                        content: 'Welcome!!!!',
                        className: 'custom-class',
                        style: {
                        marginTop: '20vh',
                        },
                    });

                    history.replace('/customer-portal')
                
                })
                .catch((err) => {
                    console.log(err);
                });
                console.log(responseA);
        
            })
            .catch((err) => {
                message.error({
                    content: 'Invalid Username or Password!!!!',
                    className: 'custom-class',
                    style: {
                      marginTop: '20vh',
                    },
                });
            });
        }
    }

    return(
        <div className="container">
                <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo" />
                <div className={classes['SignupDiv']}>
                    <p></p>
                    <Form id="signInForm" layout = "vertical">
                        <Row xs={1}>
                            <Col style={{ padding: '0px 30px' }}>
                                <Row xs={1} className = {classes['FormItem']}>
                                    <Form.Item 
                                        className = {classes['FormItem']}
                                        // label = {<div className={classes['Label']}>User Code</div>}
                                    >
                                        <Input 
                                            placeholder="Enter User Code"
                                            className = {codeIsValid ? classes["Input"] : classes["InvalidInput"]}
                                            value = {userCode}
                                            onChange = {userChange}
                                        />
                                        {!codeIsValid && <p style = {{color: "red"}}>User Code is Required</p>}
                                    </Form.Item>
                                </Row>
                                <p></p>
                                <Row xs={1} className = {classes['FormItem']}>
                                    <Form.Item 
                                        className = {classes['FormItem']}
                                        // label = {<div className={classes['Label']}>Password</div>}
                                    >
                                        <Input.Password
                                            className = {passwordValid ? classes["Input"] : classes["InvalidInput"]}
                                            placeholder="Enter Password"
                                            value = {password}
                                            onChange = {passwordChange}
                                        />
                                        {!passwordValid && <p style = {{color: "red"}}>Password is Required</p>}
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <p></p>
                    <p></p>
                    <button onClick = {(e) => submitHandler(e)} type="submit" form="signInForm" className={classes['Create']} variant="light">
                        <strong>Sign In to Report Writer</strong>
                    </button>
                </div>
            </div>
    );
}

export default SignInPageC