import { Col, Row} from 'antd';
import { useContext  } from 'react';
import DataContext from '../../Context/dataContext';
import classes from './ReportType.module.css';
import { Card } from 'antd';

const { Meta } = Card;

const ReportType = (props) => {

    const mainData = useContext(DataContext)

    return(
        <Row gutter={32} className={classes['TypeRow']}>
            <Col md = {8} xs={{span : 24}} className={classes['TypeCol']}>
                <a>
                    <Card
                        cover={
                        <img
                            alt="example"
                            src="https://image.freepik.com/free-vector/accounting-report-illustration_179970-718.jpg"
                        />
                        } 
                        
                        style = {{backgroundColor: mainData.reportType === "tabular" ? "teal" : "black"}}
                        className = {classes['TypeCard']}
                        onClick = {(event) => mainData.reportTypeHandler(event,"tabular")}
                    >
                        <Meta
                        title={<p style = {{color: "white"}}>TABULAR</p>}
                        description= {<p style = {{color: "white"}}>This provides a way to list the data in a simpler form.</p>}
                        />
                    </Card>
                </a>
                
            </Col>
            <Col md = {8} xs={{span : 24}} className={classes['TypeCol']}>
                <a>
                    <Card
                        cover={
                        <img
                            alt="example"
                            src="http://www.industriuscfo.com/wp-content/uploads/2015/01/financial_statement-1080x675.jpg"
                        />
                        } 
                        

                        style = {{  backgroundColor: mainData.reportType === "summary" ? "teal" : "black"}}

                        onClick = {(event) => mainData.reportTypeHandler(event,"summary")}

                        className = {classes['TypeCard']}
                        
                    >
                        <Meta
                        title={<p style = {{color: "white"}}>SUMMARY</p>}
                        description={<p style = {{color: "white"}} >This allows to view data along with subtotals, groupings and other summary information</p>}
                        />
                    </Card>
                </a>
            </Col>
            {
                mainData.parentReportData.report_matPos === 1
                ?
                <Col md = {8} xs={{span : 24}} className={classes['TypeCol']}>
                    <a>
                        <Card
                            cover={
                            <img
                                alt="example"
                                src="https://library.kissclipart.com/20181218/tkq/kissclipart-financial-report-vector-clipart-financial-statemen-951a92ac3211ebee.png"
                            />
                            } 
                            
                            className = {classes['TypeCard']}
                            
                            style = {{backgroundColor: mainData.reportType === "matrix" ? "teal" : "black"}}

                            onClick = {(event) => mainData.reportTypeHandler(event,"matrix")}

                        >
                            <Meta
                            title={<p style = {{color: "white"}}>MATRIX</p>}
                            description= {<p style = {{color: "white"}} >This provides a Grid layout to summarize data</p>}
                            />
                        </Card>
                    </a>
                </Col>
                :
                <Col md = {8} xs={{span : 24}} className={classes['TypeCol']}>
                        <Card
                            cover={
                            <img
                                style = {{filter: "grayscale(70%)"}}
                                alt="example"
                                src="https://library.kissclipart.com/20181218/tkq/kissclipart-financial-report-vector-clipart-financial-statemen-951a92ac3211ebee.png"
                            />
                            } 
                            
                            className = {classes['TypeCardX']}
                            
                        >
                            <Meta
                            title={<p style = {{color: "white"}}>MATRIX</p>}
                            description= {<p style = {{color: "white"}} >This provides a Grid layout to summarize data</p>}
                            />
                        </Card>
                </Col>
            }
        </Row>
    );
}

export default ReportType