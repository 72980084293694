import classes from '../Pages.module.css';
import {Row,Col, Skeleton} from 'antd';
import { useState, useEffect, useContext} from 'react';
import ToolRow from '../ToolRow/ToolRow';
import DataContext from '../../../Context/dataContext';
import moment from 'moment';
import axios from 'axios';
import CustomerTable from '../CustomerTable/CustomerTable';

const NetDue = (props) => {

    const customerData = useContext(DataContext)

    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState(null)
    const [date, setDate] = useState(moment(new Date()).format("DD/MM/YYYY"));
    const [total, setTotal] = useState('-');


    useEffect(() => {  
        setColumns([])
        setRows(null)
        setTotal('-')
        
        console.log(date);
        axios
            .get(customerData.URL + '/api/v1/customer/net-due?date=' + date,{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.netDue.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.netDue.rows.map((row,index) => {return(
                        {
                            "SNO": index + 1,
                            ...row
                        }
                    );})
                    return newRows;
                })

                setTotal(total => {
                    const newTotal = response.data.total
                    return newTotal;
                })
            })
            .catch((error) => {
                console.log(error);
            })


    }, [date])

    const onDateChange = (date, dateString) => {
        
        setDate(dateString)

    }

    return(
        <>            
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Net Due</p></Col>
                <Col className = {classes['Col']} md = {10}>
                   
                </Col>
            </Row>
            <p></p>
            <ToolRow ft = {false} date = {date} onDateChange = {onDateChange} netDue = {true} total = {total} ledger = {true}/>
            <p></p>
            {
                rows && columns.length > 0
                ?
                <>
                    <CustomerTable data = {rows} columns = {columns} netDue = {false}/>
                </>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
            <p></p>
        </>
    );
}

export default NetDue