import classes from './FieldDataC.module.css'
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Sauda from '../Sauda/Sauda';
import SalesOrder from '../SalesOrder/SalesOrder';
import SalesReturn from '../SalesReturn/SalesReturn';
import Invoices from '../Invoices/Invoices';
import Payments from '../Payments/Payments';
import NetDue from '../Outstanding/NetDue';
import Ageing from '../Outstanding/Ageing'
import Ledger from '../Ledger/Ledger';
import Profile from '../Profile/Profile';


const FieldDataC = (fieldProps) => {

    return(
        <div className={classes['Fields']}>
            <Switch>
                <Route path="/customer-portal" exact 
                    render={(props) => (
                        <Dashboard {...props} />
                    )}
                />
                <Route path="/customer-portal/sauda" exact 
                    render={(props) => (
                        <Sauda {...props} />
                    )}
                />
                <Route path="/customer-portal/sales-order" exact 
                    render={(props) => (
                        <SalesOrder {...props} />
                    )}
                />
                <Route path="/customer-portal/sales-return" exact 
                    render={(props) => (
                        <SalesReturn {...props} />
                    )}
                />
                
                <Route path="/customer-portal/invoices" exact 
                    render={(props) => (
                        <Invoices {...props} />
                    )}
                />
                <Route path="/customer-portal/payments" exact 
                    render={(props) => (
                        <Payments {...props} />
                    )}
                />
                <Route path="/customer-portal/outstanding/net-due" exact 
                    render={(props) => (
                        <NetDue {...props} />
                    )}
                />
                <Route path="/customer-portal/outstanding/ageing-analysis" exact 
                    render={(props) => (
                        <Ageing {...props} />
                    )}
                />
                <Route path="/customer-portal/ledger" exact 
                    render={(props) => (
                        <Ledger {...props} />
                    )}
                />
                <Route path="/customer-portal/profile" exact 
                    render={(props) => (
                        <Profile {...props} />
                    )}
                />
            </Switch>
        </div>
    );
}

export default FieldDataC