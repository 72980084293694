import { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, message, Modal, Form, Input, Select, Button} from 'antd';
import './MainPage.css';
import Chatbot from "react-chatbot-kit";
import classes from './MainPageExtra.module.css';
import SiderContent from '../../components/SiderContent/SiderContent';
import TitleRow from '../../components/TitleRow/TitleRow';
import FieldData from '../../components/FieldData/FieldData'
import { useHistory } from "react-router-dom";
import {DataProvider} from '../../Context/dataContext';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import config from '../../ChatBot/chatBotConfig';
import MessageParser from '../../ChatBot/MessageParser';
import ActionProvider from '../../ChatBot/ActionProvider';
import {FaRobot} from 'react-icons/fa'
import {RiBankFill} from 'react-icons/ri'
import {BiLayerPlus} from 'react-icons/bi'
import {IoTicket} from 'react-icons/io5'

const {Content, Sider } = Layout;
const { Option } = Select;

const MainPage = (props) => {

    const URL = props.url;
    
    const [f1, setF1] = useState(false);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [showBot, toggleBot] = useState(false);
    const [name, setName] = useState("")
    const [accountType, setAccountType] = useState("Saving")
    const [bank, setBank] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [allReports, setAllReports] = useState(null);
    const [collapsed, setCollapsed] = useState(true);
    const [company, setCompany] = useState(props.company);
    const [site, setSite] = useState(props.site);
    const [title, setTitle] = useState("All Reports");
    const [parentReportData, setParentReportData] = useState({
        report_cns: 0,
        report_matPos: 0,
    });

    const [module, setModule] = useState("All Reports");
    const [editMode, setEditMode] = useState(false);
    const [reportType, setReportType] = useState("");
    const [targetKeys, setTargetKeys] = useState([])
    const [summaryInfo, setSummaryInfo] = useState([])
    const [sortInfo, setSortInfo] = useState([])
    const [filterInfo, setFilterInfo] = useState(null)
    const [matrixSummaryInfo, setMatrixSummaryInfo] = useState([])
    const [viewName, setViewName] = useState("");
    const [chartType, setChartType] = useState("");
    const [pieColumn, setPieColumn] = useState("");
    const [pieRow, setPieRow] = useState("");
    const [viewDesc, setViewDesc] = useState("");
    const [columnsToTotal, setColumnsToTotal] = useState(null);
    const [tableColumns, setTableColumns] = useState([]);
    const [createNew, setCreateNew] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [siteMenu, setSiteMenu] = useState(null);
    const [filterString, setFilterString] = useState("");
    const [modules, setModules] = useState([]);
    const [aKey, setAKey] = useState("2");
    const [charts, setCharts] = useState(false);

    const onSiteChangeHandler = (event, code, name) => {
        setSite({code: code, name: name})
    };
    
    const saveMessages = (messages) => {
        localStorage.setItem("chat_messages", JSON.stringify(messages));
    };
    
    const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem("chat_messages"));
    return messages;
    };

    useEffect(() => {

        setAllReports(null)
        axios
            .get(URL + '/api/v1/modules',{withCredentials: true})
            .then((response) => {
                
                setModules(modules => {
                    const newModules = [...response.data.data.modules]
                    return newModules;
                })

            })
            .catch((err) => {
                console.log(err.response);
            });

        axios
            .get(URL + '/api/v1/reports',{withCredentials: true})
            .then((response) => {
                console.log(response)
                setAllReports(allReports => {
                    const newReports = [...response.data.data.reports.rows]
                    return newReports;
                })

            })
            .catch((err) => {
                console.log(err);
            });
        
        axios
        .get(URL + '/api/v1/cns',{withCredentials: true})
        .then((response) => {
            
            setCompanyList(companyList => {
                const newCompanies = [...response.data.data.companies]
                return newCompanies;
            })

            setSiteList(siteList => {
                const newSites = [...response.data.data.sites]
                return newSites;
            })

            setSiteMenu(
                <Menu>
                    {
                        response.data.data.sites.map( site => {
                            
                            if(site.COMPANY === company.code)
                            return (
                            <Menu.Item onClick = {(event) => onSiteChangeHandler(event, site.SITE_CODE, site.SITE_DESC)} key={site.SITE_CODE}>{site.SITE_DESC}</Menu.Item>
                            );
    
                            else return null;
                        })
                    }
                </Menu>
            );

        })
        .catch((err) => {
            console.log(err.response);
        });

    }, [])

    const history = useHistory();

    const reportTypeHandler = (event, val) => {
        setAKey("2")
        setReportType(val)
    }

    const editModeHandler = () => {

        mainData.setAKey("1");
        
        if(editMode) 
        {
            history.goBack(); 
            setTargetKeys([]);
            setSortInfo([]);
            setReportType("");
        }
        setEditMode(!editMode)
    }

    const set = () => {
        setEditMode(false)
    }

    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };

    const onCompanyChangeHandler = (event, code, name) => {
       setCompany({code: code, name: name})
       setSiteMenu(
            <Menu>
                {
                    siteList.map( site => {
                        
                        if(site.COMPANY === code)
                        return (
                        <Menu.Item onClick = {(event) => onSiteChangeHandler(event, site.SITE_CODE, site.SITE_DESC)} key={site.SITE_CODE}>{site.SITE_DESC}</Menu.Item>
                        );

                        else return null;
                    })
                }
            </Menu>
        );
    };

    const onTitleChangeHandler = (val) => {
        setTitle(val)
    };

    const onModuleChangeHandler = (val) => {
        setModule(val)
    };

    const mainData = {
        URL: URL,
        allReports: allReports,
        setAllReports: setAllReports,
        company: company,
        site: site,
        module: module,
        title: title,
        parentReportData: parentReportData,
        setParentReportData: setParentReportData,
        reportType: reportType,
        reportTypeHandler: reportTypeHandler,
        targetKeys: targetKeys,
        setTargetKeys: setTargetKeys,
        summaryInfo: summaryInfo,
        setSummaryInfo: setSummaryInfo,
        matrixSummaryInfo: matrixSummaryInfo,
        setMatrixSummaryInfo: setMatrixSummaryInfo,
        viewName: viewName,
        setViewName: setViewName,
        viewDesc: viewDesc,
        setViewDesc: setViewDesc,
        columnsToTotal: columnsToTotal,
        setColumnsToTotal: setColumnsToTotal,
        tableColumns: tableColumns,
        setTableColumns: setTableColumns,
        sortInfo: sortInfo,
        setSortInfo: setSortInfo,
        setEditMode: setEditMode,
        filterInfo: filterInfo,
        setFilterInfo: setFilterInfo,
        createNew: createNew,
        setCreateNew: setCreateNew,
        filterString: filterString,
        setFilterString: setFilterString,
        aKey: aKey,
        setAKey: setAKey,
        charts: charts,
        setCharts: setCharts,
        chartType: chartType,
        setChartType: setChartType,
        pieColumn: pieColumn,
        setPieColumn: setPieColumn,
        pieRow: pieRow,
        setPieRow: setPieRow,
    }
    
    const companyMenu = (
        <Menu>
            {
                companyList.map( company => {return (
                    <Menu.Item onClick = {(event) => onCompanyChangeHandler(event, company.COMPANY_CODE, company.COMPANY_NAME)} key={company.COMPANY_CODE}>{company.COMPANY_NAME}</Menu.Item>
                );})
            }
        </Menu>
    );
    
    const showModal = (event) => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        if(!f1){

            const postData = {
                name: name,
                accountType: accountType,
                bank : bank,
                contactNo: contactNo,
            }
            
            axios
            .post(URL + '/api/v1/banking/get-url/',
            postData,
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                
                console.log(response.data.data.response[0].tempUrl);
                window.open(response.data.data.response[0].tempUrl);
                setConfirmLoading(false);
                message.info({
                    content: 'Enter Details in the New Tab & Revert Back Here!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                
                setF1(true)
                
            })
            .catch((err) => {
                message.error({
                    content: 'Some Error Occurred!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setConfirmLoading(false)
                setF1(false)
            });
            
        }
        else{
            const postData = {
                name: name,
                accountType: accountType,
                bank : bank,
                contactNo: contactNo,
            }
            
            axios
            .post(URL + '/api/v1/banking/generate-file/',
            postData,
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                console.log(response);
                setConfirmLoading(false)
                message.info({
                    content: response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setF1(false)
                setVisible(false)
                
            })
            .catch((err) => {
                message.error({
                    content: 'Some Error Occurred!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setConfirmLoading(false)
                setF1(false)
                setVisible(false)
            });
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        setF1(false)
        setName("")
        setBank("")
        setContactNo("")
    };

    const onAChange = (value) => {
        setAccountType(value)
    }

    const onChange = (e, param) => {
        if(param === "name") setName(e.target.value)
        if(param === "bank") setBank(e.target.value)
        if(param === "contactNo") setContactNo(e.target.value)
    }

    const handleTicket = (event) => {
        window.open('https://lohabazaar.freshdesk.com')
    }

    const handleFeature = (event) => {
        window.open('https://app.productstash.io/the-report-writer#/ideas')
    }

    return(
        <Layout style={{ minHeight: '100vh', backgroundColor: "transparent"}}>
            {
                editMode
                ?
                null:
                <Sider className = {classes['Sider']}  collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <SiderContent url = {URL} modules = {modules} allReports = {allReports} editMode = {editMode} onTitleChangeHandler = {onTitleChangeHandler}/>
                </Sider>
            }

            <Layout className = {classes['SiteLayout']}>
                <DataProvider value = {mainData}>
                    <Scrollbars autoHeight autoHeightMax={ ( window.innerHeight*1 )}>
                        <Content>
                            <TitleRow editMode = {editMode} editModeHandler = {editModeHandler} module = {module} title = {title} site = {site} company = {company} siteMenu = {siteMenu} companyMenu = {companyMenu} companyList = {companyList}/>
                            <FieldData {...props} set = {set} editMode = {editMode} editModeHandler = {editModeHandler} onTitleChangeHandler = {onTitleChangeHandler} onModuleChangeHandler = {onModuleChangeHandler} company = {company} site = {site}/>
                        </Content>
                    </Scrollbars>
                </DataProvider>
                {showBot && (
                    <div className = {classes['ChatScreen']}>
                        <Chatbot
                            config={config}
                            actionProvider={ActionProvider}
                            messageHistory={loadMessages()}
                            messageParser={MessageParser}
                            saveMessages={saveMessages}
                            
                        />
                    </div>
                )}

                <Modal
                    title= "Bank Integration"
                    visible={visible}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                          Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                          {f1 ? "Generate File" : "Send Details" }
                        </Button>,
                    ]}
                >
                    <Form
                        layout="vertical"
                    >
                        <Form.Item 
                            label = {<div className={classes['Label']}>Name</div>}
                        >
                            <Input 
                                placeholder="Enter Name"
                                className = {classes["Input"]}
                                value = {name}
                                onChange = {(event) => onChange(event, "name")}
                            />
                        </Form.Item>
                        <Form.Item 
                            label = {<div className={classes['Label']}>Account Type</div>}
                        >
                            <Select 
                                placeholder="Select Account Type"
                                bordered = {false}
                                value = {accountType}
                                onChange = {(value) => onAChange(value)}
                                style={{ width: "100%",backgroundColor : "white",  textTransform: "capitalize", color: "black", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"}}
                                dropdownStyle = {{textTransform: "capitalize"}}
                            >
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "Saving" value="Saving">Saving</Option>
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = "Current" value="Current">Current</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label = {<div className={classes['Label']}>Bank</div>}
                        >
                            <Input 
                                placeholder="Enter Bank"
                                className = {classes["Input"]}
                                value = {bank}
                                onChange = {(event) => onChange(event, "bank")}
                            />
                        </Form.Item>
                        <Form.Item 
                            label = {<div className={classes['Label']}>Contact</div>}
                        >
                            <Input 
                                placeholder="Enter Contact"
                                className = {classes["Input"]}
                                value = {contactNo}
                                onChange = {(event) => onChange(event, "contactNo")}
                            />
                        </Form.Item>
                        
                    </Form>

                </Modal>
                <Row gutter = {16} style = {{backgroundColor: "transparent", width: "4rem", position: "fixed", bottom: "20px", right: "20px", textAlign: "right"}}>
                    <Col md = {24} >
                        <button className = {classes['ChatBot']} onClick={(event) => handleFeature(event)}><BiLayerPlus style = {{color: "white"}}/></button>
                    </Col>
                    <Col md = {24} >
                        <p></p>
                    </Col>
                    <Col md = {24} >
                        <button className = {classes['ChatBot']} onClick={(event) => handleTicket(event)}><IoTicket style = {{color: "white"}}/></button>
                    </Col>
                    <Col md = {24} >
                        <p></p>
                    </Col>
                    <Col md = {24} >
                        <button className = {classes['ChatBot']} onClick={(event) => showModal(event)}><RiBankFill style = {{color: "white"}}/></button>
                    </Col>
                    <Col md = {24} >
                        <p></p>
                    </Col>
                    <Col md = {24} >
                        <button className = {classes['ChatBot']} onClick={() => toggleBot((prev) => !prev)}><FaRobot style = {{color: "white"}}/></button>
                    </Col>
                    <Col md = {24} >
                        <p></p>
                    </Col>
                </Row>
            </Layout>

           

        </Layout>
    );
}

export default (MainPage);