import {Row} from 'antd';
import classes from '../Pages.module.css';
import NormDataField from './NormDataField'

const BasiCSalaryDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = dateString
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = val
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.EMPLOYEE_CODE} type = "Select" name = "EMPLOYEE NAME" param = "EMPLOYEE_CODE" value = {props.data.EMPLOYEE_CODE ? props.data.EMPLOYEE_CODE : null }/> 
                {!props.create && <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "SALARY NORMS CODE" param = "SALARY_NORMS_HDR_CODE" value = {props.data.SALARY_NORMS_HDR_CODE ? props.data.SALARY_NORMS_HDR_CODE : null }/> }
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.EMPLOYEE_TYPE} type = "Select" name = "EMPLOYEE TYPE" param = "EMP_TYPE" value = {props.data.EMP_TYPE ? props.data.EMP_TYPE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.STATE_CODE} type = "Select" name = "STATE" param = "STATE_CODE" value = {props.data.STATE_CODE ? props.data.STATE_CODE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.DEPARTMENT_CODE} type = "Select" name = "DEPARTMENT" param = "DEPARTMENT_CODE" value = {props.data.DEPARTMENT_CODE ? props.data.DEPARTMENT_CODE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.DESIGNATION_CODE} type = "Select" name = "DESIGNATION" param = "DESIGNATION_CODE" value = {props.data.DESIGNATION_CODE ? props.data.DESIGNATION_CODE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.BRANCH_CODE} type = "Select" name = "BRANCH" param = "BRANCH_CODE" value = {props.data.BRANCH_CODE ? props.data.BRANCH_CODE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "GROSS SALARY" param = "GROSS_SAL" value = {props.data.GROSS_SAL ? props.data.GROSS_SAL : null }/> 
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "SALARY FROM" param = "GROSS_FROM" value = {props.data.GROSS_FROM ? props.data.GROSS_FROM : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "SALARY TO" param = "GROSS_TO" value = {props.data.GROSS_TO ? props.data.GROSS_TO : null }/>                
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "FROM DATE" param = "FROM_DATE" value = {props.data.FROM_DATE ? props.data.FROM_DATE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "TO DATE" param = "TO_DATE" value = {props.data.TO_DATE ? props.data.TO_DATE : null }/> 
            </Row>
            <p></p>
        </div>
    );
}

export default BasiCSalaryDetails