import classes from './SignInPage.module.css'
import signInSignUpLogo from '../../assets/signInSignUp.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { Form, Input} from 'antd';
import {useState} from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';

const SignIn = (props) => {

    const history = useHistory()
    const [userCode, setUserCode] = useState("")
    const [password, setPassword] = useState("")
    const [codeIsValid, setCodeIsValid] = useState(true)
    const [passwordValid, setPasswordValid] = useState(true)
    const [type, setType] = useState("employee")
    const [label, setLabel] = useState("Report Writer")

    const userChange = (e) => { 

        if(e.target.value !== "") setCodeIsValid(true)
        else setCodeIsValid(false)

        setUserCode(e.target.value)
    }

    const passwordChange = (e) => { 
        
        if(e.target.value !== "") setPasswordValid(true)
        else setPasswordValid(false)

        setPassword(e.target.value)
    }

    const submitHandler = (e) => {

        if(userCode === "") setCodeIsValid(false)

        if(password === "") setPasswordValid(false)

        if(userCode !== "" && password !== ""){

            setCodeIsValid(true)
            setPasswordValid(true)
            
            console.log("Submitting");

            const postData = {
                userCode: userCode,
                password: password,
                userType: type,
            }

            console.log(postData);

            axios
            .post(props.url + '/api/v1/users/login', postData,  {
                withCredentials: true,
                credentials: 'include',
            })
            .then((responseA) => {

                console.log(responseA);
                if(type === "employee"){
                    axios
                    .get(props.url + '/api/v1/cns',{withCredentials: true})
                    .then((response) => {
                        
                        const index = response.data.data.companies.findIndex((element) => element.COMPANY_CODE === responseA.data.data.user.COMPANY_CODE)
                        props.setCompany({code: responseA.data.data.user.COMPANY_CODE, name:response.data.data.companies[index].COMPANY_NAME});

                        const index2 = response.data.data.sites.findIndex((element) => element.SITE_CODE === responseA.data.data.user.UNIT_CODE)
                        props.setSite({code: responseA.data.data.user.UNIT_CODE, name: response.data.data.sites[index2].SITE_DESC});
                        
                        message.success({
                            content: 'Welcome to O2C Report Writer!!!!',
                            className: 'custom-class',
                            style: {
                            marginTop: '20vh',
                            },
                        });

                        history.replace('/reports')

                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
                
                if(type === "payroll"){
                    history.replace('/payroll')
                }
                if(type === "SalesModule"){
                    history.replace('/Sales')
                }
            })
            .catch((err) => {
                message.error({
                    content: 'Invalid Username or Password!!!!',
                    className: 'custom-class',
                    style: {
                      marginTop: '20vh',
                    },
                });
            });
        }
    }

    const typeHandler = (event, val) => {
        setType(val)
        if(val === "employee"){
            setLabel('Report writer')
        }

        if(val === "payroll"){
            setLabel("Payroll Portal")
        }
        if(val === "SalesModule"){
            setLabel("Sales Module")
        }
    } 

    return(
        <div className="container">
                <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo" />
                <Row xs = {{span: 3, offset: 3}} className = {classes['Row']}>
                    <Col><button onClick = {(event) => typeHandler(event, "employee")} className={type === "employee" ? classes['SelectedButton'] : classes["NewButton"]}>Report Writer</button></Col>
                    <Col><button onClick = {(event) => typeHandler(event, "payroll")} className={type === "payroll" ? classes['SelectedButton'] : classes["NewButton"]}>Payroll Portal</button></Col>
                    <Col><button onClick = {(event) => typeHandler(event, "SalesModule")} className={type === "SalesModule" ? classes['SelectedButton'] : classes["NewButton"]}>Sales Module</button></Col>
                </Row>
                <div className={classes['SignupDiv']}>
                    <p></p>
                    <Form id="signInForm" layout = "vertical">
                        <Row xs={1}>
                            <Col style={{ padding: '0px 30px' }}>
                                <Row xs={1} className = {classes['FormItem']}>
                                    <Form.Item 
                                        className = {classes['FormItem']}
                                        // label = {<div className={classes['Label']}>User Code</div>}
                                    >
                                        <Input 
                                            placeholder="Enter User Code"
                                            className = {codeIsValid ? classes["Input"] : classes["InvalidInput"]}
                                            value = {userCode}
                                            onChange = {userChange}
                                        />
                                        {!codeIsValid && <p style = {{color: "red"}}>User Code is Required</p>}
                                    </Form.Item>
                                </Row>
                                <p></p>
                                <Row xs={1} className = {classes['FormItem']}>
                                    <Form.Item 
                                        className = {classes['FormItem']}
                                        // label = {<div className={classes['Label']}>Password</div>}
                                    >
                                        <Input.Password
                                            className = {passwordValid ? classes["Input"] : classes["InvalidInput"]}
                                            placeholder="Enter Password"
                                            value = {password}
                                            onChange = {passwordChange}
                                        />
                                        {!passwordValid && <p style = {{color: "red"}}>Password is Required</p>}
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <p></p>
                    <p></p>
                    <button onClick = {(e) => submitHandler(e)} type="submit" form="signInForm" className={classes['Create']} variant="light">
                        <strong>{"Sign In to " + label}</strong>
                    </button>
                </div>
            </div>
    );
}

export default SignIn