import classes from '../Pages.module.css';
import {Row,Col, Tabs, Skeleton, message} from 'antd';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useState, useEffect, useContext} from 'react';
import BasicChargeDetails from './BasicChargeDetails';
import ChargeDetails from './ChargeDetails';
import { useHistory } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";

const { TabPane } = Tabs;

const ChargeCreate = (props) => {

    const employeeData = useContext(DataContext)
    const history = useHistory();

    const [ad, setAD] = useState(null)
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(true)
    const [allData, setAllData] = useState(
      {
        "header": {
          "metaData": [
            
            {
              "name": "INVOICE_TYPE_CODE"
            },
           
          ],
          "rows": [
            {
              
              "INVOICE_TYPE_CODE": null,
             
            }
          ]
        },
        "details": {
          "metaData": [
            {
              "name": "CHARGE_CODE"
            },
            {
              "name": "CHARGE_EFFECT"
            },
            {
              "name": "VALUE"
            },
            {
                "name": "RATE_TYPE"
              },
            
          ],
          "rows": []
        }
      }
    )

    useEffect(() => {

      setAD(null)
      axios
        .get(employeeData.URL + '/api/v1/charges/additional-data',{
            withCredentials: true
        })
        .then((response) => {

            console.log(response);
            setAD(ad => {
                let newad = response.data.data
                return({
                  ...newad,
                  RATE_TYPE: {
                    metaData: [{name: "KEY"},{name: "VALUE"}],
                    rows: [{KEY: "%", VALUE: "Percentage"},{KEY: "A", VALUE: "Amount"}]
                  },
                  CHARGE_EFFECT: {
                    metaData: [{name: "KEY"},{name: "VALUE"}],
                    rows: [{KEY: "+", VALUE: "+"},{KEY: "-", VALUE: "-"}]
                  },
                
                });
            }) 
        })
        .catch((error) => {
            console.log(error);
        })

    },[])

    const handleSave = (event) => {

      var formv = true
      let count = 0;
      allData.details.rows.forEach(data => {
          
          if(!data){

              formv = formv&&false
              if(count === 0){
                  message.error({
                      content: 'Empty Fields In LEAVE NORM DETAILS Tab!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '1vh',
                      },
                  });
              }

              count = count + 1;
          }
      })

      if(formv){

        console.log("Submitting");
       
        const postData = {
          "header": allData.header.rows,
        
        }

        console.log(JSON.stringify(postData, undefined, 2));
        setLoading(true)
        axios
          .post(employeeData.URL + '/api/v1/charges/create', postData,  {
              withCredentials: true,
              credentials: 'include',
          })
          .then((response) => {
              message.success({
                  content: 'Charge Definition Created Successfully!!!',
                  className: 'custom-class',
                  style: {
                      marginTop: '2vh',
                  },
              });
              setLoading(false)
              history.replace('/sales/chargedefMaster/charge-details')
          })
          .catch((err) => {
              message.error({
                  content: 'An Error Occurred!!!!',
                  className: 'custom-class',
                  style: {
                      marginTop: '2vh',
                  },
              });
              setLoading(false)
          });
      }

    }

    const handleCancel = (event) => {

      setLoading(false)
      history.replace('/sales/chargedefMaster/charge-details')
    }
    return(
        ad
        ?
        <Row className = {classes['RowP']}>
            <Col lg = {editMode ? 13 : 19} md = {editMode ? 13 : 19} className = {classes['Col']} >

            </Col>
            {
                editMode
                ?
                <>
                    <Col md = {4} >
                        {
                          loading
                          ?
                          <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                          :
                          <button style = {{ backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                        }
                        
                    </Col>
                    <Col md = {4} >
                        {
                          loading
                          ?
                          <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                          :
                          <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancel(event)} className = {classes['ProfileButton']}>Cancel</button>
                        }
                    </Col>
                </>
                :
                <Col md = {4} >
                    <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Details</button>
                </Col>
            }
            <Col lg = {1} md = {1} >

            </Col>
            <Col lg = {24} md = {24} className = {classes['Col']}>
                <p></p>
                <div className = {classes['EditView']}>
                    <Tabs defaultActiveKey="0" centered style = {{ height: "100%"}} tabPosition={"left"} type = "line" >
                        <TabPane tab= {<span>Basic Details</span>} key="0">
                            <BasicChargeDetails create = {true} ad = {ad} setData = {setAllData} data = {allData.header.rows[0]} editMode = {editMode} />
                        </TabPane>
                        <TabPane tab= {<span>Charge Def Details</span>} key="1">
                            <p></p>
                            <ChargeDetails ad = {ad} editMode = {editMode}  setData = {setAllData} data = {allData.details.rows} />
                        </TabPane>
                    </Tabs>
                </div>
            </Col>       
        </Row>
        :
        <>
          <Skeleton active = {true}/>
          <Skeleton active = {true}/>
          <Skeleton active = {true}/>
        </>
    );
}

export default ChargeCreate;