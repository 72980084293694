import classes from './SiderContentC.module.css'
import { Menu, message } from 'antd';
import { FaSignOutAlt, FaBusinessTime,FaMoneyCheckAlt} from "react-icons/fa"
import signInSignUpLogo from '../../../assets/signInSignUp.png'
import {MdDashboard} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { useHistory } from 'react-router';
import {MdWatchLater, MdAssignmentTurnedIn} from 'react-icons/md';
import {BsFillGrid3X3GapFill, BsFillPeopleFill} from 'react-icons/bs'
import {GiRuleBook} from 'react-icons/gi';

const { SubMenu } = Menu;

const SiderContentC = (props) => {
    
    const history = useHistory()

    const handleLogout = (event) => {

        axios
        .get(props.url + '/api/v1/users/logout',{
            withCredentials: true
        })
        .then((response) => {
            message.success({
                content: 'Goodbye!!!! See You Again!!!',
                className: 'custom-class',
                style: {
                marginTop: '20vh',
                },
            });
            history.replace('/employee')
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <>  
            <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo"/>
            <Menu className = {classes['Menu']}>
                <Menu.Item key="empDashboard" icon={<MdDashboard />}>   
                    <NavLink to = '/payroll'>
                        Dashboard
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="empDetails" icon={<BsFillPeopleFill />}>   
                    <NavLink to = '/payroll/employee-details'>
                        Employee Details
                    </NavLink>
                </Menu.Item>
                {/* <Menu.Item key="empLO" icon={<FaBusinessTime />}>   
                    <NavLink to = '/payroll/leave-opening'>
                        Leave Opening
                    </NavLink>
                </Menu.Item> */}
                {/* <Menu.Item key="empSM" icon = {<MdWatchLater />}>   
                    <NavLink to = '/payroll/shift-master'>
                        Shift Master
                    </NavLink>
                </Menu.Item> */}
                <SubMenu key="norms" title="Norms" icon = {<GiRuleBook />}>
                    <Menu.Item key="leaveNorms" >
                        <NavLink to = '/payroll/norms/leave-norms'>
                            Leave Norms
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="salaryNorms" >
                        <NavLink to = '/payroll/norms/salary-norms'>
                            Salary Norms
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="inoutNorm" >
                        <NavLink to = '/payroll/norms/inout-norms'>
                            InOut Norms
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="advanceNorm" >
                        <NavLink to = '/payroll/norms/salary-advance'>
                            Salary Advance
                        </NavLink>
                    </Menu.Item>
                    
                </SubMenu>
                <SubMenu key="transactions" title="Transactions" icon = {<MdAssignmentTurnedIn />}>
                    <Menu.Item key="daily-attendance" >
                        <NavLink to = '/payroll/transactions/daily-attendance'>
                            Daily Attendance
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="leave" >
                        <NavLink to = '/payroll/transactions/leave'>
                            Leave Entry
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="salary-processing" >
                        <NavLink to = '/payroll/transactions/salary-processing'>
                            Salary Processing
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="salarywadges" title="Salary and wadges" icon = {<FaMoneyCheckAlt />}>
                    <Menu.Item key="employee-data" >
                        <NavLink to = '/payroll/salarywadges/employee-data'>
                            Employee Data
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="attandance-data" >
                        <NavLink to = '/payroll/salarywadges/attandance-data'>
                            Attandance Data
                        </NavLink>
                    </Menu.Item>
                   
                </SubMenu>
                <SubMenu key="misc" title="Miscelleneous" icon = {<BsFillGrid3X3GapFill />}>
                    {
                        props.miscList && props.miscList.length > 0
                        ?
                        <>
                            {
                                props.miscList.map(misc => {
                                    return(
                                        <Menu.Item key={misc.TABLE_CODE} >
                                            <NavLink to = {'/payroll/misc/' + misc.SLUG}>
                                                {misc.FORM_NAME}
                                            </NavLink>
                                        </Menu.Item>
                                    );
                                })
                            }
                        </>
                        :
                        null
                    }
                </SubMenu>
                <Menu.Item key="empLogout" onClick = {(event) => handleLogout(event)} icon={<FaSignOutAlt style = {{color : "red", opacity: "0.7"}}/>}>   
                    <NavLink to = '/customer-portal'>
                        Logout
                    </NavLink>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default SiderContentC