import classes from './FieldDataC.module.css'
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Misc from '../Misc/Misc'
import LeaveOpening from '../LeaveOpening/LeaveOpening';
import ShiftMaster from '../ShiftMaster/ShiftMaster';
import EmployeeDetails from '../EmployeeDetails/EmployeeDetails';
import EmployeeView from '../EmployeeDetails/EmployeeView';
import EmployeeNew from '../EmployeeDetails/EmployeeNew';
import LeaveNorms from '../Norms/LeaveNorms';
import SalaryNorms from '../Norms/SalaryNorms';
import InOutNorms from '../Norms/InOutNorms';
import LeaveNormsCreate from '../Norms/LeaveNormsCreate';
import SalaryNormsCreate from '../Norms/SalaryNormCreate';
import LeaveNormsView from '../Norms/LeaveNormsView';
import SalaryNormView from '../Norms/SalaryNormView';
import DailyAttendance from '../Transactions/DailyAttendance';
import Leave from '../Transactions/Leave';
import SalaryProcessing from '../Transactions/SalaryProcessing';
import AdvanceForm from '../Norms/AdvanceForm';
import AdvanceFormCreate from '../Norms/AdvanceFormCreate';
import AdvanceFormView from '../Norms/AdvanceFormView';
import EmployeeData from '../SalaryWadges/EmployeeData';
import AttandanceData from '../SalaryWadges/AttandanceData';

const FieldDataC = (fieldProps) => {

    return(
        <div className={classes['Fields']}>
            <Switch>
                <Route path="/payroll" exact 
                    render={(props) => (
                        <Dashboard {...props} />
                    )}
                />

                <Route path="/payroll/employee-details" exact 
                    render={(props) => (
                        <EmployeeDetails {...props} />
                    )}
                />

                <Route path="/payroll/employee-details-view/:id" exact 
                    render={(props) => (
                        <EmployeeView {...props} />
                    )}
                />

                <Route path="/payroll/employee-create" exact 
                    render={(props) => (
                        <EmployeeNew {...props} />
                    )}
                />

                <Route path="/payroll/leave-opening" exact 
                    render={(props) => (
                        <LeaveOpening {...props} />
                    )}
                />

                <Route path="/payroll/shift-master" exact 
                    render={(props) => (
                        <ShiftMaster {...props} />
                    )}
                />

                <Route path="/payroll/norms/leave-norms" exact 
                    render={(props) => (
                        <LeaveNorms {...props} />
                    )}
                />

                <Route path="/payroll/norms/leave-norms-view/:id" exact 
                    render={(props) => (
                        <LeaveNormsView {...props} />
                    )}
                />

                <Route path="/payroll/norms/leave-norms-create" exact 
                    render={(props) => (
                        <LeaveNormsCreate {...props} />
                    )}
                />

                <Route path="/payroll/norms/salary-norms" exact 
                    render={(props) => (
                        <SalaryNorms {...props} />
                    )}
                />

                <Route path="/payroll/norms/salary-norms-view/:id" exact 
                    render={(props) => (
                        <SalaryNormView {...props} />
                    )}
                />

                <Route path="/payroll/norms/salary-norms-create" exact 
                    render={(props) => (
                        <SalaryNormsCreate {...props} />
                    )}
                />

                <Route path="/payroll/norms/inout-norms" exact 
                    render={(props) => (
                        <InOutNorms {...props} />
                    )}
                />
                <Route path="/payroll/norms/salary-advance" exact 
                    render={(props) => (
                        <AdvanceForm {...props} />
                    )}
                />
                 <Route path="/payroll/norms/advance_form_view/:id" exact 
                    render={(props) => (
                        <AdvanceFormView {...props} />
                    )}
                />
                 <Route path="/payroll/norms/advance-form-create" exact 
                    render={(props) => (
                        <AdvanceFormCreate {...props} />
                    )}
                />

                <Route path="/payroll/transactions/daily-attendance" exact 
                    render={(props) => (
                        <DailyAttendance {...props} />
                    )}
                />

                <Route path="/payroll/transactions/leave" exact 
                    render={(props) => (
                        <Leave {...props} />
                    )}
                />

                <Route path="/payroll/transactions/salary-processing" exact 
                    render={(props) => (
                        <SalaryProcessing {...props} />
                    )}
                />
                 <Route path="/payroll/salarywadges/employee-data" exact 
                    render={(props) => (
                        <EmployeeData {...props} />
                    )}
                />
                 <Route path="/payroll/salarywadges/attandance-data" exact 
                    render={(props) => (
                        <AttandanceData {...props} />
                    )}
                />

                <Route path="/payroll/misc/:id" exact 
                    render={(props) => (
                        <Misc {...props} />
                    )}
                />

            </Switch>
        </div>
    );
}

export default FieldDataC