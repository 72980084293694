import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import SkillEdit from './SkillEdit';

const Skill = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24}  options = {props.ad.SKILL_CODE} type = "Select" name = "SKILL" value = {data.SKILL_CODE}/>     
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <SkillEdit skillOptions = {props.ad.SKILL_CODE} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Skill;