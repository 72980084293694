import classes from '../Pages.module.css';
import {Row,Col, Skeleton} from 'antd';
import { useState, useEffect, useContext} from 'react';
import ToolRow from '../ToolRow/ToolRow';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import VendorTable from '../VendorTable/VendorTable';

const months = { "01": "JAN", "02": "FEB", "03": "MAR", "04": "APR", "05": "MAY", "06": "JUN", "07": "JUL", "08": "AUG", "09": "SEP", "10": "OCT", "11": "NOV", "12": "DEC"}

const Ledger = (props) => {

    const vendorData = useContext(DataContext)

    const [type, setType] = useState('all');
    const [ft, setFT] = useState(false)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState(null)
    const [ob, setOB] = useState(0)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [dFilter, setDFilter] = useState("month")

    useEffect(() => {

        if(dFilter !== "month"){
            if(dFilter !== "fromTo")
            {
                setFT(false)
                setToDate("")
                setFromDate("")
                setRows(null)
                setOB(0)

                axios
                .get(vendorData.URL + '/api/v1/vendor/ledger?date=' + dFilter,{
                    withCredentials: true
                })
                .then((response) => {

                    console.log(response);
                    setColumns((columns) => {
                        let newCols =  response.data.data.ledgers.metaData.map((col) => {
                            return({
                                name: col.name,
                                title: col.name.split("_").join(" ").toLowerCase()
                            });
                        })

                        const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                        return newNewCols;
                    })

                    setRows(rows => {
                        let newRows = response.data.data.ledgers.rows.map((row,index) => {return(
                            {
                                "SNO": index + 1,
                                ...row
                            }
                        );})
                        return newRows;
                    })

                    setOB(ob => {
                        const newob = response.data.data.openingBalance
                        return newob
                    })

                })
                .catch((error) => {
                    console.log(error);
                })
            }

            else{
                setFT(true)
                if(fromDate !== "" && toDate !== ""){

                    setRows(null)
                    setOB(0)

                    let from = fromDate.split("-")[0] + "-" + months[fromDate.split("-")[1]] + "-" + fromDate.split("-")[2]
                    let to = toDate.split("-")[0] + "-" + months[toDate.split("-")[1]] + "-" + toDate.split("-")[2]

                    axios
                    .get(vendorData.URL + '/api/v1/vendor/ledger?from=' + from + "&to=" + to,{
                        withCredentials: true
                    })
                    .then((response) => {

                        console.log(response);
                        setColumns((columns) => {
                            let newCols =  response.data.data.ledgers.metaData.map((col) => {
                                return({
                                    name: col.name,
                                    title: col.name.split("_").join(" ").toLowerCase()
                                });
                            })

                            const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                            return newNewCols;
                        })

                        setRows(rows => {
                            let newRows = response.data.data.ledgers.rows.map((row,index) => {return(
                                {
                                    "SNO": index + 1,
                                    ...row
                                }
                            );})
                            return newRows;
                        })

                        setOB(ob => {
                            const newob = response.data.data.openingBalance
                            return newob
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
            }
        }

    }, [dFilter, fromDate, toDate])

    useEffect(() => {  

        if(dFilter === "month"){

            setFT(false);
            setToDate("")
            setFromDate("")
            setRows(null)
            setOB(0)

            axios
            .get(vendorData.URL + '/api/v1/vendor/ledger',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.data.ledgers.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.data.ledgers.rows.map((row,index) => {return(
                        {
                            "SNO": index + 1,
                            ...row
                        }
                    );})
                    return newRows;
                })

                setOB(ob => {
                    const newob = response.data.data.openingBalance
                    return newob
                })

            })
            .catch((error) => {
                console.log(error);
            })
            
        }      
        
    }, [dFilter])

    const handleClick = (event, val) => {
        setType(val)
    }

    const onDChange = (value, param) => {

        setDFilter(value)
    }

    const onDateChange = (date, dateString) => {
        
        setFromDate(dateString[0])
        setToDate(dateString[1])

    }

    return(
        <>            
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Ledgers</p></Col>
                <Col className = {classes['Col']} md = {10}>
                    <Row>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "all")} className = {type === "all" ? classes['PageButton'] : classes['PageButton2']}>ALL</button></Col>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "credit")} className = {type === "credit" ? classes['PageButton'] : classes['PageButton2']}>CREDIT</button></Col>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "debit")} className = {type === "debit" ? classes['PageButton'] : classes['PageButton2']}>DEBIT</button></Col>
                    </Row>
                </Col>
            </Row>
            <p></p>
            <ToolRow ledger = {true} ob = {ob} data = {rows} ft = {ft} fromDate = {fromDate} toDate = {toDate} onDateChange = {onDateChange} dFilter = {dFilter} onDChange = {onDChange}/>
            {
                rows && columns.length > 0
                ?
                <>
                   <VendorTable data = {rows} columns = {columns} />
                </>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default Ledger