import {Row,Col,Skeleton } from 'antd'
import axios from 'axios';
import classes from '../Pages.module.css';
import { useState, useEffect, useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import TransactionTable from './TransactionTable';
import DAForm from './DAForm';
import moment from 'moment';

const DailyAttendance = (props) => {

    const employeeData = useContext(DataContext)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState(null)
    const [ad, setAD] = useState(null)
    const [form, setForm] = useState({
        ATTEND_CODE: null,
        SITE: 1,
        ATTEND_DATE: moment(new Date()).format("DD/MM/YYYY"),
        DEPARTMENT: null,
        EMP_TYPE: null,
    })

    useEffect(() => {

        setRows(null)
        setColumns([])
        setAD(null)
        console.log(form);
        axios
            .get(employeeData.URL + '/api/v1/transactions/daily-att-data',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAD(ad => {
                    let newad = response.data.data
                    return ({
                        ...newad,
                        STATUS: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [
                                {KEY: "P", VALUE: "Present"},
                                {KEY: "HP", VALUE: "H Present"},
                                {KEY: "L", VALUE: "Leave"},
                                {KEY: "H", VALUE: "Holiday"},
                                {KEY: "W", VALUE: "Weekly Off"},
                                {KEY: "O", VALUE: "OD"},
                                {KEY: "S", VALUE: "SM"},
                                {KEY: "C", VALUE: "CO"},
                                {KEY: "N", VALUE: "NA"},
                            ]
                        },
                        LEAVE_PERIOD: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [
                                {KEY: 1, VALUE: "Full Day"},
                                {KEY: 0.5, VALUE: "Half Day"},
                                {KEY: 0, VALUE: "NA"},
                            ]
                        }
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            })

        axios
            .get(employeeData.URL + '/api/v1/transactions/daily-attendance?site=1&date=' + form.ATTEND_DATE,{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.data.attendance.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.data.attendance.rows.map((row,index) => {
                        
                        var d12 = new Date(Date.parse("2017-05-02T" + row["TIME_IN"]));
                        var d22 = new Date(Date.parse("2017-05-02T" + row['TIME_OUT']));
                        var d32 = new Date(d22 - d12);
                        var d02 = new Date(0);
                        var ans2 = d32.getHours() - d02.getHours()
                                                                                                                            
                        return(
                            {
                                "SNO": index + 1,
                                ...row,
                                "NO_WORKING_HRS": Math.abs(ans2),
                                "OVERTIME_HRS": row["OVERTIME_HRS"] ? parseInt(row["OVERTIME_HRS"]) : 0,
                            }
                        );})
                    return newRows;
                })
            })
            .catch((error) => {
                console.log(error);
            })
        
    }, [])

    const handleSave = (event) => {

        const postData = {
            ATTEND_CODE: form.ATTEND_CODE ? form.ATTEND_CODE : '',
            SITE: form.SITE,
            ATTEND_DATE: form.ATTEND_DATE,
            DEPARTMENT: form.DEPARTMENT ? form.DEPARTMENT : '',
            EMP_TYPE: form.EMP_TYPE ? form.EMP_TYPE : '',
        }

        console.log(postData);
        setRows(null)
        console.log(form);
        axios
            .get(employeeData.URL + '/api/v1/transactions/daily-attendance?site='+ postData.SITE.toString() +'&date=' + postData.ATTEND_DATE + 
                                    '&empType=' + postData.EMP_TYPE.toString() + '&code='+ postData.ATTEND_CODE.toString() +'&dept=' + postData.DEPARTMENT.toString(),{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.data.attendance.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.data.attendance.rows.map((row,index) => {
                        
                        var d12 = new Date(Date.parse("2017-05-02T" + row["TIME_IN"]));
                        var d22 = new Date(Date.parse("2017-05-02T" + row['TIME_OUT']));
                        var d32 = new Date(d22 - d12);
                        var d02 = new Date(0);
                        var ans2 = d32.getHours() - d02.getHours()

                        return(
                            {
                                "SNO": index + 1,
                                ...row,
                                "NO_WORKING_HRS": Math.abs(ans2),
                                "OVERTIME_HRS": row["OVERTIME_HRS"] ? parseInt(row["OVERTIME_HRS"]) : 0,
                            }
                        );})
                    
                    console.log(newRows);
                    return newRows;
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return(
        <>
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Daily Attendance</p></Col>
                <Col className = {classes['Col']} md = {10}>
                  
                </Col>
                <Col className = {classes['Col']} md = {24}>
                  
                </Col>
            </Row>
            {
                form && ad
                ?
                <DAForm ad = {ad} form = {form} setForm = {setForm} handleSave = {handleSave}/>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
            <hr></hr>
            <p></p>
            {
                ad && rows && columns.length > 0
                ?
                <TransactionTable form = {form} ad = {ad} data = {rows} columns = {columns}/>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default DailyAttendance