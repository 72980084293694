import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import DependentEdit from './DependentEdit';

const Dependent = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24} name = "NAME" value = {data.NAME}/> 
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.RELATION_CODE} type = "Select" name = "RELATION" value = {data.RELATION_CODE}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "DOB" value = {!data.DOB ? null : data.DOB.format('DD-MM-YYYY')}/> 
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.GENDER} type = "Select" name = "GENDER" value = {data.GENDER}/> 
                        <DataField editMode = {false} lg = {8} md = {24} options = {props.ad.EDUCATION} type = "Select" name = "EDUCATION" value = {data.EDUCATION}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "EARNING" value = {data.EARNING}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "INSURANCE" value = {data.INSURANCE_AMOUNT}/>         
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <DependentEdit educationOptions = {props.ad.EDUCATION} relationOptions = {props.ad.RELATION_CODE} options = {props.ad.GENDER} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Dependent