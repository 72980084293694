import {useState, useEffect} from 'react';
import { Tabs } from 'antd';
import { useContext  } from 'react';
import DataContext from '../../Context/dataContext';
import TransferList from './TransferList';
import SummaryGroupings from './SummaryGroupings';
import MatrixGroupings from './MatrixGroupings';
import ColumnsToTotal from './ColumnsToTotal';
import Sortings from './Sortings';
import classes from './ReportRepresentation.module.css'

const { TabPane } = Tabs;

const ReportRepresentation = (props) => {

    const mainData = useContext(DataContext)

    const [data, setData] = useState([])

    useEffect(() => {

        let sortInfo = []
        mainData.parentReportData.report_Tsort.forEach((ss, index) => {
            let vals = ss.split(" ")
            sortInfo.push(
                {
                    key: (index + 1).toString(),
                    col: vals[0].toUpperCase(),
                    ascending: vals[1] === "ASC" ? true : false,
                    descending: vals[1] === "DESC" ? true : false,
                }
            )
        })

        mainData.setSortInfo(sortInfo);

        let summaryInfo = []
        mainData.parentReportData.report_Ssort.forEach((ss, index) => {
            let vals = ss.split(" ")
            summaryInfo.push(
                {
                    key: (index + 1).toString(),
                    col: vals[0].toUpperCase(),
                    ascending: vals[1] === "ASC" ? true : false,
                    descending: vals[1] === "DESC" ? true : false,
                }
            )
        })

        mainData.setSummaryInfo(summaryInfo);

    }, [])

    useEffect(() => {
        const data = props.parentColumns.map((column,index) => {return(
            {
                key: index,
                title: column,
                description: `Description for ${column}`
            }
        );})
        
        setData(data)
    }, [props.parentColumns]);

    const tabClickHandler = (key, event) => {
        if(key === "1") mainData.setAKey("3")
        if(key === "2") mainData.setAKey("4")
        if(key === "3") mainData.setAKey("5")
    }

    return(
        
        <Tabs onTabClick = {(key, event) => tabClickHandler(key,event)} defaultActiveKey="1" style = {{ height: "100%", color: "grey",}}>
            {
                mainData.reportType === "tabular"
                ?
                <TabPane tab= "Columns" key="1" >
                    <TransferList 
                        data = {data} 
                        aKey = {mainData.aKey} setAKey = {mainData.setAKey}
                        targetKeys = {mainData.targetKeys} 
                        setTargetKeys = {mainData.setTargetKeys}
                    />
                </TabPane>
                :
                null
            }
            {
                mainData.reportType === "summary"
                ?
                <TabPane tab= "Columns" key="1" >
                    <TransferList 
                        data = {data} 
                        aKey = {mainData.aKey} setAKey = {mainData.setAKey}
                        targetKeys = {mainData.targetKeys} 
                        setTargetKeys = {mainData.setTargetKeys}
                     />
                </TabPane>
                :
                null
            }

            {
                mainData.reportType === "summary"
                ?
                <TabPane tab= {<span className = {mainData.aKey === "3" && classes['Pane']} >Groupings</span>} key="2" >
                    <SummaryGroupings  aKey = {mainData.aKey} setAKey = {mainData.setAKey} sortStrings = {mainData.parentReportData.report_Ssort} parentColumns = {props.parentColumns} targetKeys = {mainData.targetKeys} columns = {props.columns} summaryInfo = {mainData.summaryInfo} setSummaryInfo = {mainData.setSummaryInfo} />
                </TabPane>
                :
                null
            }

            {
                mainData.reportType === "matrix"
                ?
                <TabPane tab= {<span>Groupings</span>} key="1" >
                    <MatrixGroupings parentReportData = {mainData.parentReportData} matrixColumns = {props.matrixColumns} matrixDataColumns = {props.matrixDataColumns} aKey = {mainData.aKey} setAKey = {mainData.setAKey} metaData = {props.metaData} parentColumns = {props.parentColumns} targetKeys = {mainData.targetKeys} columns = {props.columns} matrixSummaryInfo = {mainData.matrixSummaryInfo} setMatrixSummaryInfo = {mainData.setMatrixSummaryInfo} />
                </TabPane>
                :
                null
            }

            {
                mainData.reportType === "tabular"
                ?
                <TabPane  tab= {<span className = {mainData.aKey === "3" && classes['Pane']} >Sortings</span>} key="2">
                    <Sortings  aKey = {mainData.aKey} setAKey = {mainData.setAKey} sortStrings = {mainData.parentReportData.report_Tsort} parentColumns = {props.parentColumns} targetKeys = {mainData.targetKeys} columns = {props.columns} sortInfo = {mainData.sortInfo} setSortInfo = {mainData.setSortInfo} />
                </TabPane>
                :
                null
            }

            {
                mainData.reportType !== "matrix"
                ?
                <TabPane  tab= {<span className = {mainData.aKey === "4" && classes['Pane']} >Columns To Total</span>} key="3">
                    <ColumnsToTotal toTotalStrings = {mainData.parentReportData.report_colToTotal} aKey = {mainData.aKey} setAKey = {mainData.setAKey} metaData = {props.metaData} parentColumns = {props.parentColumns} targetKeys = {mainData.targetKeys} columns = {props.columns} setColumnsToTotal = {mainData.setColumnsToTotal}/>
                </TabPane>
                :
                null
            }
        </Tabs>
    );
}

export default ReportRepresentation