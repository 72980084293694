import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const BasicDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                employeeMaster : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = val
            return({
                ...data,
                employeeMaster : newdata
            })
        })
    }


    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                { !props.create && <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "EMPLOYEE CODE" param = "EMPLOYEE_CODE" value = {props.data.EMPLOYEE_CODE}/> }
                <DataField editMode = {props.editMode} lg = {props.create ? 24 : 12} md = {24} handleChange = {handleChange} name = "FIRST NAME" param = "EMPLOYEE_NAME" value = {props.data.EMPLOYEE_NAME}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "MIDDLE NAME" param = "EMPLOYEE_MNAME" value = {props.data.EMPLOYEE_MNAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "LAST NAME" param = "EMPLOYEE_LNAME" value = {props.data.EMPLOYEE_LNAME}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "COMPANY EMAIL" param = "EMAIL" value = {props.data.EMAIL}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.UNIT_CODE} type = "Select" name = "SITE" param = "UNIT_CODE" value = {props.data.UNIT_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.EMPLOYEE_DESIG_CODE} type = "Select" name = "DESIGNATION" param = "EMPLOYEE_DESIG_CODE" value = {props.data.EMPLOYEE_DESIG_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "AADHAR NO" param = "ADHAR_NO" value = {props.data.ADHAR_NO}/>          
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.EMPLOYEE_DEPT_CODE} type = "Select" name = "DEPARTMENT" param = "EMPLOYEE_DEPT_CODE" value = {props.data.EMPLOYEE_DEPT_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HOD" param = "HOD" value = {props.data.HOD}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.EMPLOYEE_GRADE_CODE} type = "Select" name = "GRADE" param = "EMPLOYEE_GRADE_CODE" value = {props.data.EMPLOYEE_GRADE_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "GROUP" param = "EMPLOYEE_CODE" value = {props.data.EMPLOYEE_CODE}/> 
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "EXPERIENCE" param = "EXPERIENCE" value = {props.data.EXPERIENCE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.QUALIFICATIONS} type = "Select" name = "QULAFICATION" param = "QUALIFICATIONS" value = {props.data.QUALIFICATIONS}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CURRENT CTC" param = "CTC" value = {props.data.CTC}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "GROSS SALARY" param = "GROSS_SALARY" value = {props.data.GROSS_SALARY}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.RWS} type = "Select" name = "PF GIVEN or NOT" param = "PF_REQ" value = {props.data.PF_REQ}/>   
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.EMPLOYEE_TYPE_CODE} type = "Select" name = "EMP TYPE" param = "EMPLOYEE_TYPE_CODE" value = {props.data.EMPLOYEE_TYPE_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "SALARY NORM" param = "SALARY_NORM_CODE" value = {props.data.SALARY_NORM_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ACCOUNT CODE" param = "ACC_GROUP_CODE" value = {props.data.ACC_GROUP_CODE}/>        
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.SHIFT} type = "Select" name = "SHIFT" param = "SHIFT" value = {props.data.SHIFT}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.REST_DAY} type = "Select" name = "REST DAY" param = "REST_DAY" value = {props.data.REST_DAY}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CASUAL LEAVE" param = "CASUAL_LV" value = {props.data.CASUAL_LV}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "EARNED LEAVE" param = "EARNED_LV" value = {props.data.EARNED_LV}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "LIMIT" param = "LIMIT" value = {props.data.LIMIT}/>    
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.RWS} type = "Select" name = "OVERTIME" param = "OVERTIME" value = {props.data.OVERTIME}/>    
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TOLERANCE" param = "TOLERANCE" value = {props.data.TOLERANCE}/>    
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "VALIDITY DAYS" param = "VALIDITY_DAYS" value = {props.data.VALIDITY_DAYS}/>             
            </Row>
            <p></p>
        </div>
    );
}

export default BasicDetails