import {Row, Col, Form, Input, Space, Button, DatePicker, Select} from 'antd';
import classes from '../Pages.module.css';
import { MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';

const {Option} = Select

const DealerGroupEdit = (props) => {

    const handleValueChanges = (changedValues, allValues) => {
        props.setData(data => {
            return({
                ...data,
                dealerGroup: allValues.dealerGroup
            })
        })
    }

    return(
        <Form layout="vertical" name="filter_form" onValuesChange = {handleValueChanges} autoComplete="off">
            <Form.List name="dealerGroup" initialValue = {props.data}>
                {(fields, { add, remove }) => { 
                    
                    return(
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField}) => (
                        <Space key={key} className = {classes['Space']} align="center">
                            <Row gutter = {16}>
                                <p></p>
                                <Col lg = {12} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'EXTERNAL_ENTITY_GROUP_NAME']}
                                        fieldKey={[fieldKey, 'EXTERNAL_ENTITY_GROUP_NAME']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DEALER GROUP</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Input
                                            style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            bordered = {false}
                                            placeholder="Enter Name"
                                        />
                                    </Form.Item>
                                </Col>
                              
                              
                          
                            
                                <Col lg = {21} md = {0}>
                                    
                                </Col>
                                <Col lg = {1} md = {24}>
                                    <MinusCircleOutlined className = {classes['Remove']} onClick={() => { remove(name) }} />
                                </Col>
                                <Col lg = {1} md = {24}>
                                    <PlusCircleOutlined className = {classes['Add']} onClick={() => {add()}} />
                                </Col>
                                <Col lg = {1} md = {24}>
                                    
                                </Col>
                                <Col lg = {24} md = {24}>
                                    <hr></hr>
                                </Col>
                            </Row>                                                     
                        </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" className = {classes['DashedButton']}onClick={() => {add()}}>
                                Add Dealer
                            </Button>
                        </Form.Item>
                    </>
                )}}
            </Form.List>
        </Form>
    )
}

export default (DealerGroupEdit)