import { Layout } from 'antd';
import './CustomerPortalPage.css';
import classes from './CustomerPortalPageExtra.module.css';
import SiderContentC from '../../components/CustomerPortal/SiderContentC/SiderContentC';
import { Scrollbars } from 'react-custom-scrollbars';
import {useState} from 'react';
import FieldDataC from '../../components/CustomerPortal/FieldDataC/FieldDataC';
import { DataProvider } from '../../Context/dataContext';

const {Content, Sider } = Layout;

const CustomerPortalPage = (props) => {

    const [collapsed, setCollapsed] = useState(true);

    const customerData = {
        URL: props.url,
        analysis: props.analysis
    }

    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };

    return(
        <Layout style={{ minHeight: '100vh', backgroundColor: "transparent"}}>

            <Sider className = {classes['Sider']} collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <SiderContentC url = {props.url} />
            </Sider>
           
            <Layout className = {classes['SiteLayout']}>
                <DataProvider value = {customerData}>
                    <Scrollbars autoWidth autoHeight autoHeightMax={ ( window.innerHeight*1 )}>
                        <Content>
                            <FieldDataC />
                        </Content>
                    </Scrollbars>
                </DataProvider>
            </Layout>
        </Layout>
    );  
}

export default (CustomerPortalPage);