import {Row, Col, Card} from 'antd';
import classes from './Dashboard.module.css';
import {FaHandsHelping, FaFileInvoice, FaReceipt} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import {GiSellCard} from 'react-icons/gi';

const DataCards = (props) => {
    return(
        <Row >
            <Col lg = {{span: 6, offset: 0}} md = {11} xs = {24} className = {classes['Col']}>
                <Card style = {{backgroundColor: "#A4CCE3", opacity: "0.9"}} bordered={false} className = {classes['Card']}>
                    <Row>
                        <Col md = {24} className = {classes['DataCol']}><div className = {classes['Data1']}>{props.data.sauda !== null ? (props.data.sauda.PENDING_SAUDA_QTY === null ? '-' : props.data.sauda.PENDING_SAUDA_QTY) : "-"}</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{fontWeight: "900"}}>Pending Sauda</Col>
                        <Col md = {24} ><div className = {classes['Data1']} style = {{color: "white"}}>{props.data.sauda !== null ? (props.data.sauda.AVG_RATE === null ? '-' : props.data.sauda.AVG_RATE) : "-"}</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{color: "white", fontWeight: "900"}}>Average Rate</Col>
                        <Col md = {24} className = {classes['DataCol']}><Link to = "/customer-portal/sauda"><div className = {classes['Data2']}><FaHandsHelping /></div></Link></Col>
                    </Row>
                    {/* <Row>
                        <Col md = {12} className = {classes['DataCol']}><div className = {classes['Data1']}>12</div></Col>
                        <Col md = {12} ><div className = {classes['Data1']} style = {{color: "#79CBF3"}}>1.3</div></Col>
                    </Row>
                    <Row>
                        <Col md = {12} className = {classes['DataCol']} style = {{fontWeight: "500"}}>Pending</Col>
                        <Col md = {12} style = {{color: "#79CBF3", fontWeight: "500"}}>Avg. Rate</Col>
                    </Row> */}
                </Card>
            </Col>
            <Col lg = {{span: 6, offset: 0}} md = {11} xs = {24} className = {classes['Col']}>
                <Card style = {{backgroundColor: "#AAD9CD", opacity: "0.9"}} bordered={false} className = {classes['Card']}>
                    <Row>
                        <Col md = {24} className = {classes['DataCol']}><div className = {classes['Data1']}>{props.data.salesOrder !== null ? (props.data.salesOrder.PENDING_QTY === null ? '-' : props.data.salesOrder.PENDING_QTY ): "-"}</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{fontWeight: "900"}}>Pending SO Qty</Col>
                        <Col md = {24} ><div className = {classes['Data1']} style = {{color: "white"}}>{props.data.salesOrder !== null ? (props.data.salesOrder.AVG_RATE === null ? '-' : props.data.salesOrder.AVG_RATE) : "-"}</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{color: "white", fontWeight: "900"}}>Average Rate</Col>
                        <Col md = {24} className = {classes['DataCol']}><Link to = "/customer-portal/sales-order"><div className = {classes['Data2']}><GiSellCard/></div></Link></Col>
                    </Row>   
                </Card>
            </Col>
            <Col lg = {{span: 6, offset: 0}} md = {11} xs = {24} className = {classes['Col']}>
                <Card style = {{backgroundColor: "#E7CBA9", opacity: "0.9"}} bordered={false} className = {classes['Card']}>
                    <Row>
                        <Col md = {24} className = {classes['DataCol']}><div className = {classes['Data1']}>$ 255</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{fontWeight: "900"}}>Pending Invoice</Col>
                        <Col md = {24} ><div className = {classes['Data1']} style = {{color: "white"}}>1.3</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{color: "white", fontWeight: "900"}}>Average Days</Col>
                        <Col md = {24} className = {classes['DataCol']}><Link to = "/customer-portal/invoices"><div className = {classes['Data2']}><FaFileInvoice /></div></Link></Col>
                    </Row>
                </Card>
            </Col>
            <Col lg = {{span: 6, offset: 0}} md = {11} xs = {24} className = {classes['Col']}>
                <Card style = {{backgroundColor: "#E9BBB5", opacity: "0.9"}} bordered={false} className = {classes['Card']}>
                    <Row>
                        <Col md = {24} className = {classes['DataCol']}><div className = {classes['Data1']}>{props.data.ledgerBalance !== null ? props.data.ledgerBalance : "-"}</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{fontWeight: "900"}}>Ledger Balance</Col>
                        {/* <Col md = {24} ><div className = {classes['Data1']} style = {{color: "white"}}>1.3</div></Col>
                        <Col md = {24} className = {classes['DataCol']} style = {{color: "white", fontWeight: "900"}}>Average Days</Col> */}
                        <Col md = {24} className = {classes['DataCol']}><Link to = "/customer-portal/ledger"><div className = {classes['Data2']}><FaReceipt /></div></Link></Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default DataCards;