import {Row,Col,Skeleton,  message, Tooltip, Modal, Button} from 'antd'
import axios from 'axios';
import classes from '../Pages.module.css';
import { useState, useEffect, useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import EmployeTable from '../EmployeeTable/EmployeeTable';
import DAForm from './DAForm';
import moment from 'moment';
import {FaPenAlt} from 'react-icons/fa';
import {Link} from 'react-router-dom'
import LeaveUpdateForm from './LeaveUpdateForm';
import SyncLoader from "react-spinners/SyncLoader";

const Leave = (props) => {

    const employeeData = useContext(DataContext)

    const [loading, setLoading] = useState(false)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState(null)
    const [ad, setAD] = useState(null)
    const [form, setForm] = useState({
        ATTEND_CODE: null,
        SITE: 1,
        ATTEND_DATE: moment(new Date()).format("DD/MM/YYYY"),
        DEPARTMENT: null,
        EMP_TYPE: null,
    })
    const [leaveList, setLeaveList] = useState(null);
    const [leaveTypes, setLeaveTypes] = useState(null);
    const [periodList] = useState({
        metaData: [{name: "KEY"},{name: "VALUE"}],
        rows: [
            {KEY: 1, VALUE: "Full Day"},
            {KEY: 0.5, VALUE: "Half Day"},
            {KEY: 0, VALUE: "NA"},
        ]
    })
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [leaveUpdateForm, setLAF] = useState(null)
    const [refIndex, setIndex] = useState(null)

    useEffect(() => {

        setRows(null)
        setColumns([])
        setAD(null)
        setLeaveTypes(null)
        console.log(form);
        axios
            .get(employeeData.URL + '/api/v1/transactions/daily-att-data',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAD(ad => {
                    let newad = response.data.data
                    return ({
                        ...newad,
                        STATUS: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [
                                {KEY: "P", VALUE: "Present"},
                                {KEY: "HP", VALUE: "H Present"},
                                {KEY: "L", VALUE: "Leave"},
                                {KEY: "H", VALUE: "Holiday"},
                                {KEY: "W", VALUE: "Weekly Off"},
                                {KEY: "O", VALUE: "OD"},
                                {KEY: "S", VALUE: "SM"},
                                {KEY: "C", VALUE: "CO"},
                                {KEY: "N", VALUE: "NA"},
                            ]
                        },
                        LEAVE_PERIOD: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [
                                {KEY: 1, VALUE: "Full Day"},
                                {KEY: 0.5, VALUE: "Half Day"},
                                {KEY: 0, VALUE: "NA"},
                            ]
                        }
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            })

        axios
            .get(employeeData.URL + '/api/v1/transactions/leave?site=1&date=' + form.ATTEND_DATE,{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.data.leaves.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "E", title: "E"}, {name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.data.leaves.rows.map((row,index) => {
                                                                                                                                                    
                        return(
                            {
                                "E": <Tooltip placement="bottom" title = "Edit" color = "#1777C4">
                                        <Link  to = "#" style = {{color: "#1777C4", fontWeight: "bolder"}} onClick = {(event) => editHandler(event,index,response.data.data.leaves.rows,row['EMP_CODE'])}>
                                            <FaPenAlt  style = {{color: "#1777C4", fontWeight: "bolder"}}/>
                                        </Link>
                                     </Tooltip>,
                                "SNO": index + 1,
                                ...row,
                            }
                        );})
                    return newRows;
                })

                setLeaveTypes(leaveTypes => {
                    let newTypes = response.data.data.leaveTpyes                                                                                                              
                    return newTypes;
                })
            })
            .catch((error) => {
                console.log(error);
            })
        
    }, [])

    const editHandler = (event,index,rows,code) => {
        
        setIndex(index)
        setLAF(null)
        setLeaveList(null)
        axios
            .get(employeeData.URL + '/api/v1/transactions/leave-lov?empCode='+ code.toString(),{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);
                setLAF(rows[index])
                setLeaveList(response.data.data.lov);
                setVisible(true)
            })
            .catch((error) => {
                console.log(error);
            })
        
    }

    const handleSave = (event) => {

        const postData = {
            ATTEND_CODE: form.ATTEND_CODE ? form.ATTEND_CODE : '',
            SITE: form.SITE,
            ATTEND_DATE: form.ATTEND_DATE,
            DEPARTMENT: form.DEPARTMENT ? form.DEPARTMENT : '',
            EMP_TYPE: form.EMP_TYPE ? form.EMP_TYPE : '',
        }

        console.log(postData);
        setRows(null)
        setLeaveTypes(null)
        console.log(form);
        axios
            .get(employeeData.URL + '/api/v1/transactions/leave?site='+ postData.SITE.toString() +'&date=' + postData.ATTEND_DATE + 
                                    '&empType=' + postData.EMP_TYPE.toString() + '&code='+ postData.ATTEND_CODE.toString() +'&dept=' + postData.DEPARTMENT.toString(),{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.data.leaves.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "E", title: "E"}, {name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.data.leaves.rows.map((row,index) => {
                                                                                                                                                    
                        return(
                            {
                                "E": <Tooltip placement="bottom" title = "Edit" color = "#1777C4">
                                        <Link  to = "#" style = {{color: "#1777C4", fontWeight: "bolder"}} onClick = {(event) => editHandler(event,index,response.data.data.leaves.rows,row['EMP_CODE'])}>
                                            <FaPenAlt  style = {{color: "#1777C4", fontWeight: "bolder"}}/>
                                        </Link>
                                     </Tooltip>,
                                "SNO": index + 1,
                                ...row,
                            }
                        );})
                    return newRows;
                })

                setLeaveTypes(leaveTypes => {
                    let newTypes = response.data.data.leaveTpyes                                                                                                              
                    return newTypes;
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleOk = () => {
        setConfirmLoading(true);
        console.log(JSON.stringify(leaveUpdateForm, undefined, 2));

        const postData = {
            ATTEND_CODE: form.ATTEND_CODE ? form.ATTEND_CODE : '',
            SITE: form.SITE,
            ATTEND_DATE: form.ATTEND_DATE,
            DEPARTMENT: form.DEPARTMENT ? form.DEPARTMENT : '',
            EMP_TYPE: form.EMP_TYPE ? form.EMP_TYPE : '',
        }

        let actual = [...rows]
        setRows(null)
        axios
        .post(employeeData.URL + '/api/v1/transactions/leave?site='+ postData.SITE.toString() +'&date=' + postData.ATTEND_DATE, leaveUpdateForm,  {
            withCredentials: true,
            credentials: 'include',
        })
        .then((response) => {

            actual[refIndex] = {leaveUpdateForm}
            actual[refIndex] = {
                ...leaveUpdateForm,
                "E": <Tooltip placement="bottom" title = "Edit" color = "#1777C4">
                        <Link  to = "#" style = {{color: "#1777C4", fontWeight: "bolder"}} onClick = {(event) => editHandler(event,refIndex, actual ,leaveUpdateForm['EMP_CODE'])}>
                            <FaPenAlt  style = {{color: "#1777C4", fontWeight: "bolder"}}/>
                        </Link>
                    </Tooltip>,
                "SNO": refIndex + 1,
            };
            setRows(actual)
            setConfirmLoading(false)
            setVisible(false)
        })
        .catch((err) => {
            setRows(actual)
            setConfirmLoading(false)
            setVisible(false)
            console.log(err);
        });
        
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        
    };

    return(
        <>
            <Modal
                title= { "Leave Entry for " + (leaveUpdateForm ? leaveUpdateForm.EMPLOYEE_NAME : "")}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
                centered = {true}
                width= "70vw"
                bodyStyle = {{height: "maxContent"}}
            >
              <LeaveUpdateForm form = {leaveUpdateForm} setForm = {setLAF} periodList = {periodList} leaveList = {leaveList}/>      
            </Modal>
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Leave Entry</p></Col>
                <Col className = {classes['Col']} md = {10}>
                  
                </Col>
                <Col className = {classes['Col']} md = {24}>
                  
                </Col>
            </Row>
            {
                form && ad
                ?
                <DAForm ad = {ad} form = {form} setForm = {setForm} handleSave = {handleSave}/>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
            <hr></hr>
            <p></p>
            {
                ad && leaveTypes && periodList && rows && columns.length > 0
                ?
                <EmployeTable data = {rows} columns = {columns} leaveTypes = {leaveTypes} periodList = {periodList}/>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default Leave