import { Col, Row, Dropdown, Tooltip, message } from 'antd';
import classes from './TitleRow.module.css';
import { useState } from 'react';
import {useContext} from 'react';
import DataContext from '../../Context/dataContext';
import { DownOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import {LeftOutlined} from '@ant-design/icons';
import axios from 'axios';
import PacmanLoader from "react-spinners/PacmanLoader"; 

const TitleRow = (props) => {

    const mainData = useContext(DataContext)

    const [Chovered, setCHovered] = useState(false);
    const [Shovered, setSHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const onEnterHandler = (event, id) => {
        if (id === "C") setCHovered(true)

        if (id === "S") setSHovered(true)
    };

    const onLeaveHandler = (event, id) => {
        if (id === "C") setCHovered(false)

        if (id === "S") setSHovered(false)
    };

    const onSaveHandler = (event) => {

        let SORTING_BY = ""
        let GROUPING_BY = ""
        let COL_TOTAL = ""

        setLoading(true);

        let REPORT_FIELDS = "";
        mainData.targetKeys.forEach((target) => {
            REPORT_FIELDS = REPORT_FIELDS + mainData.tableColumns[target] + ", "
        })
        REPORT_FIELDS = REPORT_FIELDS.slice(0,-2)

        if(mainData.columnsToTotal){
            mainData.columnsToTotal.forEach((col) => {
            
                if(col.sum) COL_TOTAL = COL_TOTAL + col.col + " SUM, "
                if(col.average) COL_TOTAL = COL_TOTAL + col.col + " AVG, "
                if(col.lowest_value) COL_TOTAL = COL_TOTAL + col.col + " MIN, "
                if(col.largest_value) COL_TOTAL = COL_TOTAL + col.col + " MAX, "
            })
            COL_TOTAL = COL_TOTAL.slice(0, -2)
        }
        

        if(mainData.reportType.toLowerCase() === "tabular"){

            mainData.sortInfo.forEach((info) => {
                SORTING_BY = SORTING_BY + info.col + " ";
                if(info.ascending) SORTING_BY = SORTING_BY + "ASC, "
                if(info.descending) SORTING_BY = SORTING_BY + "DESC, "
            })
            SORTING_BY = SORTING_BY.slice(0,-2)
        }
        
        else if(mainData.reportType.toLowerCase() === "summary"){

            mainData.summaryInfo.forEach((info) => {
                SORTING_BY = SORTING_BY + info.col + " ";
                GROUPING_BY = GROUPING_BY + info.col + ", ";
                if(info.ascending) SORTING_BY = SORTING_BY + "ASC, "
                if(info.descending) SORTING_BY = SORTING_BY + "DESC, "
            })
            SORTING_BY = SORTING_BY.slice(0,-2)
            GROUPING_BY = GROUPING_BY.slice(0,-2)
        }

        else if(mainData.reportType.toLowerCase() === "matrix"){

            mainData.matrixSummaryInfo.forEach((info, index) => {
                if(index <= 1){
                    SORTING_BY = SORTING_BY + info.col + " ";
                    if(info.ascending) SORTING_BY = SORTING_BY + "ASC, "
                    if(info.descending) SORTING_BY = SORTING_BY + "DESC, "
                }
            })
            SORTING_BY = SORTING_BY.slice(0,-2)
        }

        let FILTER_BY = ""
        if(mainData.filterInfo){
            
            mainData.filterInfo.filters.forEach((info) => {
                FILTER_BY = FILTER_BY + info.columnName + " "
                if(info.filterParam === "BETWEEN"){
                    FILTER_BY = FILTER_BY + "BETWEEN " + info.filterValue.toString() + " AND " + info.filterValue1.toString() + " AND "
                }
                else{
                    if(typeof info.filterValue !== 'number')
                    FILTER_BY = FILTER_BY  + info.filterParam + " '" + info.filterValue.toUpperCase() + "' AND "

                    else
                    FILTER_BY = FILTER_BY  + info.filterParam + " " + info.filterValue + " AND "
                }
            })
            FILTER_BY = FILTER_BY.slice(0,-5)
        }

        if(mainData.reportType.toLowerCase() === "matrix" && mainData.matrixSummaryInfo.length !== 3){
            
            if(mainData.matrixSummaryInfo.length === 0) {
                message.error({
                    content: 'Column Headings Not Specified in Report Representataion!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }

            else if(mainData.matrixSummaryInfo.length === 1) {
                message.error({
                    content: 'Row Headings Not Specified in Report Representataion!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }

            else if(mainData.matrixSummaryInfo.length === 2) {
                message.error({
                    content: 'Data Field Not Specified in Report Representataion!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }

            setLoading(false);
        }

        else if(mainData.reportType.toLowerCase() === "summary" && mainData.summaryInfo.length === 0){
            

            message.error({
                content: 'Summary Groupings Not Specified in Report Representataion!!!!',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });

            setLoading(false);

        }

        else{

            if(mainData.createNew){
                const postData = {
                    parentReportCode: mainData.parentReportData.report_code,
                    reportName: mainData.createNew ? mainData.viewName : mainData.parentReportData.report_name,
                    description: mainData.createNew ? mainData.viewDesc : mainData.parentReportData.report_desc,
                    reportFields: REPORT_FIELDS,
                    category: mainData.module,
                    format: mainData.reportType,
                    sort: mainData.reportType.toLowerCase() === "tabular"? SORTING_BY : "",
                    summarySortBy: mainData.reportType.toLowerCase() === "summary"? SORTING_BY : "",
                    filter: FILTER_BY,
                    groupBy: GROUPING_BY,
                    matX: mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[0].col : null,
                    matY: mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[1].col : null,
                    matData: mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[2].col : null,
                    matAction: mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[2].function : null,
                    colsToTotal: mainData.reportType.toLowerCase() === "matrix"? "" : COL_TOTAL
                }
        
                console.log(JSON.stringify(postData))
                
                axios
                    .post(mainData.URL + '/api/v1/reports', postData,  {
                        withCredentials: true,
                        credentials: 'include',
                    })
                    .then((response) => {
                        setLoading(false)
                        message.success({
                            content: 'View Created Successfully!!!!',
                            className: 'custom-class',
                            style: {
                                marginTop: '20vh',
                            },
                        });
        
                        window.location.replace('/reports')
                        mainData.setTargetKeys([]);
                        mainData.setSortInfo([]);
                        mainData.setReportType("");
                        mainData.setEditMode(false);
                        mainData.setAKey("1");
                
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.log(err.response);
                    });
            }  
        
            else
            {
                const postData = {
                    reportCode: mainData.parentReportData.report_code,
                    reportName: mainData.createNew ? mainData.viewName : mainData.parentReportData.report_name,
                    description: mainData.createNew ? mainData.viewDesc : mainData.parentReportData.report_desc,
                    reportFields: REPORT_FIELDS,
                    category: mainData.module,
                    format: mainData.reportType,
                    sort: mainData.reportType.toLowerCase() === "tabular"? SORTING_BY : "",
                    summarySortBy: mainData.reportType.toLowerCase() === "summary"? SORTING_BY : "",
                    filter: FILTER_BY,
                    groupBy: GROUPING_BY,
                    matX:  mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[0].col : null,
                    matY:  mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[1].col : null,
                    matData:  mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[2].col : null,
                    matAction:  mainData.reportType.toLowerCase() === "matrix"? mainData.matrixSummaryInfo[2].function : null,
                    colsToTotal: mainData.reportType.toLowerCase() === "matrix"? "" : COL_TOTAL
                }
    
                console.log(JSON.stringify(postData));
            
                axios
                    .patch(mainData.URL + '/api/v1/reports', postData,  {
                        withCredentials: true,
                        credentials: 'include',
                    })
                    .then((response) => {
                        setLoading(false)
                        message.success({
                            content: 'View Created Successfully!!!!',
                            className: 'custom-class',
                            style: {
                                marginTop: '20vh',
                            },
                        });
        
                        window.location.replace('/reports')
                        mainData.setTargetKeys([]);
                        mainData.setSortInfo([]);
                        mainData.setReportType("");
                        mainData.setEditMode(false);
                        mainData.setAKey("1");
                
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.log(err);
                    });
            }       
        }
        
    }
    return(
        <>
            <div className={classes['SiteLayoutBackground']}>
                <Row gutter={16} className={classes['SiteLayoutBackground']}>
                    <Col lg = {props.editMode? 14 : 12} md = {props.editMode? 14 : 10} xs={{span : 24}} className={classes['ColX']}>
                        <card className={classes['CardNew']}>
                            <strong>{props.title}</strong>
                        </card>
                    </Col>
                    <Col lg = {4} md = {3} xs={{span : 24}} className={classes['Col']}>
                        {
                            loading
                            ?
                            null
                            :
                            <card  className={classes['Card']}  style = {{backgroundColor : "transparent", color:"#355576"}}>
                                    {/* <strong style = {{ color: "#355576" }}>{props.module}</strong> */}
                            </card>
                        }
                    </Col>
                    {
                        props.editMode
                        ?
                        <Col md = {2} xs={{span : 24}} className={classes['Col']}>
                            {
                                loading
                                ?
                                // <PacmanLoader color = {"#1777C4"} size = {20}/>
                                null
                                :
                                <Tooltip placement="bottom" title="Back" color="#black" key="back"> 
                                    <button onClick = {props.editModeHandler} className = {classes['StyledButtonBack']} style = {{color: "white", fontWeight: "bold"}}><LeftOutlined className = {classes['StyledIcon']} /></button>
                                </Tooltip>
                            }
                        </Col>
                        :
                        <Col onMouseEnter = {(event) => onEnterHandler(event,"C")} onMouseLeave = {(event) => onLeaveHandler(event,"C")} lg = {5} md = {8} xs={{span : 24}} className={classes['Col']}>
                            {
                                mainData.parentReportData.report_cns === 0
                                ?
                                <card  className={classes['Card']}  style = {{color : "#1777C4", backgroundColor:"transparent",  whiteSpace: "nowrap"}}>
                                        <strong className={classes['Company']}>{props.company? props.company.name : "company"}</strong>
                                </card>
                                :
                                <>
                                    {   
                                        props.companyList.length > 0 && props.companyMenu
                                        ?
                                        <Dropdown overlay={props.companyMenu} placement="bottomCenter"> 
                                            <card  className={classes['Card']}  style = {{color : "#1777C4", backgroundColor:"transparent", whiteSpace: "nowrap"}}>
                                                    {Chovered ? "Switch Company" : <strong className={classes['Company']}>{props.company? props.company.name : "company"}<DownOutlined /></strong>}
                                            </card>
                                        </Dropdown>
                                        :
                                        <card  className={classes['Card']}  style = {{color : "#1777C4", backgroundColor:"transparent",  whiteSpace: "nowrap"}}>
                                                {Chovered ? "Switch Company" : <strong className={classes['Company']}>{props.company? props.company.name : "company"}<DownOutlined /></strong>}
                                        </card>
                                    }
                                </>
                            }
                        </Col>
                    }
                    {
                        props.editMode
                        ?
                        <Col md = {4} xs={{span : 24}} className={classes['Col']}>
                            {
                                loading
                                ?
                                <PacmanLoader color = {"#1777C4"} size = {20}/>
                                :
                                <Link to = "#" style = {{pointerEvents: (mainData.createNew && mainData.viewName === "") && "none", cursor: (mainData.createNew && mainData.viewName === "") && "default"}}>
                                    <card  onClick = {(event) => onSaveHandler(event)} className={mainData.createNew && mainData.viewName === "" ? classes['CardDisabled'] : classes['CardX']}>
                                            <strong style = {{ color: "white" }}>SAVE</strong>
                                    </card>
                                </Link>
                            }                            
                        </Col>
                        :
                        <Col onMouseEnter = {(event) => onEnterHandler(event,"S")} onMouseLeave = {(event) => onLeaveHandler(event,"S")} md = {3} xs={{span : 0}} className={classes['Col']}>
                            {
                                mainData.parentReportData.report_cns === 0
                                ?
                                <card className={classes['Card']} >
                                    <strong>{props.site.name}</strong>
                                </card>
                                :
                                <> 
                                    {
                                        props.companyList.length > 0 && props.siteMenu
                                        ?
                                        <Dropdown overlay={props.siteMenu} placement="bottomCenter"> 
                                            <card className={classes['Card']} >
                                                {Shovered ? "Switch Site" : <strong>{props.site.name}<DownOutlined /> </strong>}
                                            </card>
                                        </Dropdown>
                                        :
                                        <card className={classes['Card']} >
                                            {Shovered ? "Switch Site" : <strong>{props.site.name}<DownOutlined /> </strong>}
                                        </card>
                                    }
                                </>
                            }
                        </Col>
                    }
                </Row>
            </div>
        </>
    );
}

export default TitleRow