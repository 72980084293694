import classes from './SiderContentC.module.css'
import { Menu, message } from 'antd';
import { FaSignOutAlt, FaHandsHelping, FaFileInvoice, FaMoneyCheckAlt, FaBusinessTime, FaReceipt} from "react-icons/fa"
import signInSignUpLogo from '../../../assets/signInSignUp.png'
import {MdDashboard, MdPersonPin} from 'react-icons/md';
import { AiOutlineDownSquare } from "react-icons/ai";
import { NavLink } from 'react-router-dom';
import {GiSellCard} from 'react-icons/gi';
import axios from "axios";
import { useHistory } from 'react-router';

const { SubMenu } = Menu;

const SiderContentC = (props) => {
    
    const history = useHistory()

    const handleLogout = (event) => {

        axios
        .get(props.url + '/api/v1/users/logout',{
            withCredentials: true
        })
        .then((response) => {
            message.success({
                content: 'Goodbye!!!! See You Again!!!',
                className: 'custom-class',
                style: {
                marginTop: '20vh',
                },
            });
            history.replace('/customer')
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <>  
            <img className={classes['SignInSignUpLogo']} src={signInSignUpLogo} alt="Logo"/>
            <Menu className = {classes['Menu']}>
                <Menu.Item key = 'custDash'icon={<MdDashboard />}>   
                    <NavLink to = '/customer-portal'>
                        Dashboard
                    </NavLink>
                </Menu.Item>
                <Menu.Item key = 'custSauda'icon={<FaHandsHelping />}>   
                    <NavLink to = '/customer-portal/sauda'>
                        Sauda
                    </NavLink>
                </Menu.Item>
                <Menu.Item key = 'custSales' icon={<GiSellCard />}>   
                    <NavLink to = '/customer-portal/sales-order'>
                        Sales Order
                    </NavLink>
                </Menu.Item>
                <Menu.Item key = 'custReturn' icon={<AiOutlineDownSquare />}>   
                    <NavLink to = '/customer-portal/sales-return'>
                        Sales Return
                    </NavLink>
                </Menu.Item>
                <Menu.Item key = 'custInvoices' icon={<FaFileInvoice />}>   
                    <NavLink to = '/customer-portal/invoices'>
                        Invoices
                    </NavLink>
                </Menu.Item>
                <Menu.Item key = 'custPay' icon={<FaMoneyCheckAlt />}>   
                    <NavLink to = '/customer-portal/payments'>
                        Payments
                    </NavLink>
                </Menu.Item>
                <SubMenu key="custOut" title="Outstanding" icon = {<FaBusinessTime />}>
                    <Menu.Item key="outNetDue" >
                        <NavLink to = '/customer-portal/outstanding/net-due'>
                            Net Due
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="outAge" >
                        <NavLink to = '/customer-portal/outstanding/ageing-analysis'>
                            Ageing Analysis
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item icon={<FaReceipt />}>   
                    <NavLink to = '/customer-portal/ledger'>
                        Ledger
                    </NavLink>
                </Menu.Item>
                <Menu.Item icon={<MdPersonPin />}>   
                    <NavLink to = '/customer-portal/profile'>
                        Profile
                    </NavLink>
                </Menu.Item>
                <Menu.Item onClick = {(event) => handleLogout(event)} icon={<FaSignOutAlt style = {{color : "red", opacity: "0.7"}}/>}>   
                    <NavLink to = '/customer-portal'>
                        Logout
                    </NavLink>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default SiderContentC