import {Row, Col, Card} from 'antd';
import classes from './Dashboard.module.css';
import DataCards from './DataCards';
import ProfileCard from './ProfileCard';
import {useEffect, useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import Charts from './Charts'
const Dashboard = (props) => {

    const customerData = useContext(DataContext)

    return(
        <>
            {/* <p className={classes['PGreet']}>Good Evening, <strong>Chandan</strong></p> */}
            <Row className = {classes['SiteLayoutBackground']}>
                <Col xl = {18} lg = {19} md = {24}>
                    <DataCards data = {customerData.analysis}/>
                    <Row>
                        <Col lg = {{span: 24, offset: 0}} md = {24} xs = {24} className = {classes['ColC']}>
                            <Card className = {classes['Card']}>
                                <Charts chartType = "line" data = {customerData.analysis.graphData.rows} pieData = {customerData.analysis.pieData.rows} />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xl = {{span: 6, offset: 0}} lg = {{span: 5, offset: 0}} md = {24} className = {classes['ColX']}>  
                    <ProfileCard data = {customerData.analysis} />
                </Col>
                <Col lg = {{span: 24, offset: 0}} md = {24} xs = {24} className = {classes['ColC']}>
                    <Card className = {classes['Card']}>
                        <Charts chartType = "pie" data = {customerData.analysis.graphData.rows} pieData = {customerData.analysis.pieData.rows} />
                    </Card>
                </Col>
            </Row>
            <p></p>
        </>
    );
}

export default Dashboard