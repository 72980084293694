import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const BasicDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['customerMaster']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                customerMaster : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['customerMaster']]
            newdata[0][param] = val
            return({
                ...data,
                customerMaster : newdata
            })
        })
    }
    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['customerMaster']]
            newdata[0][param] = dateString
            return({
                ...data,
                customerMaster : newdata
            })
        })
    }



    return(
        
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                { !props.create && <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CUSTOMER CODE" param = "DISTRIBUTOR_CODE" value = {props.data.DISTRIBUTOR_CODE}/> }
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CUSTOMER NAME" param = "DISTRIBUTOR_NAME" value = {props.data.DISTRIBUTOR_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ACC_GROUP_CODE} type = "Select" name = "DEBITOR GROUP" param = "ACC_GROUP_CODE" value = {props.data.ACC_GROUP_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.EXT_ENTITY_TYPE_CODE} type = "Select" name = "CUSTOMER TYPE" param = "EXT_ENTITY_TYPE_CODE" value = {props.data.EXT_ENTITY_TYPE_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ADDRESS" param = "ADDRESS" value = {props.data.ADDRESS}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.CITY} type = "Select" name = "CITY" param = "CITY" value = {props.data.CITY}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.LOCALITY_CODE} type = "Select" name = "LOCALITY" param = "LOCALITY_CODE" value = {props.data.LOCALITY_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HSN NO" param = "HSN" value = {props.data.HSN}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAN NO" param = "PAN_NO" value = {props.data.PAN_NO}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "GST NO" param = "S_TAX_NO" value = {props.data.S_TAX_NO}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT DAYS" param = "PAYMENT_DAYS" value = {props.data.PAYMENT_DAYS}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "INTEREST RATE(MO)" param = "MONTHLY_INTREST" value = {props.data.MONTHLY_INTREST}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "INTEREST RATE(YEAR)" param = "YEARLY_INTREST" value = {props.data.YEARLY_INTREST}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ACCOUNT CODE" param = "ACCOUNT_CODE" value = {props.data.ACCOUNT_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ECC NO" param = "ECC_NO" value = {props.data.ECC_NO}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PIN" param = "PIN_NO" value = {props.data.PIN_NO} numberVal={props.onlyNumber}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CST NUMBER" param = "CST_NO" value = {props.data.CST_NO}/> 
               
            </Row>
            
            
        </div>
    );
}

export default BasicDetails