import classes from '../Pages.module.css';
import {Row,Col, Tabs, Skeleton, message} from 'antd';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useState, useEffect, useContext} from 'react';
import BasicDetails from './BasicDetails'
import VendorDetails from './VendorDetails';
import DealerGroup from './DealerGroup'
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory } from 'react-router';

const { TabPane } = Tabs;

const identifiers = ['customerDetails', 'dealerGroup'];

const DealerNew = (props) => {

    const employeeData = useContext(DataContext)
    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(true)
    const [allData, setAllData] = useState({
        "dealerMaster": [
            {
                "EXTERNAL_ENTITY_CODE":null,
                "EXTERNAL_ENTITY_NAME":null,
                "EXT_ENTITY_TYPE_CODE":null,
                "EXTERNAL_ENTITY_GROUP_CODE":null,
                "ADDRESS": null,
                "CITY": null,
                "LOCALITY_CODE": null,
                "PAN_NO": null,
                "INT_PER": null,
                "CST_NO": null,
                "PIN_NO": null
             
            }
        ],
        
        "customerDetails": [],
        "dealerGroup": [],
       
    })
    const [ad, setAD] = useState(null)

    useEffect(() => {
        
        setEditMode(true)
        setAD(null)
        setLoading(false)
        axios
            .get(employeeData.URL + '/api/v1/dealer/additional-data',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAD(ad => {
                    let newad = response.data.data
                    return ({
                        ...newad,
                       
                       
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            })
        
    }, [])

    const handleSave = (event) => {

        var formv = true;

        identifiers.forEach(val => {
            let count = 0;
            allData[val].forEach(data => {
                
                if(!data){

                    formv = formv&&false
                    if(count === 0){
                        message.error({
                            content: 'Empty Fields In ' + val.toUpperCase() + ' Tab!!!',
                            className: 'custom-class',
                            style: {
                                marginTop: '1vh',
                            },
                        });
                    }

                    count = count + 1;
                }
            })
        })

        if(formv){
            setLoading(true)
            const postData = {
                ...allData,
                customerDetails: allData.customerDetails.map(dep => {
                                return(
                                    {
                                        ...dep,
            
                                    }
                                )
                            }),
                dealerGroup: allData.dealerGroup.map(dep => {
                                return(
                                    {
                                        ...dep,
                                      
                                    }
                                )
                            }),

              
              
            }

            console.log(postData);

            axios
                .post(employeeData.URL + '/api/v1/dealer', postData,  {
                    withCredentials: true,
                    credentials: 'include',
                })
                .then((response) => {
                    message.success({
                        content: 'dealer master Created Successfully!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '2vh',
                        },
                    });
                    setLoading(false)
                    history.replace('/sales/dealerMaster/dealer-details')
                })
                .catch((err) => {
                    message.error({
                        content: 'An Error Occurred!!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '2vh',
                        },
                    });
                    setLoading(false)
                });
        }
        
    }

    const handleCancel = (event) => {

        setLoading(false)
        history.replace('/sales/dealerMaster/dealer-details')
    }

    return(
        <>
            {
                ad
                ?
                <Row className = {classes['RowP']}>
                    <Col lg = {editMode ? 13 : 19} md = {editMode ? 13 : 19} className = {classes['Col']} >

                    </Col>
                    {
                        editMode
                        ?
                        <>
                            <Col md = {4} >
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{ backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                                }
                            </Col>
                            <Col md = {4} >
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancel(event)} className = {classes['ProfileButton']}>Cancel</button>
                                }
                                
                            </Col>
                        </>
                        :
                        <Col md = {4} >
                            <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Details</button>
                        </Col>
                    }
                    <Col lg = {1} md = {1} >

                    </Col>
                    <Col lg = {24} md = {24} className = {classes['Col']}>
                        <div className = {classes['EditView']}>
                            <Tabs defaultActiveKey="0" centered style = {{ height: "100%"}} tabPosition={"left"} type = "line" tabBarGutter = "0">
                                <TabPane tab= {<span>Basic Details</span>} key="0">
                                    <BasicDetails create = {true} ad = {ad} setData = {setAllData} data = {allData.dealerMaster[0]} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Vendor Details</span>} key="1">
                                    <VendorDetails  ad = {ad} setData = {setAllData} data = {allData.customerDetails} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Delivery Details</span>} key="2">
                                    <DealerGroup  ad = {ad} setData = {setAllData} data = {allData.dealerGroup} editMode = {editMode} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                
                </Row>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
           
        </>
    );
}

export default DealerNew