import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import LanguageEdit from './LanguageEdit';

const Language = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            if(data.PARAM !== "DELETE")
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {6} md = {24} options = {props.ad.LANGUAGE_CODE} type = "Select" name = "LANGUAGE NAME" value = {data.LANGUAGE_CODE}/> 
                        <DataField editMode = {false} lg = {6} md = {24} options = {props.ad.RWS} type = "Select" name = "READ" value = {data.READ}/> 
                        <DataField editMode = {false} lg = {6} md = {24} options = {props.ad.RWS} type = "Select" name = "WRITE" value = {data.WRITE}/> 
                        <DataField editMode = {false} lg = {6} md = {24} options = {props.ad.RWS} type = "Select" name = "SPEAK" value = {data.SPEAK}/>          
                    </Row>  
                    <p></p>
                </div>
            );
            else
            return null
        })
           
        :
        <>
            <p></p>
            <LanguageEdit languageOptions = {props.ad.LANGUAGE_CODE} options = {props.ad.RWS} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Language