import { Col, Row, Tooltip, message, Modal, Form, Input,} from 'antd';
import {Link} from 'react-router-dom';
import {MdSettings} from 'react-icons/md'
import classes from './AllReports.module.css';
import {useState, useContext} from 'react';
import DataContext from '../../Context/dataContext';
import axios from 'axios';
import { saveAs } from 'file-saver'
import { FaSearch, FaFilePdf} from 'react-icons/fa';
import { RiFileExcel2Fill, RiMailSendFill, RiWhatsappFill} from 'react-icons/ri'
import { AiFillDelete, AiFillPrinter } from 'react-icons/ai'
import SyncLoader from "react-spinners/SyncLoader";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Functions = (props) => {

    const mainData = useContext(DataContext);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [wsp, setWsp] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [type, setType] = useState("Excel Sheet");
    const [con, setCon] = useState("in");
    
    const showModal = (event, val) => {
        setType(val)
        setVisible(true);
        setCon("in")
    };

    const printPageExcel = (event) => {
        
        props.setExporting(props.index)
        axios
            .post(mainData.URL + '/api/v1/excel/download/'+ props.code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(), 
            {"filter": ""},
            { responseType: 'arraybuffer'},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {

                const data = response.data;
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, props.name + ".xlsx")
                message.success({
                    content: 'Excel Sheet Generated Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                props.setExporting(props.index)
                
            })
            .catch((err) => {
                console.log(err);
                props.setExporting(props.index)
            });
    }

    const printPagePdf = (event) => {

        props.setExporting(props.index)
        axios
            .post(mainData.URL + '/api/v1/pdf/download/'+ props.code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(),
            {"filter": ""},
            {responseType: 'arraybuffer'},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                const data = response.data;
                const blob = new Blob([data], { type: 'application/pdf' })
                saveAs(blob, props.name + ".pdf")
                message.success({
                    content: 'PDF Generated Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                props.setExporting(props.index)

                
            })
            .catch((err) => {
                console.log(err);
                props.setExporting(props.index)
            });
        

    }

    const mailPDF = (email) => {

        props.setExporting(props.index)
        axios
            .post(mainData.URL + '/api/v1/pdf/email/'+ props.code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(), 
            { "email": email, "filter": ""},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                setIsValid(true)
                setConfirmLoading(false)
                setEmail("")
                setVisible(false)
                message.success({
                    content: 'PDF Mailed Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                props.setExporting(props.index)
                
            })
            .catch((err) => {
                console.log(err);
                props.setExporting(props.index)
            });
    }

    const wPDF = (wsp) => {

        props.setExporting(props.index)
        axios
            .post(mainData.URL + '/api/v1/pdf/whatsapp/'+ props.code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(), 
            { "phone": wsp},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                setIsValid(true)
                setConfirmLoading(false)
                setWsp("")
                setCon("in")
                setVisible(false)
                message.success({
                    content: 'PDF Sent Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                props.setExporting(props.index)
                
            })
            .catch((err) => {
                console.log(err);
                message.error({
                    content: 'Invalid Contact Number!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                props.setExporting(props.index)
            });
    }
    const mailExcel = (email) => {

        props.setExporting(props.index)
        axios
            .post(mainData.URL + '/api/v1/excel/email/'+ props.code + "?cc=" + mainData.company.code.toString() + "&uc=" + mainData.site.code.toString(),
            { "email": email, "filter": ""},
            {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                setIsValid(true)
                setConfirmLoading(false)
                setEmail("")
                setVisible(false)
                message.success({
                    content: 'Excel Sheet Mailed Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                props.setExporting(props.index)
                
            })
            .catch((err) => {
                console.log(err);
                props.setExporting(props.index)
            });
    }

    const handleOk = () => {
        setConfirmLoading(true);
        if(type !== "WPDF" && email.includes("@")){
            if(type === "Excel Sheet") mailExcel(email)
            else if(type === "PDF") mailPDF(email)
        }
        else if(type === "WPDF"){
            wPDF(wsp)
        }
        else{
            setIsValid(false)
            setConfirmLoading(false)
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        setIsValid(true)
        setEmail("")
        setWsp("")
        setCon("in")
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const onWspChange = (value, country, e, formattedValue) => {
        console.log(value);
        console.log(formattedValue);
        setWsp(value)
    }

    const deleteHandler = (event, slug) => {
        
        props.setLoading(true)
        props.setError(false)
        const DURL = mainData.URL + '/api/v1/reports/' + slug

        axios
            .delete(DURL, {withCredentials: true})
            .then((response) => {
               mainData.setAllReports( allReports => {
                   let newReports = [...allReports]
                   newReports = newReports.filter((report) => report.SLUG !== slug)
                   return newReports
               })
               props.setError(false)
               props.setLoading(false)
            })
            .catch((error) => {
                console.log(error.response);
                props.setError(true)
                props.setLoading(false)
            });
    }
    
    return(
        <div className = {classes['Div']}>
            <Modal
                title={ type === "WPDF" ? "Send PDF" :"Mail " + type }
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form
                    layout="vertical"
                >
                    {
                        type === "WPDF"
                        ?
                        <Form.Item 
                            label = {<div className={isValid ? classes['Label'] : classes['InvalidLabel']}>Receiver's WhatsApp Number</div>}
                        >
                            {/* <Input 
                                placeholder="Enter Contact Number with Country Code"
                                onChange = {onWspChange}
                                value = {wsp}
                                className = {classes["Input"]}
                            /> */}
                            <PhoneInput
                                country={con}
                                value = {wsp}
                                className = {classes["Input"]}
                                onChange = {onWspChange}
                                inputClass = {classes["Input"]}
                                inputStyle = {{border : "none", width: "100%"}}
                                buttonStyle = {{backgroundColor: "white", border: "none"}}
                            />
                        </Form.Item>
                        :
                        <Form.Item 
                            label = {<div className={isValid ? classes['Label'] : classes['InvalidLabel']}>Receiver's Mail ID</div>}
                        >
                            <Input 
                                placeholder="Enter Email ID"
                                onChange = {onEmailChange}
                                value = {email}
                                className = {isValid ? classes["Input"] : classes["InvalidInput"]}
                            />
                            {!isValid ? <p style = {{textAlign : "left", color: "red"}}>Enter a Valid Email!!!!</p> : null}
                        </Form.Item>  
                    }             
                </Form>
            </Modal>
            
                {
                    props.func
                    ?
                    <>
                        {
                            props.exporting
                            ?
                            <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                            :
                            <Row className = {classes['Row']} gutter = {20}>
                                <Col md = {3}> <Tooltip placement="bottom" title = "View" color = "#2EA2EC"><Link to = {props.link}><FaSearch className = {classes['StyledIcon']} /> </Link></Tooltip></Col>
                                <Col md = {3}> <Tooltip placement="bottom" title = "Print" color= "#1777C4"> <AiFillPrinter onClick = {printPagePdf} className = {classes['StyledIcon']} style = {{color: "#1777C4"}} /> </Tooltip> </Col>
                                <Col md = {3}> <Tooltip placement="bottom" title = "Download as Excel" color= "#1D6F42"> <RiFileExcel2Fill onClick = {printPageExcel} className = {classes['StyledIcon']} style = {{color: "#1D6F42"}} /> </Tooltip> </Col>
                                <Col md = {3}> <Tooltip placement="bottom" title = "Mail as Excel" color= "#1D6F42"> <RiMailSendFill onClick = {(event) => showModal(event, "Excel Sheet")} className = {classes['StyledIcon']} style = {{color: "#1D6F42"}} /> </Tooltip> </Col>
                                <Col md = {3}> <Tooltip placement="bottom" title = "Download as PDF" color= "#ED2224"> <FaFilePdf onClick = {printPagePdf} className = {classes['StyledIcon']} style = {{color: "#ED2224"}} /> </Tooltip> </Col>
                                <Col md = {3}> <Tooltip placement="bottom" title = "Mail as PDF" color= "#ED2224"> <RiMailSendFill onClick = {(event) => showModal(event, "PDF")} className = {classes['StyledIcon']} style = {{color: "#ED2224"}} /> </Tooltip> </Col>
                                <Col md = {3}> <Tooltip placement="bottom" title = "Send PDF Via Whatsapp" color= "#1D6F42"> <RiWhatsappFill onClick = {(event) => showModal(event, "WPDF")} className = {classes['StyledIcon']} style = {{color: "#1D6F42"}} /> </Tooltip> </Col>
                                {
                                    props.del
                                    ?
                                    <Col md = {3}> <Tooltip placement="bottom" title = "Delete" color= "red"> <AiFillDelete onClick = {(event) => deleteHandler(event, props.slug)} className = {classes['StyledIcon']} style = {{color: "red"}} /> </Tooltip> </Col>
                                    :
                                    null
                                }
                            </Row>
                        }
                    </>
                    :
                    <MdSettings />
                }  
        </div>
    );
}

export default Functions