import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import MembershipEdit from './MembershipEdit';

const Membership = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {12} md = {24} name = "MEMBERSHIP" value = {data.MEMBERSHIP}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "MEMEBR LEVEL" value = {data.MEM_LEVEL}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "SINCE DATE" value = {!data.SINCE_DATE ? null : data.SINCE_DATE.format('DD-MM-YYYY')}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "EXPIRY DATE" value = {!data.EXPIRY_DATE ? null : data.EXPIRY_DATE.format('DD-MM-YYYY')}/>          
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <MembershipEdit data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Membership