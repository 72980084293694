import classes from './FieldDataC.module.css'
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import ItemDetails from '../ItemMaster/ItemDetails';
import ItemNew from '../ItemMaster/ItemNew';
import ItemView from '../ItemMaster/ItemView';
import SalesCFormView from '../Transaction/SalesCFormView';
import SalesCForm from '../Transaction/SalesCForm';
import SalesCFormCreate from '../Transaction/SalesCFormCreate';
import CustomerDetails from '../CustomerMaster/CustomerDetails';
import CustomerNew from '../CustomerMaster/CustomerNew';
import CustomerView from '../CustomerMaster/CustomerView';
import DealerDetails from '../DealerMaster/DealerDetails';
import DealerNew from '../DealerMaster/DealerNew';
import DealerView from '../DealerMaster/DealerView';
import Charge from '../ChargeDefMaster/Charge';
import ChargeCreate from '../ChargeDefMaster/ChargeCreate';
import ChargeView from '../ChargeDefMaster/ChargeView';
import SalesDetails from '../SalesOrder/SalesDetails';
import SalesNew from '../SalesOrder/SalesNew';
import SalesView from '../SalesOrder/SalesView';
import InvoiceDetails from '../SalesInvoice/InvoiceDetails';
import InvoiceNew from '../SalesInvoice/InvoiceNew';




const FieldDataS = (fieldProps) => {

    return(
        <div className={classes['Fields']}>
            <Switch>

            <Route path="/sales" exact 
            render={(props) => (
                <Dashboard {...props} />
            )}
        />

            <Route path="/sales/itemMaster/item-details" exact 
            render={(props) => (
                <ItemDetails {...props} />
            )}
        />
        <Route path="/sales/itemMaster/item-details-view/:id" exact 
        render={(props) => (
            <ItemView {...props} />
        )}
    />

    <Route path="/sales/itemMaster/item-create" exact 
        render={(props) => (
            <ItemNew {...props} />
        )}
    />

<Route path="/sales/customerMaster/customer-details" exact 
            render={(props) => (
                <CustomerDetails {...props} />
            )}
        />


<Route path="/sales/customerMaster/customer-create" exact 
            render={(props) => (
                <CustomerNew {...props} />
            )}
        />

        
<Route path="/sales/customerMaster/customer-details-view/:id" exact 
render={(props) => (
    <CustomerView {...props} />
)}
/>


          
    

<Route path="/sales/dealerMaster/dealer-details" exact 
            render={(props) => (
                <DealerDetails {...props} />
            )}
        />



 
 <Route path="/sales/dealerMaster/dealer-create" exact 
        render={(props) => (
            <DealerNew {...props} />
        )}
    />
    <Route path="/sales/dealerMaster/dealer-form-view/:id" exact 
        render={(props) => (
            <DealerView {...props} />
        )}
    />


    <Route path='/sales/chargedefMaster/charge-details' exact 
        render={(props) => (
            <Charge {...props} />
        )}
    />
    <Route path='/sales/chargedefMaster/charge-create' exact 
    render={(props) => (
        <ChargeCreate {...props} />
    )}
     />

  <Route path="/sales/chargedefMaster/charge-form-view/:id" exact 
render={(props) => (
    <ChargeView {...props} />
    )}
   />

   <Route path = "/sales/transaction/salesForm" exact
            
    render={(props) => (
     <SalesCForm {...props} />
       )}
 
     />

     <Route path="/sales/transaction/sales-form-view/:id" exact 
     render={(props) => (
         <SalesCFormView {...props} />
     )}
    />
  <Route path="/sales/transaction/sales-form-create" exact 
     render={(props) => (
         <SalesCFormCreate {...props} />
     )}
    />

    <Route path='/sales/transaction/salesOrder' exact 
    render={(props) => (
        <SalesDetails {...props} />
    )}
    />  
     <Route path='/sales/transaction/sales-create' exact 
    render={(props) => (
        <SalesNew {...props} />
    )}
   />
   <Route path="/sales/transaction/salesOrder-form-view/:id" exact 
   render={(props) => (
       <SalesView {...props} />
   )}
  />
   
   <Route path='/sales/transaction/salesInvoice' exact 
    render={(props) => (
        <InvoiceDetails {...props} />
    )}
    />
      <Route path='/sales/transaction/invoice-create' exact 
    render={(props) => (
        <InvoiceNew {...props} />
    )}
   />         


            </Switch>
        </div>
    );
}

export default FieldDataS