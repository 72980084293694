import { Layout } from 'antd';
import './PayrollPage.css';
import classes from './PayrollPageExtra.module.css';
import SiderContentC from '../../components/Payroll/SiderContentC/SiderContentC';
import { Scrollbars } from 'react-custom-scrollbars';
import {useEffect, useState} from 'react';
import FieldDataC from '../../components/Payroll/FieldDataC/FieldDataC';
import { DataProvider } from '../../Context/dataContext';
import axios from 'axios';

const {Content, Sider } = Layout;

const PayrollPage = (props) => {

    const URL = props.url;
    const [collapsed, setCollapsed] = useState(true);
    const [miscList, setMiscList] = useState(null);

    useEffect(() => {
        axios
        .get(URL + '/api/v1/employee-misc',{withCredentials: true})
        .then((response) => {
            
            setMiscList(miscList => {
                const newList = response.data.data.tables.rows
                return newList
            })

        })
        .catch((err) => {
            console.log(err.response);
        });

    }, [])

    const employeeData = {
        URL: props.url,
        miscList: miscList,
    }

    const onCollapse = collapsed => {
        setCollapsed(collapsed);
    };

    return(
        <Layout style={{ minHeight: '100vh', backgroundColor: "transparent"}}>

            <Sider className = {classes['Sider']} collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <SiderContentC url = {props.url} miscList = {miscList} />
            </Sider>
           
            <Layout className = {classes['SiteLayout']}>
                <DataProvider value = {employeeData}>
                    <Scrollbars autoWidth autoHeight autoHeightMax={ ( window.innerHeight*1 )}>
                        <Content>
                            <FieldDataC />
                        </Content>
                    </Scrollbars>
                </DataProvider>
            </Layout>
        </Layout>
    );  
}

export default (PayrollPage);