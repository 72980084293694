import classes from '../Pages.module.css';
import {Row,Col, Skeleton} from 'antd';
import { useState, useEffect, useContext} from 'react';
import ToolRow from '../ToolRow/ToolRow';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import EmployeeTable from '../EmployeeTable/EmployeeTable';

const AttandanceData = (props) => {
    
    const customerData = useContext(DataContext)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState(null)
    const [ft, setFT] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [dFilter, setDFilter] = useState("all")

    useEffect(() => {

        if(dFilter !== "all"){
            if(dFilter !== "fromTo")
            {
                setFT(false)
                setToDate("")
                setFromDate("")
                setColumns([])
                setRows(null)
                axios
                .get(customerData.URL + '/api/v1/employees/attendance-data',{
                    withCredentials: true
                })
                .then((response) => {

                    console.log(response);

                    setColumns((columns) => {
                        let newCols =  response.data.data.employees.metaData.map((col) => {
                            return({
                                name: col.name,
                                title: col.name.split("_").join(" ").toLowerCase()
                            });
                        })

                        const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                        return newNewCols;
                    })

                    setRows(rows => {
                        let newRows = []
                        return newRows;
                    })

                })
                .catch((error) => {
                    console.log(error);
                })
            }

            else{
                setFT(true)
                if(fromDate !== "" && toDate !== ""){
            
                }
            }
        }

    }, [dFilter, fromDate, toDate])

    useEffect(() => {  

        if(dFilter === "all"){

            setFT(false);
            setToDate("")
            setFromDate("")
            setColumns([])
            setRows(null)
            axios
            .get(customerData.URL + '/api/v1/employees/attendance-data',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);

                setColumns((columns) => {
                    let newCols =  response.data.data.employees.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setRows(rows => {
                    let newRows = response.data.data.employees.rows.map((row,index) => {return(
                        {
                            "SNO": index + 1,
                            ...row
                        }
                    );})
                    return newRows;
                })

            })
            .catch((error) => {
                console.log(error);
            })
        }      
        
    }, [dFilter])


    const onDChange = (value, param) => {

        setDFilter(value)
    }

    const onDateChange = (date, dateString) => {
        
        setFromDate(dateString[0])
        setToDate(dateString[1])

    }

    return(
        <>            
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Attendance Data</p></Col>
                <Col className = {classes['Col']} md = {10}>
    
                </Col>
            </Row>
            <p></p>
            <ToolRow ft = {ft} fromDate = {fromDate} toDate = {toDate} onDateChange = {onDateChange} dFilter = {dFilter} onDChange = {onDChange}/>
            <p></p>
            {
                rows && columns.length > 0
                ?
                <EmployeeTable data = {rows} columns = {columns} />
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default AttandanceData;