import {Row, Col, Form, Input, Space, Button, Select, DatePicker} from 'antd';
import classes from '../Pages.module.css';
import { MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

const ItemDetailsEdit = (props) => {

    const handleValueChanges = (changedValues, allValues) => {
        props.setData(data => {
            return({
                ...data,
                itemDetails: {
                    ...data.itemDetails,
                    rows: allValues.items
                }
            })
        })
    }

    return(
        <Form layout="vertical" name="filter_form" onValuesChange = {handleValueChanges} autoComplete="off">
            <Form.List name="items" initialValue = {props.data}>
                {(fields, { add, remove }) => { 
                    
                    return(
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField}) => (
                        <Space key={key} className = {classes['Space']} align="center">
                            <Row gutter = {16}>
                                <Col lg = {24} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'ITEM_CD']}
                                        fieldKey={[fieldKey, 'ITEM_CD']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ITEM NAME"</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Select
                                            bordered = {false}
                                            style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            placeholder="Select"
                                            optionFilterProp="children"
                                        >
                                            {
                                                props.itemOptions.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.itemOptions.metaData[0].name]} value={option[props.itemOptions.metaData[0].name]}>{option[props.itemOptions.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'SIZE_CODE']}
                                        fieldKey={[fieldKey, 'SIZE_CODE']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>SIZE</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Select
                                            bordered = {false}
                                            style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            placeholder="Select"
                                            optionFilterProp="children"
                                        >
                                            {
                                                props.sizeOptions.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.sizeOptions.metaData[0].name]} value={option[props.sizeOptions.metaData[0].name]}>{option[props.sizeOptions.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'APPROVAL_DATE']}
                                        fieldKey={[fieldKey, 'APPROVAL_DATE']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>APPROVAL DATE</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                       <DatePicker
                                            format="DD-MM-YYYY"
                                            style={{ textAlign: "left", width: "100%" , backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                            bordered = {false}
                                            placeholder="Enter Date" />

                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'ISI_APPROVED']}
                                        fieldKey={[fieldKey, 'ISI_APPROVED']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ISI APPROVED</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Input
                                            style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            bordered = {false}
                                            placeholder="Enter ISI"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'AUTHORIZED']}
                                        fieldKey={[fieldKey, 'AUTHORIZED']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>AUTHORISED</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Input
                                            style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            bordered = {false}
                                            placeholder="Enter Authorized"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'WARRANTY']}
                                        fieldKey={[fieldKey, 'WARRANTY']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>WARRANTY</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Input
                                            style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            bordered = {false}
                                            placeholder="Enter Warranty"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'VENDOR_STATUS']}
                                        fieldKey={[fieldKey, 'VENDOR_STATUS']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>VENDOR STATUS</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Input
                                            style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            bordered = {false}
                                            placeholder="Enter Vendor Status"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg = {21} md = {0}>
                                    
                                </Col>
                                <Col lg = {1} md = {24}>
                                    <MinusCircleOutlined className = {classes['Remove']} onClick={() => { remove(name) }} />
                                </Col>
                                <Col lg = {1} md = {24}>
                                    <PlusCircleOutlined className = {classes['Add']} onClick={() => {add()}} />
                                </Col>
                                <Col lg = {1} md = {24}>
                                    
                                </Col>
                                <Col lg = {24} md = {24}>
                                    <hr></hr>
                                </Col>
                            </Row>                                                     
                        </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" className = {classes['DashedButton']}onClick={() => {add()}}>
                                Add Item
                            </Button>
                        </Form.Item>
                    </>
                )}}
            </Form.List>
        </Form>
    )
}

export default (ItemDetailsEdit)