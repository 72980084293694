import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import DealerGroupEdit from './DealerGroupEdit';

const DealerGroup= (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {24} md = {24} name = "DEALER GROUP" value = {data.EXTERNAL_ENTITY_GROUP_NAME}/> 
                       
                            
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <DealerGroupEdit   data = {props.data} setData = {props.setData} />
        </>
    );
}

export default DealerGroup;