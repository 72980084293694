import {useState, useEffect} from 'react';
import { Transfer } from 'antd';
import { Col, Row} from 'antd';
import classes from './ReportRepresentation.module.css';
import { UpOutlined } from '@ant-design/icons';
import {DownOutlined} from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

const TransferList = (props) => {

    useEffect(() => {
        props.setAKey("3")
    }, [])
    
    const [selectedKeys, setSelectedtKeys] = useState([])

    const handleChange = targetKeys => {
        props.setTargetKeys(targetKeys)
        };

    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        const arr = targetSelectedKeys;
        setSelectedtKeys(arr)
    }
 
    const handleUp = () => {
        const index = props.targetKeys.findIndex((element) => element === selectedKeys[0])
        if(index !== 0){
            props.setTargetKeys((targetKeys) => {
                let newTargets = [...targetKeys]
                let  temp = newTargets[index]
                newTargets[index] = newTargets[index - 1]
                newTargets[index - 1] = temp
                return newTargets;
            })
        }
    }
 
    const handleDown = () => {
        const n = props.targetKeys.length
        const index = props.targetKeys.findIndex((element) => element === selectedKeys[0])
        if(index !== n - 1){
            props.setTargetKeys(targetKeys => {
            let newTargets = [...targetKeys]
            let  temp = newTargets[index]
            newTargets[index] = newTargets[index + 1]
            newTargets[index + 1] = temp
            return newTargets;
            })
        }
    }
 
    const filterOption = (inputValue, option) => option.description.indexOf(inputValue) > -1;

    return(
       
        <Scrollbars  autoHide autoHeight autoHeightMax={ ( window.innerHeight >= 748 ? window.innerHeight*0.65 : window.innerHeight*0.64 )}>
            <div className = {classes['TypeRow']}>
                <Row gutter={16} >
                    <Col md = {22}>
                        <Transfer
                            dataSource={props.data}
                            showSearch
                            listStyle={{
                                width: "100%",
                                height: window.innerHeight <= 600 ? 0.55*window.innerHeight :  0.58*window.innerHeight,
                                backgroundColor: 'white',
                                border: "none",
                                boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                padding: "0.3rem 1rem 0rem 1rem",
                                fontSize: "0.8rem"
                            }}
                            operations={['ADD', 'REMOVE']}
                            targetKeys={props.targetKeys}
                            onChange={handleChange}
                            filterOption={filterOption}
                            onSelectChange = {(sourceSelectedKeys, targetSelectedKeys) => handleSelectChange(sourceSelectedKeys, targetSelectedKeys)}
                            render={item => {return(<p className = {classes["ListItem"]}>{item.title.split("_").join(" ").toLowerCase()} </p>);}}
                        />
                    </Col>
                    <Col  className = {classes['ButtonColumn']} md = {2}>
                        { selectedKeys.length === 1? <button onClick = {(event) => handleUp(event)} className = {classes['OperationButton']}><UpOutlined style = {{fontSize : "15px", fontWeight : "bold"}}/></button> : null}
                        { selectedKeys.length === 1 ? <button onClick = {(event) => handleDown(event)} className = {classes['OperationButton']}><DownOutlined style = {{fontSize : "15px", fontWeight : "bold"}}/></button> : null}
                    </Col>
                </Row>
            </div>
        </Scrollbars>
               
    );
}

export default TransferList