import classes from '../Pages.module.css';
import {Row,Col, Tabs, Skeleton, message} from 'antd';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import { useState, useEffect, useContext} from 'react';
import BasicDetails from './BasicDetails'
import AddressDetails from './AddressDetails';
import Education from './Education';
import Language from './Language'
import Experience from './Experience'
import Health from './Health';
import Dependent from './Dependent';
import Training from './Training';
import Membership from './Membership';
import Department from './Department';
import OtherDetails from './OtherDetails';
import City from './City';
import Skill from './Skill';
import Designation from './Designation';
import DocumentVerification from './DocumentVerification';
import moment from 'moment';
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory } from 'react-router';

const { TabPane } = Tabs;

const identifiers = ['education', 'languages', 'experience', 'health', 'dependent', 'training', 'membership', 'department', 'skill', 'city', 'documentVerification'];

const EmployeeView = (props) => {

    const employeeData = useContext(DataContext)
    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(true)
    const [allData, setAllData] = useState({
        "employeeMaster": [
            {
                "EMPLOYEE_CODE": null,
                "PF_REQ": null,
                "REST_DAY": null,
                "EMPLOYEE_NAME": null,
                "EMPLOYEE_MNAME": null,
                "EMPLOYEE_LNAME": null,
                "EMAIL": null,
                "CTC": null,
                "SHIFT": null,
                "EMPLOYEE_DESIG_CODE": null,
                "GROSS_SALARY": null,
                "QUALIFICATIONS": null,
                "EMPLOYEE_GRADE_CODE": null,
                "EXPERIENCE": null,
                "EMPLOYEE_DEPT_CODE": null,
                "VALIDITY_DAYS": null,
                "EARNED_LV": null,
                "HOD": null,
                "CASUAL_LV": null,
                "TOLERANCE": null,
                "TOLERANCE_TYPE": null,
                "EMPLOYEE_TYPE_CODE": null,
                "LIMIT": null,
                "LIMIT_TYPE": null,
                "OVERTIME": null,
                "SALARY_NORM_CODE": null,
                "ACC_GROUP_CODE": null,
                "ADHAR_NO": null,
                "FATHER_NAME": null,
                "MOTHER_NAME": null,
                "MARITIAL_STATUS": null,
                "DOB": null,
                "SEX": null,
                "MEDICLAIM_AMOUNT": null,
                "HEIGHT": null,
                "DOC": null,
                "BLOOD_GROUP": null,
                "DOJ": null,
                "WEIGHT": null,
                "DISTANCE_NATIVE": null,
                "DOW": null,
                "DOL": null,
                "PAN_NUMBER": null,
                "INSURANCE_AMOUNT": null,
                "BANK_NAME": null,
                "BRANCH_ADDRESS": null,
                "RELIGION_CODE": null,
                "NATIONALITY": null,
                "PFACCOUNT_NO": null,
                "BANK_ACC": null,
                "ESI_NUMBER": null,
                "OVERTIME_1": null,
                "TERM_INSURANCE_PREMIUM": null,
                "IFSC_CODE": null,
                "HADICAPPED": null,
                "PROBATION_PERIOD": null,
                "TERM_INSURANCE_VALUE": null,
                "FILE_NO": null,
                "REPORTING_TO": null,
                "CARD_NO": null,
                "UAN": null,
                "NAME_IN_BANK": null,
                "DISP_NAME": null,
                "REFERENCE": null,
                "ADDRESS": null,
                "COUNTRY_CODE": null,
                "STATE_CODE": null,
                "CITY_CODE": null,
                "PINCODE": null,
                "PHONE": null,
                "MOBILE": null,
                "FAX": null,
                "ADDRESS_PRESENT": null,
                "COUNTRY_CODE_PRESENT": null,
                "STATE_CODE_PRESENT": null,
                "CITY_CODE_PRESENT": null,
                "PINCODE_PRESENT": null,
                "PHONE_PRESENT": null,
                "ADDRESS_CORR": null,
                "COUNTRY_CODE_CORR": null,
                "STATE_CODE_CORR": null,
                "CITY_CODE_CORR": null,
                "PINCODE_CORR": null,
                "PHONE_CORR": null,
                "UNIT_CODE": null
            }
        ],
        "education": [],
        "languages": [],
        "experience": [],
        "health": [],
        "dependent": [],
        "training": [],
        "membership": [],
        "department": [],
        "designation": [],
        "skill": [],
        "city": [],
        "documentVerification": []
    })
    const [ad, setAD] = useState(null)

    useEffect(() => {
        
        setEditMode(true)
        setAD(null)
        setLoading(false)
        axios
            .get(employeeData.URL + '/api/v1/employees/additional-data',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAD(ad => {
                    let newad = response.data.data
                    return ({
                        ...newad,
                        GENDER: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [{KEY: "M", VALUE: "Male"},{KEY: "F", VALUE: "Female"}]
                        },
                        HANDICAPPED: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [{KEY: "Y", VALUE: "Yes"},{KEY: "N", VALUE: "No"}]
                        },
                        RWS: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [{KEY: "Y", VALUE: "Yes"},{KEY: "N", VALUE: "No"}]
                        },
                        MARITIAL_STATUS: {
                            metaData: [{name: "KEY"},{name: "VALUE"}],
                            rows: [{KEY: "M", VALUE: "Married"},{KEY: "U", VALUE: "Unmarried"}]
                        }
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            })
        
    }, [])

    const handleSave = (event) => {

        var formv = true;

        identifiers.forEach(val => {
            let count = 0;
            allData[val].forEach(data => {
                
                if(!data){

                    formv = formv&&false
                    if(count === 0){
                        message.error({
                            content: 'Empty Fields In ' + val.toUpperCase() + ' Tab!!!',
                            className: 'custom-class',
                            style: {
                                marginTop: '1vh',
                            },
                        });
                    }

                    count = count + 1;
                }
            })
        })

        if(formv){
            setLoading(true)
            const postData = {
                ...allData,
                department: allData.department.map(dep => {
                                return(
                                    {
                                        ...dep,
                                        DATE_JOIN: dep.DATE_JOIN ? dep.DATE_JOIN.format('DD-MM-YYYY') : null,
                                        DATE_RELEASE: dep.DATE_RELEASE ? dep.DATE_RELEASE.format('DD-MM-YYYY') : null,
                                    }
                                )
                            }),
                designation: allData.designation.map(dep => {
                                return(
                                    {
                                        ...dep,
                                        DATE_JOIN: dep.DATE_JOIN ? dep.DATE_JOIN.format('DD-MM-YYYY') : null,
                                        DATE_RELEASE: dep.DATE_RELEASE ? dep.DATE_RELEASE.format('DD-MM-YYYY') : null,
                                    }
                                )
                            }),
                dependent: allData.dependent.map(dep => {
                                return(
                                    {
                                        ...dep,
                                        DOB: dep.DOB ? dep.DOB.format('DD-MM-YYYY') : null,
                                    }
                                )
                            }),
                experience: allData.experience.map(dep => {
                                return(
                                    {
                                        ...dep,
                                        FROM_DATE: dep.FROM_DATE ? dep.FROM_DATE.format('DD-MM-YYYY') : null,
                                        TO_DATE: dep.TO_DATE ? dep.TO_DATE.format('DD-MM-YYYY') : null,
                                    }
                                )
                            }),
                membership: allData.membership.map(dep => {
                                return(
                                    {
                                        ...dep,
                                        SINCE_DATE: dep.SINCE_DATE ? dep.SINCE_DATE.format('DD-MM-YYYY') : null,
                                        EXPIRY_DATE: dep.EXPIRY_DATE ? dep.EXPIRY_DATE.format('DD-MM-YYYY') : null,
                                    }
                                )
                            }),
                training:  allData.training.map(dep => {
                                return(
                                    {
                                        ...dep,
                                        START_DATE: dep.START_DATE ? dep.START_DATE.format('DD-MM-YYYY') : null,
                                    }
                                )
                            }),
            }

            console.log(postData);

            axios
                .post(employeeData.URL + '/api/v1/employees', postData,  {
                    withCredentials: true,
                    credentials: 'include',
                })
                .then((response) => {
                    message.success({
                        content: 'Employee Created Successfully!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '2vh',
                        },
                    });
                    setLoading(false)
                    history.replace('/payroll/employee-details')
                })
                .catch((err) => {
                    message.error({
                        content: 'An Error Occurred!!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '2vh',
                        },
                    });
                    setLoading(false)
                });
        }
        
    }

    const handleCancel = (event) => {

        setLoading(false)
        history.replace('/payroll/employee-details')
    }

    return(
        <>
            {
                ad
                ?
                <Row className = {classes['RowP']}>
                    <Col lg = {editMode ? 13 : 19} md = {editMode ? 13 : 19} className = {classes['Col']} >

                    </Col>
                    {
                        editMode
                        ?
                        <>
                            <Col md = {4} >
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{ backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                                }
                            </Col>
                            <Col md = {4} >
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancel(event)} className = {classes['ProfileButton']}>Cancel</button>
                                }
                                
                            </Col>
                        </>
                        :
                        <Col md = {4} >
                            <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Details</button>
                        </Col>
                    }
                    <Col lg = {1} md = {1} >

                    </Col>
                    <Col lg = {24} md = {24} className = {classes['Col']}>
                        <div className = {classes['EditView']}>
                            <Tabs defaultActiveKey="0" centered style = {{ height: "100%"}} tabPosition={"left"} type = "line" tabBarGutter = "0">
                                <TabPane tab= {<span>Basic Details</span>} key="0">
                                    <BasicDetails create = {true} ad = {ad} setData = {setAllData} data = {allData.employeeMaster[0]} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Address</span>} key="1">
                                    <AddressDetails  ad = {ad} setData = {setAllData} data = {allData.employeeMaster[0]} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Education</span>} key="2">
                                    <Education  ad = {ad} setData = {setAllData} data = {allData.education} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Language</span>} key="3">
                                    <Language  ad = {ad} setData = {setAllData} data = {allData.languages} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Experience</span>} key="4">
                                    <Experience  ad = {ad} setData = {setAllData} data = {allData.experience} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Health</span>} key="5">
                                    <Health  ad = {ad} setData = {setAllData} data = {allData.health} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Dependent</span>} key="6">
                                    <Dependent  ad = {ad} setData = {setAllData} data = {allData.dependent} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Training</span>} key="7">
                                    <Training  ad = {ad} setData = {setAllData} data = {allData.training} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Membership</span>} key="8">
                                    <Membership  ad = {ad} setData = {setAllData} data = {allData.membership} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Department</span>} key="10">
                                    <Department  ad = {ad} setData = {setAllData} data = {allData.department} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Designation</span>} key="11">
                                    <Designation ad = {ad} setData = {setAllData} data = {allData.designation} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Skills</span>} key="12">
                                    <Skill   ad = {ad} setData = {setAllData} data = {allData.skill} editMode = {editMode} />  
                                </TabPane>
                                <TabPane tab= {<span>City</span>} key="13">
                                  <City   ad = {ad} setData = {setAllData} data = {allData.city} editMode = {editMode} />  
                                </TabPane>
                                <TabPane tab= {<span>Document Verification</span>} key="14">
                                    <DocumentVerification   ad = {ad} setData = {setAllData} data = {allData.documentVerification} editMode = {editMode} /> 
                                </TabPane>
                                <TabPane tab= {<span>Other Details</span>} key="15">
                                    <OtherDetails  ad = {ad} setData = {setAllData} data = {allData.employeeMaster[0]} editMode = {editMode} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                
                </Row>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
           
        </>
    );
}

export default EmployeeView