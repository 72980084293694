import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const AddressDetails = (props) => {

    const handleChange = (e,param,id) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                employeeMaster: newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = val
            return({
                ...data,
                employeeMaster: newdata
            })
        })
    }


    return(
        <div>
            <p></p>
            <p style = {{ fontWeight: "bolder"}} >Permanent Address</p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "ADDRESS"    name = "ADDRESS" value = {props.data.ADDRESS}/>          
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.CITY_CODE} param = "CITY_CODE"   type = "Select"    name = "CITY" value = {props.data.CITY_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.STATE_CODE} param = "STATE_CODE" type = "Select"   name = "STATE" value = {props.data.STATE_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.COUNTRY_CODE} param = "COUNTRY_CODE"type = "Select"    name = "COUNTRY" value = {props.data.COUNTRY_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} param = "PINCODE"    name = "PIN" value ={props.data.PINCODE}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "FAX"    name = "FAX" value = {props.data.FAX}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "MOBILE"    name = "MOBILE" value = {props.data.MOBILE}/>   
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "PHONE"    name = "PHONE" value = {props.data.PHONE}/> 
            </Row>
            <hr></hr>
            <p style = {{fontWeight: "bolder"}} >Present Address</p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "ADDRESS_PRESENT"     name = "ADDRESS" value = {props.data.ADDRESS_PRESENT}/>          
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.CITY_CODE} param = "CITY_CODE_PRESENT"   type = "Select"  name = "CITY" value = {props.data.CITY_CODE_PRESENT}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.STATE_CODE} param = "STATE_CODE_PRESENT"  type = "Select"   name = "STATE" value = {props.data.STATE_CODE_PRESENT}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleSChange} options = {props.ad.COUNTRY_CODE} param = "COUNTRY_CODE_PRESENT" type = "Select"    name = "COUNTRY" value = {props.data.COUNTRY_CODE_PRESENT}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "PINCODE_PRESENT"     name = "PIN" value ={props.data.PINCODE_PRESENT}/>  
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "PHONE_PRESENT"     name = "PHONE" value = {props.data.PHONE_PRESENT}/> 
            </Row>
            <hr></hr>
            <p style = {{ fontWeight: "bolder"}} >Corresponding Address</p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} param = "ADDRESS_CORR"    name = "ADDRESS" value = {props.data.ADDRESS_CORR}/>          
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.CITY_CODE} param = "CITY_CODE_CORR"   type = "Select" name = "CITY" value = {props.data.CITY_CODE_CORR}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.STATE_CODE} param = "STATE_CODE_CORR"  type = "Select"  name = "STATE" value = {props.data.STATE_CODE_CORR}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleSChange} options = {props.ad.COUNTRY_CODE} param = "COUNTRY_CODE_CORR"  type = "Select"  name = "COUNTRY" value = {props.data.COUNTRY_CODE_CORR}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "PINCODE_CORR"    name = "PIN" value ={props.data.PINCODE_CORR}/>   
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} param = "PHONE_CORR"    name = "PHONE" value = {props.data.PHONE_CORR}/> 
            </Row>
            <hr></hr>
        </div>
    );
}

export default AddressDetails