import { Scrollbars } from 'react-custom-scrollbars';
import classes from './App.module.css';
import { Route } from 'react-router-dom';
import LayoutWrapper from './components/LayoutWrapper/LayoutWrapper'
import MainPage from './Pages/MainPage/MainPage'
import CustomerPortalPage from './Pages/CustomerPortalPage/CustomerPortalPage'
import VendorPortalPage from './Pages/VendorPortalPage/VendorPortalPage'
import PayrollPage from './Pages/PayrollPage/PayrollPage';
import LandingPage from './Pages/LandingPage/LandingPage';
import LandingPageC from './Pages/LandingPageC/LandingPageC';
import LandingPageV from './Pages/LandingPageV/LandingPageV';
import SignInPage from './Pages/SignInPage/SignInPage';
import SignInPageC from './Pages/SignInPageC/SignInPageC';
import SignInPageV from './Pages/SignInPageV/SignInPageV';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {message} from 'antd';
import { useHistory } from "react-router-dom";
import SalesModule from './Pages/SalesModulePage/SalesModule';



function App() {

  const AURL = 'http://lohabazaar.com';
  const [company, setCompany] = useState(null)
  const [site, setSite] = useState(null)
  const [type, setType] = useState("")
  const [analysis, setAnalysis] = useState(null)
  const [analysisV, setAnalysisV] = useState(null)
  const history = useHistory();

  useEffect(() => {
    axios
      .get(AURL + '/api/v1/users/checkStatus',{
          withCredentials: true
      })
      .then((responseA) => { 

        console.log(responseA);

        if(responseA.data.data){

          if(responseA.data.data.user.rows[0].userType === "employee"){
            axios
            .get(AURL + '/api/v1/cns',{withCredentials: true})
            .then((response) => {
                
              const index = response.data.data.companies.findIndex((element) => element.COMPANY_CODE === responseA.data.data.user.rows[0].COMPANY_CODE)
              setCompany({code: responseA.data.data.user.rows[0].COMPANY_CODE, name:response.data.data.companies[index].COMPANY_NAME});

              const index2 = response.data.data.sites.findIndex((element) => element.SITE_CODE === responseA.data.data.user.rows[0].UNIT_CODE)
              setSite({code: responseA.data.data.user.rows[0].UNIT_CODE, name: response.data.data.sites[index2].SITE_DESC});
              
            })
            .catch((err) => {
              console.log(err);
            });
          }
          if(responseA.data.data.user.rows[0].userType === "erp"){
            axios
            .get(AURL + '/api/v1/cns',{withCredentials: true})
            .then((response) => {
                
              const index = response.data.data.companies.findIndex((element) => element.COMPANY_CODE === responseA.data.data.user.rows[0].COMPANY_CODE)
              setCompany({code: responseA.data.data.user.rows[0].COMPANY_CODE, name:response.data.data.companies[index].COMPANY_NAME});

              const index2 = response.data.data.sites.findIndex((element) => element.SITE_CODE === responseA.data.data.user.rows[0].UNIT_CODE)
              setSite({code: responseA.data.data.user.rows[0].UNIT_CODE, name: response.data.data.sites[index2].SITE_DESC});
              
            })
            .catch((err) => {
              console.log(err);
            });
          }

          if(responseA.data.data.user.rows[0].userType === "customer"){
            setAnalysis(null)
            axios
            .get(AURL + '/api/v1/customer/analysis',{withCredentials: true})
            .then((response) => {
                
              setAnalysis((analysis) => {
                let newAnalysis = response.data.data
                return newAnalysis
              })
              
            })
            .catch((err) => {
              console.log(err);
            });
          }

          if(responseA.data.data.user.rows[0].userType === "vendor"){
            axios
            .get(AURL + '/api/v1/vendor/analysis',{withCredentials: true})
            .then((response) => {
                
              setAnalysisV((analysis) => {
                let newAnalysis = response.data.data
                return newAnalysis
              })
              
            })
            .catch((err) => {
              console.log(err);
            });
          }

          if(responseA.data.data.user.rows[0].userType !== "customer" && window.location.href.split('/')[3] === "customer-portal"){
            history.replace('/customer')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

          if(responseA.data.data.user.rows[0].userType !== "vendor" && window.location.href.split('/')[3] === "vendor-portal"){
            history.replace('/vendor')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

          if(responseA.data.data.user.rows[0].userType !== "employee" && window.location.href.split('/')[3] === "reports"){
            history.replace('/employee')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

          if(responseA.data.data.user.rows[0].userType !== "payroll" && window.location.href.split('/')[3] === "payroll"){
            history.replace('/employee')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
          if(responseA.data.data.user.rows[0].userType !== "SalesModule" && window.location.href.split('/')[3] === "SalesModule"){
            history.replace('/employee')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

        }

        else{

          if(window.location.href.split('/')[3] !== "employee" && window.location.href.split('/')[3] !== "signIn" && window.location.href.split('/')[3] === "reports"){
            history.replace('/employee')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

          else if(window.location.href.split('/')[3] !== "employee" && window.location.href.split('/')[3] !== "signIn" && window.location.href.split('/')[3] === "payroll"){
            history.replace('/employee')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
          else if(window.location.href.split('/')[3] !== "erp" && window.location.href.split('/')[3] !== "signIn" && window.location.href.split('/')[3] === "erp"){
            history.replace('/erp')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

          else if(window.location.href.split('/')[3] !== "customer" && window.location.href.split('/')[3] !== "signInC" && window.location.href.split('/')[3] === "customer-portal"){
            history.replace('/customer')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

          else if(window.location.href.split('/')[3] !== "vendor" && window.location.href.split('/')[3] !== "signInV" && window.location.href.split('/')[3] === "vendor-portal"){
            history.replace('/vendor')
            message.error({
              content: 'Sign In to gain Access!!!!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  return (
    <Scrollbars autohide autoheight className={classes['App']}> 

      <LayoutWrapper>  

        <Route exact path = '/employee'> 
          <LandingPage type = {type} setType = {setType}/>
        </Route> 
      
        <Route exact path = '/signIn'> 
          <SignInPage url = {AURL} analysis = {analysis} setAnalysis = {setAnalysis} setCompany = {setCompany} setSite = {setSite} company = {company} site = {site}  type = {type} />
        </Route> 

        <Route exact path = '/customer'> 
          <LandingPageC type = {type} setType = {setType}/>
        </Route> 

        <Route exact path = '/signInC'> 
          <SignInPageC url = {AURL} analysis = {analysis} setAnalysis = {setAnalysis}/>
        </Route> 

        <Route exact path = '/vendor'> 
          <LandingPageV type = {type} setType = {setType}/>
        </Route> 

        <Route exact path = '/signInV'> 
          <SignInPageV url = {AURL} analysis = {analysisV} setAnalysis = {setAnalysisV} />
        </Route> 

        <Route path = '/reports'>     

          {
            company && site
            ?
            <MainPage url = {AURL} company = {company} site = {site}/>
            
            :
            null
          }
          
         
        </Route>
       
        
        <Route path = '/customer-portal'> 
          {
            analysis
            ?
            <CustomerPortalPage url = {AURL} analysis = {analysis} />
            :
            null
          }
        </Route>
        
        <Route path = '/vendor-portal'> 
          {
            analysisV
            ?
            <VendorPortalPage url = {AURL} analysis = {analysisV} />
            :
            null
          }
        </Route>
        
        <Route path = '/payroll'> 
          <PayrollPage url = {AURL} />
        </Route>
        <Route path = '/Sales'> 
        <SalesModule url = {AURL} />
      </Route>

      </LayoutWrapper>

    </Scrollbars>
  );
}

export default App;
