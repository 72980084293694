import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import EducationEdit from './EducationEdit';

const Education = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {24} md = {24} options = {props.ad.EXAM_CODE} type = "Select" name = "EXAM NAME" value = {data.EXAM_CODE}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "SCHOOL" value = {data.SCHOOL_NAME}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "UNIVERSITY or BOARD" value = {data.EXAM_BOARD}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "YEAR of PASSING" value = {data.PASS_YEAR}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "MARKS" value = {data.MARKS}/> 
                        <DataField editMode = {false} lg = {24} md = {24} name = "SUBJECTS" value = {data.SUBJECTS}/>          
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <EducationEdit examOptions = {props.ad.EXAM_CODE} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Education