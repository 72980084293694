import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import TrainingEdit from './TrainingEdit';

const Training = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {8} md = {24} name = "INSTITUTE" value = {data.INSTITUTE}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "START DATE" value = {!data.START_DATE ? null : data.START_DATE.format('DD-MM-YYYY')}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "DURATION" value = {data.DURATION}/> 
                        <DataField editMode = {false} lg = {24} md = {24} name = "SKILLS ACQUIRED" value = {data.SKILLS_ACQUIRED}/>          
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <TrainingEdit data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Training