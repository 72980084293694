import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import DeliverySiteDetailsEdit from './DeliverySiteDetailsEdit';

const DeliverySiteDetails = (props) => {
    return(

        !props.editMode
        ?
        props.data.map((site, index) => {
            return(
                <div key = {index}>
                    <Row className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                        <DataField editMode = {props.editMode} lg = {24} md = {24} name = "SITE NAME" value = {site.NAME}/>
                        <DataField editMode = {props.editMode} lg = {24} md = {24} name = "ADDRESS" value = {site.ADDRESS}/> 
                        <DataField editMode = {props.editMode} lg = {12} md = {24} options = {props.ad.LOCALITY_CODE} type = "Select" name = "LOCALITY" value = {site.LOCALITY_CODE}/>            
                        <DataField editMode = {props.editMode} lg = {12} md = {24} options = {props.ad.CITY_CODE} type = "Select" name = "CITY" value = {site.CITY_CODE}/>
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "PIN" value = {site.PIN}/>
                        <DataField editMode = {props.editMode} lg = {12} md = {24} options = {props.ad.STATE_CODE} type = "Select" name = "STATE" value = {site.STATE_CODE}/> 
                        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} name = "SITE CODE" value = {site.UNIQUE_CODE}/> */}
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "GST NUMBER" value = {site.GST_NUMBER}/> 
                    </Row>
                    <p></p>
                </div>
            );
        })
       
        :
        <DeliverySiteDetailsEdit localityOptions = {props.ad.LOCALITY_CODE} cityOptions = {props.ad.CITY_CODE} stateOptions = {props.ad.STATE_CODE} data = {props.data} setData = {props.setData} />

    );
}

export default DeliverySiteDetails