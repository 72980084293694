import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import CityEdit from './CityEdit';

const City = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>

                        <DataField editMode = {false} lg = {24} md = {24} name = "CITY" value = {data.CITY}/> 
                            
                        <DataField editMode = {false} lg = {12} md = {24}  options = {props.ad.CITY_CODE} type = "Select" name = "CITY" value = {data.CITY_CODE}/>             

                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <CityEdit options = {props.ad.CITY_CODE} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default City;