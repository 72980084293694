import {Row} from 'antd';
import classes from '../Pages.module.css';
import SchedulerDataField from '../FormRequest/SchedulerDatafield'


const BasicSchedulerDetails = (props) => {



    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = dateString
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = val
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 

           
                <SchedulerDataField editMode = {props.editMode} lg = {8} md = {24}  handleChange = {handleChange}  name = "REPORT NAME" param = "REPORT_CODE" value = {props.data.REPORT_CODE ? props.data.REPORT_CODE : null }/>
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name= "REPORT FORMAT" param = "REPORT_FORMAT" value = {props.data.REPORT_FORMAT ? props.data.REPORT_FORMAT: null }/> 
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name = "EMAIL" param = "EMAIL" value = {props.data.EMAIL ? props.data.EMAIL : null }/> 
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "PHONE NO" param = "PHONE_NO" value = {props.data.PHONE_NO ? props.data.PHONE_NO : null }/> 
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name = "START DATE" param = "START_DATE" value = {props.data.START_DATE ? props.data.START_DATE : null }/> 
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name = "FREQUENCY" param = "FREQUENCY" value = {props.data.FREQUENCY ? props.data.FREQUENCY: null }/>
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name = "TIME" param = "TIME" value = {props.data.TIME ? props.data.TIME : null }/> 
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name = "SITE CODE" param = "SITE_CODE" value = {props.data.SITE_CODE ? props.data.SITE_CODE : null }/> 
                <SchedulerDataField  editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange}  name = "COMPANY CODE" param = "COMPANY_CODE" value = {props.data.COMPANY_CODE ? props.data.COMPANY_CODE : null }/>  
 </Row>
            
        </div>
    );
}


export default BasicSchedulerDetails;
