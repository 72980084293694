import { Scrollbars } from 'react-custom-scrollbars';
import classes from './ReportRepresentation.module.css';
import { Collapse } from 'antd';
import { Select } from 'antd';
import {useState, useEffect} from 'react'
import {FaSortAlphaDown, FaSortAlphaUp} from 'react-icons/fa';

const { Option } = Select;
const { Panel } = Collapse;

const MatrixGroupings = (props) => {

    const [dynamicColumns, setDynamicColumns] = useState([props.columns])
    const [func, setFunc] = useState("count")

    useEffect(() => {

        let matrixInfo = []
        if(props.parentReportData.report_matX){
            matrixInfo = [
                {
                    key: "1",
                    col: props.parentReportData.report_matX,
                    ascending: true,
                    descending: false,
                },
                {
                    key: "2",
                    col: props.parentReportData.report_matY,
                    ascending: true,
                    descending: false,
                },
                {
                    key: "3",
                    col: props.parentReportData.report_matData,
                    function: props.parentReportData.report_matAction,
                }
            ]
        }

        props.setMatrixSummaryInfo(matrixInfo);

    }, [])

    const onChange = (value, key) => {

        const index = props.matrixSummaryInfo.findIndex((element) => element.key === key)
        const removeIndex = dynamicColumns.findIndex((element) => element.value === value)

        setDynamicColumns((dynamicColumns) => {
            let newColumns = [...dynamicColumns]
            newColumns.splice(removeIndex, 1)
            return newColumns;
        })

        if(index === -1) {

            if(key === "3"){
                props.setMatrixSummaryInfo(matrixSummaryInfo => {
                    let newInfo = [...matrixSummaryInfo]
                    newInfo.push({
                        key: key,
                        col: value,
                        function: func,
                    })
                    return newInfo;
                })
            }
            else{
                props.setMatrixSummaryInfo(matrixSummaryInfo => {
                    let newInfo = [...matrixSummaryInfo]
                    newInfo.push({
                        key: key,
                        col: value,
                        ascending: true,
                        descending: false,
                    })
                    return newInfo;
                })
            }
            
            
        }

        else{

            props.setMatrixSummaryInfo(matrixSummaryInfo => {
                let newInfo = [...matrixSummaryInfo]
                newInfo[index].col = value
                return newInfo;
            })  
        }
    }

    const handleSort = (event, key) => {

        const index = props.matrixSummaryInfo.findIndex((element) => element.key === key)
        if(index !== -1) {
            props.setMatrixSummaryInfo(matrixSummaryInfo =>{
                let newInfo = [...matrixSummaryInfo]
                if(newInfo[index]["ascending"]) { newInfo[index]["descending"] = true; newInfo[index]["ascending"] = false; } 
                else { newInfo[index]["descending"] = false; newInfo[index]["ascending"] = true; }
                return newInfo;
            })        
        }
    }

    const onFuncChange = (value) => {

        setFunc(value)
        props.setMatrixSummaryInfo(matrixSummaryInfo => {
            let newInfo = [...matrixSummaryInfo]
            newInfo[2].function = value
            return newInfo;
        })  
    }
    return(
       
        <Scrollbars  autoHide autoHeight autoHeightMax={ ( window.innerHeight >= 748 ? window.innerHeight*0.48 : window.innerHeight*0.48 )}>
            <div className = {classes['TypeRow']}>
                <Collapse defaultActiveKey={['1']} ghost style = {{textAlign : "left", fontWeight : "bolder"}}>
                    <Panel header="Set Column Headings" key="1">
                        <div style = {{textAlign: "left", fontWeight : "bolder"}}>
                            <Select
                                showSearch
                                value = {props.matrixSummaryInfo[0] ? props.matrixSummaryInfo[0].col : null}
                                dropdownStyle = {{textTransform: "capitalize"}}
                                bordered = {false}
                                onChange = {(value) => onChange(value, "1")}
                                style={{ width: "60%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                placeholder="Search to Select Subtotal Parameter"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    props.matrixColumns.map((mCol) => {

                                        if(props.matrixSummaryInfo[1]){
                                            if(mCol !== props.matrixSummaryInfo[1].col){
                                                return(
                                                    <Option key = {mCol} value={mCol}>{mCol.split('_').join(" ").toLowerCase()}</Option>
                                                );
                                            }
                                            else return null
                                        }
                                        return(
                                            <Option key = {mCol} value={mCol}>{mCol.split('_').join(" ").toLowerCase()}</Option>
                                        );
                                    })
                                }
                            </Select>

                            {
                                props.matrixSummaryInfo[0] 
                                ? 
                                <button onClick = {(event) => handleSort(event,"1")} style = {{float : "left"}} className = {classes['SortButton']}>
                                    {props.matrixSummaryInfo[0]["ascending"] ? <FaSortAlphaDown style = {{fontSize : "1.3rem"}} /> : <FaSortAlphaUp style = {{fontSize : "1.3rem"}} />}
                                </button> : null  
                            }
                        </div>
                    </Panel>
                </Collapse>
                {
                   props.matrixSummaryInfo[0] && (props.matrixSummaryInfo[0]["ascending"] || props.matrixSummaryInfo[0]["descending"])
                   ? 
                   <Collapse defaultActiveKey={['2']} ghost>
                        <Panel header="Set Row Headings" key="2" style = {{textAlign : "left", fontWeight : "bolder"}}>
                            <div style = {{textAlign: "left", fontWeight : "bolder"}}>
                                    <Select
                                        showSearch
                                        value = {props.matrixSummaryInfo[1] ? props.matrixSummaryInfo[1].col : null}
                                        bordered = {false}
                                        dropdownStyle = {{textTransform: "capitalize"}}
                                        onChange = {(value) => onChange(value, "2")}
                                        style={{ width: "60%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                        placeholder="Search to Select Subtotal Parameter"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {
                                            props.matrixColumns.map((mCol) => {

                                                if(mCol !== props.matrixSummaryInfo[0].col){
                                                    return(
                                                        <Option key = {mCol} value={mCol}>{mCol.split('_').join(" ").toLowerCase()}</Option>
                                                    );
                                                }
                                                
                                                return null;
                                                
                                            })
                                        }
                                    </Select>

                                    {
                                        props.matrixSummaryInfo[1] 
                                        ? 
                                        <button onClick = {(event) => handleSort(event,"2")} style = {{float : "left"}} className = {classes['SortButton']}>
                                            {props.matrixSummaryInfo[1]["ascending"] ? <FaSortAlphaDown style = {{fontSize : "1.3rem"}} /> : <FaSortAlphaUp style = {{fontSize : "1.3rem"}} />}
                                        </button> : null  
                                    }
                                </div>
                        </Panel>
                    </Collapse>
                    :
                    null
                }
                {
                   props.matrixSummaryInfo[1] && (props.matrixSummaryInfo[1]["ascending"] || props.matrixSummaryInfo[1]["descending"])
                   ? 
                   <Collapse defaultActiveKey={['3']} ghost>
                        <Panel header="Set Data Fields" key="3" style = {{textAlign : "left", fontWeight : "bolder"}}>
                            <div style = {{textAlign: "left", fontWeight : "bolder"}}>
                                    <Select
                                        showSearch
                                        value = {props.matrixSummaryInfo[2] ? props.matrixSummaryInfo[2].col : null}
                                        bordered = {false}
                                        dropdownStyle = {{textTransform: "capitalize"}}
                                        onChange = {(value) => onChange(value, "3")}
                                        style={{ width: "40%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize", marginRight: "0.5rem"}}
                                        placeholder="Search to Select Subtotal Parameter"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {
                                            props.matrixDataColumns.map((col) => {

                                                return(
                                                    <Option key = {col} value={col}>{col.split('_').join(" ").toLowerCase()}</Option>
                                                );
                                                
                                            })
                                        }
                                    </Select>
                                    {
                                        props.matrixSummaryInfo[2]
                                        ?
                                        <Select
                                        bordered = {false}
                                        value = {props.matrixSummaryInfo[2] ? props.matrixSummaryInfo[2].function : null}
                                        dropdownStyle = {{textTransform: "capitalize"}}
                                        onChange = {(value) => onFuncChange(value)}
                                        style={{ width: "20%" , backgroundColor : "white", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", textTransform: "capitalize"}}
                                        placeholder="Select Fucntion"
                                        optionFilterProp="children"
                                    >
                                       <Option key = "COUNT" value="COUNT">Count</Option>
                                       <Option key = "SUM" value="SUM">Sum</Option>
                                       <Option key = "AVG" value="AVG">Average</Option>

                                    </Select>
                                        :
                                        null
                                    }
                                </div>
                        </Panel>
                    </Collapse>
                    :
                    null
                }                
            </div>
        </Scrollbars>
               
    );
}

export default MatrixGroupings