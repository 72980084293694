import { Tabs } from 'antd';
import classes from './ViewEditor.module.css';
import {BsTable} from 'react-icons/bs';
import {useContext} from 'react';
import DataContext from '../../Context/dataContext';
import {GiCardExchange} from 'react-icons/gi';
import {FaFilter, FaInfoCircle} from 'react-icons/fa';
import ReportType from '../ReportType/ReportType';
import ReportRepresentation from '../ReportRepresentation/ReportRepresentation';
import BasicInformation from '../BasicInformation/BasicInformation';
import Filter from '../Filter/Filter';

const { TabPane } = Tabs;

const ViewEditor = (props) => {

    const mainData = useContext(DataContext)

    const tabClickHandler = (key, event) => {

        if(key === "1") mainData.setAKey("2")

        if(key === "2" && mainData.reportType !== "matrix") mainData.setAKey("3")

        if(key === "2" && mainData.reportType === "matrix") mainData.setAKey("5")

        if(key === "3") mainData.setAKey("6")
    }

    return(
        <div className = {classes['EditView']}>
            <Tabs onTabClick = {(key, event) => tabClickHandler(key,event)} defaultActiveKey="1" centered style = {{ height: "100%"}}>
                <TabPane tab= {<span className = {mainData.aKey === "1" && classes['Pane']}><BsTable/> Report Type</span>} key="1">
                    <ReportType />
                </TabPane>
                {
                    mainData.reportType === ""
                    ?
                    null
                    :
                    <>
                        <TabPane  tab= {<span className = {mainData.aKey === "2" && classes['Pane']} ><GiCardExchange/> Report Representation</span>} key="2">
                            <ReportRepresentation matrixColumns = {props.matrixColumns} matrixDataColumns = {props.matrixDataColumns} metaData = {props.metaData} parentColumns = {props.parentColumns} columns = {props.columns} />
                        </TabPane>
                        <TabPane tab= {<span className = {mainData.aKey === "5" && classes['Pane']} ><FaFilter/> Filters</span>} key="3" >
                            <Filter metaData = {props.metaData} matrixColumns = {props.matrixColumns} matrixDataColumns = {props.matrixDataColumns} parentColumns = {props.parentColumns} columns = {props.columns} />
                        </TabPane>
                        <TabPane tab= {<span className = {mainData.aKey === "6" && classes['Pane']} ><FaInfoCircle/> Basic Information</span>} key="4" >
                            <BasicInformation />
                        </TabPane>
                    </>
                }
            </Tabs>
        </div>
    );
}

export default ViewEditor