import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import DesignationEdit from './DesignationEdit';

const Designation = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                        <DataField editMode = {false} lg = {10} md = {24}  options = {props.ad.EMPLOYEE_DESIG_CODE} type = "Select" name = "DESIGNATION" value = {data.DESIGNATION_CODE}/> 
                        <DataField editMode = {false} lg = {7} md = {24} name = "JOINING DATE" value = {data.DATE_JOIN ? data.DATE_JOIN.format('DD-MM-YYYY') : null}/> 
                        <DataField editMode = {false} lg = {7} md = {24} name = "RELEASE DATE" value = {!data.DATE_RELEASE ? null : data.DATE_RELEASE.format('DD-MM-YYYY')}/>         
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <DesignationEdit desigOptions = {props.ad.EMPLOYEE_DESIG_CODE} data = {props.data} setData = {props.setData} />
        </>
    );
}

export default Designation