import classes from '../Pages.module.css';
import {Row,Col, Skeleton} from 'antd';
import { useState, useEffect, useContext} from 'react';
import ToolRow from '../ToolRow/ToolRow';
import axios from 'axios';
import DataContext from '../../../Context/dataContext';
import CustomerTable from '../CustomerTable/CustomerTable';

const Sauda = (props) => {

    const customerData = useContext(DataContext)
    const [columns, setColumns] = useState([])
    const [allRows, setAllRows] = useState(null)
    const [pendingRows, setPendingRows] = useState(null)
    const [completedRows, setCompletedRows] = useState(null)
    const [type, setType] = useState('pending');
    const [ft, setFT] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [dFilter, setDFilter] = useState("all")
    const [typeChanged, setTypeChanged] = useState(true)

    useEffect(() => {

        if(dFilter !== "all"){
            if(dFilter !== "fromTo")
            {
                setFT(false)
                setToDate("")
                setFromDate("")
                setColumns([])
                setAllRows(null)
                setCompletedRows(null)
                setPendingRows(null)
                axios
                .get(customerData.URL + '/api/v1/customer/sauda',{
                    withCredentials: true
                })
                .then((response) => {

                    console.log(response);

                    setColumns((columns) => {
                        let newCols =  response.data.data.allSauda.metaData.map((col) => {
                            return({
                                name: col.name,
                                title: col.name.split("_").join(" ").toLowerCase()
                            });
                        })

                        const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                        return newNewCols;
                    })

                    setAllRows(rows => {
                        let newRows = []
                        return newRows;
                    })

                    setCompletedRows(rows => {
                        let newRows = []
                        return newRows;
                    })

                    setPendingRows(rows => {
                        let newRows = []
                        return newRows;
                    })

                })
                .catch((error) => {
                    console.log(error);
                })
            }

            else{
                setFT(true)
                if(fromDate !== "" && toDate !== ""){
            
                }
            }
        }

    }, [dFilter, fromDate, toDate])

    useEffect(() => {  

        if(dFilter === "all"){

            setFT(false);
            setToDate("")
            setFromDate("")
            setColumns([])
            setAllRows(null)
            setCompletedRows(null)
            setPendingRows(null)
            axios
            .get(customerData.URL + '/api/v1/customer/sauda',{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);
                setColumns((columns) => {
                    let newCols =  response.data.data.allSauda.metaData.map((col) => {
                        return({
                            name: col.name,
                            title: col.name.split("_").join(" ").toLowerCase()
                        });
                    })

                    const newNewCols = [{name: "SNO", title: "SNo"}, ...newCols]
                    return newNewCols;
                })

                setAllRows(rows => {
                    let newRows = response.data.data.allSauda.rows.map((row,index) => {return(
                        {
                            "SNO": index + 1,
                            ...row
                        }
                    );})
                    return newRows;
                })

                setPendingRows(rows => {
                    let newRows = response.data.data.pendingSauda.rows.map((row,index) => {return(
                        {
                            "SNO": index + 1,
                            ...row
                        }
                    );})
                    return newRows;
                })

                setCompletedRows(rows => {
                    let newRows = response.data.data.completedSauda.rows.map((row,index) => {return(
                        {
                            "SNO": index + 1,
                            ...row
                        }
                    );})
                    return newRows;
                })

            })
            .catch((error) => {
                console.log(error);
            })
        }      
        
    }, [dFilter])

    const handleClick = (event, val) => {
        setTypeChanged(false)
        setType(val)
        setTypeChanged(true)
    }

    const onDChange = (value, param) => {

        setDFilter(value)
    }

    const onDateChange = (date, dateString) => {
        
        setFromDate(dateString[0])
        setToDate(dateString[1])

    }

    return(
        <>            
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Sauda</p></Col>
                <Col className = {classes['Col']} md = {10}>
                    <Row>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "all")} className = {type === "all" ? classes['PageButton'] : classes['PageButton2']}>All</button></Col>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "pending")} className = {type === "pending" ? classes['PageButton'] : classes['PageButton2']}>Pending</button></Col>
                        <Col md = {8}><button onClick = {(event) => handleClick(event, "completed")} className = {type === "completed" ? classes['PageButton'] : classes['PageButton2']}>Completed</button></Col>
                    </Row>
                </Col>
            </Row>
            <p></p>
            <ToolRow ft = {ft} fromDate = {fromDate} toDate = {toDate} onDateChange = {onDateChange} dFilter = {dFilter} onDChange = {onDChange}/>
            <p></p>
            {
                typeChanged && allRows && completedRows && pendingRows && columns.length > 0
                ?
                <>
                    {type === "all" && <CustomerTable data = {allRows} columns = {columns} />}
                    {type === "pending" && <CustomerTable data = {pendingRows} columns = {columns} />}
                    {type === "completed" && <CustomerTable data = {completedRows} columns = {columns} />}
                </>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default Sauda