import classes from '../Pages.module.css';
import {Row,Col, Avatar, Tabs, Modal, message, Input, Button, Form, Skeleton} from 'antd';
import { useState, useEffect, useContext} from 'react';
import VendorDetails from './VendorDetails';
import ItemDetails from './ItemDetails';
import SiteDetails from './SiteDetails';
import BrokerDetails from './BrokerDetails';
import {FaLinkedin, FaFacebook} from 'react-icons/fa';
import {RiWhatsappFill} from 'react-icons/ri';
import {Link} from 'react-router-dom';
import DataContext from '../../../Context/dataContext';
import axios from 'axios';
import moment from 'moment';
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory } from 'react-router';

const { TabPane } = Tabs;

const Analysis = (props) => {

    const vendorData = useContext(DataContext)
    const history = useHistory()

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [editMode, setEditMode] = useState(false)
    const [allData, setAllData] = useState(null)
    const [storeData, setStoreData] = useState(null)
    const [ad, setAd] = useState(null)
    const [password, setPassword] = useState({
        "currentPassword": "",
        "password": "",
        "passwordConfirm": ""
    })

    useEffect(() => {

        setStoreData(null)
        setAd(null)
        setAllData(null)

        axios
            .get(vendorData.URL + '/api/v1/vendor/additional-data',{
                withCredentials: true
            })
            .then((response) => {
                console.log(response);  
                setAd(ad => {
                    const newData = response.data.data

                    return newData
                })                 
            })
            .catch((error) => {
                console.log(error);
            })

        axios
            .get(vendorData.URL + '/api/v1/vendor/profile',{
                withCredentials: true
            })
            .then((response) => {

                console.log(response);
                setAllData(allData => {
                    let newDetails = response.data.data
                    let newitem = response.data.data.itemDetails.rows.map(dep => {
                        return(
                            {
                                ...dep,
                                APPROVAL_DATE: dep.APPROVAL_DATE ? moment(dep.APPROVAL_DATE, 'DD-MM-YYYY') : null,
                            }
                        )
                    })
                    return ({
                        ...newDetails,
                        itemDetails: {
                            ...response.data.data.itemDetails,
                            rows: newitem
                        }
                    })
                })   
                
                setStoreData(storeData => {
                    let newDetails = response.data.data
                    let newitem = response.data.data.itemDetails.rows.map(dep => {
                        return(
                            {
                                ...dep,
                                APPROVAL_DATE: dep.APPROVAL_DATE ? moment(dep.APPROVAL_DATE, 'DD-MM-YYYY') : null,
                            }
                        )
                    })
                    return ({
                        ...newDetails,
                        itemDetails: {
                            ...response.data.data.itemDetails,
                            rows: newitem
                        }
                    })
                })   
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const showModal = (event) => {
        setVisible(true);
    };

    const handleOk = () => {

        console.log(password);
        setConfirmLoading(true)
        axios
            .patch(vendorData.URL + '/api/v1/users/changePassword', password,  {
                withCredentials: true,
                credentials: 'include',
            })
            .then((response) => {
                message.success({
                    content: 'Password Changed Successfully!!!!',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                setConfirmLoading(false);  
                setVisible(false)                      
            })
            .catch((err) => {
                setConfirmLoading(false);
                // console.log(err.response);
                message.error({
                    content: err.response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            });
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        setPassword({
            "currentPassword": "",
            "password": "",
            "passwordConfirm": ""
        })
    };

    
    const onChange = (e, param) => {
        if(param === "currentPassword") setPassword(prevState => ({
            ...prevState,
            currentPassword: e.target.value
        }))
        if(param === "password") setPassword(prevState => ({
            ...prevState,
            password: e.target.value
        }))
        if(param === "passwordConfirm") setPassword(prevState => ({
            ...prevState,
            passwordConfirm: e.target.value
        }))
    }

    const handleSave = (event) => {

        var formv = true
        let count = 0;
        allData.siteDetails.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In SITE DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })

        count = 0;
        allData.brokerDetails.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In BROKER DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })

        count = 0;
        allData.itemDetails.rows.forEach(data => {
            
            if(!data){

                formv = formv&&false
                if(count === 0){
                    message.error({
                        content: 'Empty Fields In ITEM DETAILS Tab!!!',
                        className: 'custom-class',
                        style: {
                            marginTop: '1vh',
                        },
                    });
                }

                count = count + 1;
            }
        })
        
        if(formv){

            console.log("Submitting");                                                                                                                  
           
            const postData = {
                "vendorDetails": allData.vendorDetails,
                "itemDetails": allData.itemDetails.rows.map(dep => {
                    return(
                        {
                            ...dep,
                            APPROVAL_DATE: dep.APPROVAL_DATE ? dep.APPROVAL_DATE.format('DD-MM-YYYY') : null,
                        }
                    )
                }),
                "siteDetails": allData.siteDetails.rows,
                "brokerDetails": allData.brokerDetails.rows,
            }

            const postDataS = {
                "vendorDetails": storeData.vendorDetails,
                "itemDetails": allData.itemDetails.rows.map(dep => {
                    return(
                        {
                            ...dep,
                            APPROVAL_DATE: dep.APPROVAL_DATE ? dep.APPROVAL_DATE.format('DD-MM-YYYY') : null,
                        }
                    )
                }),
                "siteDetails": storeData.siteDetails.rows,
                "brokerDetails": storeData.brokerDetails.rows,
            }
            
            postData.itemDetails.forEach((lang,index) => {

                if(!lang.UNIQUE_ID){
                    postData.itemDetails[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.itemDetails[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.itemDetails.forEach((lang,index) => {
                const dataIndex = postData.itemDetails.findIndex(element => element.UNIQUE_ID === lang.UNIQUE_ID)
                if(dataIndex === -1){
                    postData.itemDetails.push({
                        UNIQUE_ID: lang.UNIQUE_ID,
                        PARAM: "DELETE"
                    })
                }
            })

            postData.brokerDetails.forEach((lang,index) => {

                if(!lang.BROKER_CODE){
                    postData.brokerDetails[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.brokerDetails[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.brokerDetails.forEach((lang,index) => {
                const dataIndex = postData.brokerDetails.findIndex(element => element.BROKER_CODE === lang.BROKER_CODE)
                if(dataIndex === -1){
                    postData.brokerDetails.push({
                        BROKER_CODE: lang.BROKER_CODE,
                        PARAM: "DELETE"
                    })
                }
            })

            postData.siteDetails.forEach((lang,index) => {

                if(!lang.DEL_SITE_CODE){
                    postData.siteDetails[index] = {
                        ...lang,
                        PARAM: "INSERT"
                    }
                }
    
                else{
                    postData.deliverySites[index] = {
                        ...lang,
                        PARAM: "UPDATE"
                    } 
                }
            })
    
            postDataS.siteDetails.forEach((lang,index) => {
                const dataIndex = postData.siteDetails.findIndex(element => element.DEL_SITE_CODE === lang.DEL_SITE_CODE)
                if(dataIndex === -1){
                    postData.siteDetails.push({
                        DEL_SITE_CODE: lang.DEL_SITE_CODE,
                        PARAM: "DELETE"
                    })
                }
            })
            
            console.log(JSON.stringify(postData, undefined, 2));
            setLoading(true)

            axios
              .patch(vendorData.URL + '/api/v1/vendor/profile', postData,  {
                  withCredentials: true,
                  credentials: 'include',
              })
              .then((response) => {
                  message.success({
                      content: 'Vendor Updated Successfully!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '2vh',
                      },
                  });
                  setLoading(false)
                  history.replace('/vendor-portal/profile')
              })
              .catch((err) => {
                  message.error({
                      content: 'An Error Occurred!!!!',
                      className: 'custom-class',
                      style: {
                          marginTop: '2vh',
                      },
                  });
                  setLoading(false)
              });
          }
    }

    const handleCancelSave = (event) => {
        
        setAllData(storeData)
        setEditMode(false)
    }

    return(
        <>
            <Modal
                title= "Change Password"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form
                    layout="vertical"
                >
                    <Form.Item 
                        label = {<div className={classes['Label']}>Current Password</div>}
                    >
                        <Input 
                            placeholder="Enter Current Password"
                            className = {classes["Input"]}
                            value = {password.currentPassword}
                            onChange = {(event) => onChange(event, "currentPassword")}
                        />
                    </Form.Item>
                    <Form.Item 
                        label = {<div className={classes['Label']}>New Password</div>}
                    >
                        <Input 
                            placeholder="Enter New Password"
                            className = {classes["Input"]}
                            value = {password.password}
                            onChange = {(event) => onChange(event, "password")}
                        />
                    </Form.Item>
                    <Form.Item 
                        label = {<div className={classes['Label']}>Confirm Password</div>}
                    >
                        <Input 
                            placeholder="Confirm Password"
                            className = {classes["Input"]}
                            value = {password.passwordConfirm}
                            onChange = {(event) => onChange(event, "passwordConfirm")}
                        />
                    </Form.Item>
                    
                </Form>

            </Modal>
            {
                ad && allData && storeData
                ?

                <Row className = {classes['RowP']}>
                    <Col md = {16} className = {classes['Col']}>
                        <div className = {classes['EditView']}>
                            <Tabs defaultActiveKey="1" centered style = {{ height: "100%"}}>
                                <TabPane tab= {<span>Vendor Details</span>} key="1">
                                    <VendorDetails ad = {ad} data = {allData.vendorDetails} setData = {setAllData} editMode = {editMode} />
                                </TabPane>
                                <TabPane tab= {<span>Item Details</span>} key="2">
                                    <ItemDetails ad = {ad} editMode = {editMode} data = {allData.itemDetails.rows} setData = {setAllData}/>
                                </TabPane>
                                {
                                    editMode
                                    ?
                                    null
                                    :
                                    <>
                                        <TabPane tab= {<span>Site Details</span>} key="3">
                                            <SiteDetails ad = {ad} editMode = {editMode} data = {allData.siteDetails.rows} setData = {setAllData}/>
                                        </TabPane>
                                        <TabPane tab= {<span>Broker Details</span>} key="4">
                                            <BrokerDetails ad = {ad} editMode = {editMode} data = {allData.brokerDetails.rows} setData = {setAllData}/>
                                        </TabPane>
                                    </>
                                }
                            </Tabs>
                        </div>
                    </Col>
                    <Col md = {8} className = {classes['Col']}>
                        <p></p>
                        <Avatar
                            size={{
                            xs: 24,
                            sm: 32,
                            md: 80,
                            lg: 84,
                            xl: 100,
                            xxl: 100,
                            }}
                            style={{ color: 'white', backgroundColor: '#A2C4C6', margin: "3rem 0rem 1rem 0rem"}}
                        >{allData.vendorDetails.PARTY_NAME[0]}</Avatar> 
                        <p></p>
                        <p className = {classes['Name']} >{allData.vendorDetails.PARTY_NAME}</p>
                        <p></p>
                        <Row className = {classes['RowS']}>
                            <Col lg = {4} md = {5} style = {{fontSize: "1.2rem"}}><Link to = "#"><FaLinkedin style = {{color: "#0077b5"}} /></Link></Col>
                            <Col lg = {4} md = {5} style = {{fontSize: "1.2rem"}}><Link to = "#"><FaFacebook style = {{color: "#4267B2"}} /></Link></Col>
                            <Col lg = {4} md = {5} style = {{fontSize: "1.2rem"}}><Link to = "#"><RiWhatsappFill style = {{color: "#128C7E"}} /></Link></Col>
                        </Row>
                        <p></p>
                        {
                            editMode
                            ?
                            <>  
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#D0F0C0", color: "#234F1E"}} onClick = {(event) => handleSave(event)} className = {classes['ProfileButton']}>Save</button>
                                }
                                {
                                    loading
                                    ?
                                    <SyncLoader color = {"rgba(255,163,77,0.8)"} size = {10}/>
                                    :
                                    <button style = {{backgroundColor: "#FC9483", color: "#640000"}} onClick = {(event) => handleCancelSave(event)} className = {classes['ProfileButton']}>Cancel</button>
                                }
                                
                            </>
                            :
                            <>
                                <button onClick = {() => setEditMode(true)} className = {classes['ProfileButton']}>Edit Profile</button>
                                <button style = {{backgroundColor: "#CDB7F6", color: "#000028"}} onClick={(event) => showModal(event)} className = {classes['ProfileButton']}>Change Password</button>
                            </>
                        }      
                    </Col>
                </Row>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
        </>
    );
}

export default Analysis