import classes from './FieldData.module.css'
import { Switch, Route } from 'react-router-dom';
import AllReports from '../AllReports/AllReports';
import Sales from '../Sales/Sales';
import SchedulerView from '../FormRequest/SchedulerView';
import Scheduler from '../FormRequest/Scheduler';

import SchedulerCreate from '../FormRequest/SchedulerCreate';

const FieldData = (fieldProps) => {

    return(
        <div className={classes['Fields']}>
            <Switch>
                <Route path="/reports" exact 
                    render={(props) => (
                        <AllReports {...props} editModeHandler = {fieldProps.editModeHandler} onTitleChangeHandler = {fieldProps.onTitleChangeHandler} onModuleChangeHandler = {fieldProps.onModuleChangeHandler} />
                    )}
                />
                <Route path="/reports/:cat/:id/:edit?" 
                    render={(props) => (
                        <Sales {...props} set = {fieldProps.set} company = {fieldProps.company} site = {fieldProps.site} editMode = {fieldProps.editMode} editModeHandler = {fieldProps.editModeHandler} onTitleChangeHandler = {fieldProps.onTitleChangeHandler} onModuleChangeHandler = {fieldProps.onModuleChangeHandler} />
                    )}
                />

               <Route path = "/reports/scheduler" exact
            
               render={(props) => (
                <Scheduler {...props} />
            )}
            
                />

                <Route path="/reports/sheduler-form-view/:id" exact 
                render={(props) => (
                    <SchedulerView {...props} />
                )}
            />
             <Route path="/reports/scheduler-form-create" exact 
                render={(props) => (
                    <SchedulerCreate {...props} />
                )}
            />
        
            </Switch>
        </div>
    );
}

export default FieldData;