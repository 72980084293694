import {Row, Col, Form, Input, Space, Button, Select} from 'antd';
import classes from '../Pages.module.css';
import { MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import SalaryAdvanceDataFeild from './SalaryAdvanceDataFeild';

const { Option } = Select;

const AdvanceFormDetails = (props) => {

    const handleValueChanges = (val, allValues) => {
        props.setData(data => {

            return({
                ...data,
                details: {
                    ...data.details,
                    rows: allValues.salaryAdvance
                }
            })
        })
    }

    return(
            props.editMode
            ?
            <Form layout="vertical" name="filter_form" onValuesChange = {handleValueChanges} autoComplete="off">
                <Form.List name="salaryAdvance" initialValue = {props.data}>
                    {(fields, { add, remove }) => { 
                        
                        return(
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField}) => (
                            <Space key={key} className = {classes['Space']} align="center">
                                <Row gutter = {16}>
                                    <p></p>
                                    <Col lg = {6} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'PAID_MONTH']}
                                            fieldKey={[fieldKey, 'PAID_MONTH']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DEDUCTED MONTH</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                            {
                                                props.ad.ADV_MONTH.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.ADV_MONTH.metaData[0].name]} value={option[props.ad.ADV_MONTH.metaData[0].name]}>{option[props.ad.ADV_MONTH.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col lg = {6} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'PAID_YEAR']}
                                            fieldKey={[fieldKey, 'PAID_YEAR']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DEDUCTED YEAR</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                            {
                                                props.ad.ADV_YEAR.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.ADV_YEAR.metaData[0].name]} value={option[props.ad.ADV_YEAR.metaData[0].name]}>{option[props.ad.ADV_YEAR.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {6} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'AMOUNT']}
                                            fieldKey={[fieldKey, 'AMOUNT']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>AMOUNT</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Input
                                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                bordered = {false}
                                                placeholder="Enter Amount"
                                            />
                                        </Form.Item>
                                    </Col>
                                   
                                    <Col lg = {21} md = {0}>
                                        
                                    </Col>
                                    <Col lg = {1} md = {24}>
                                        <MinusCircleOutlined className = {classes['Remove']} onClick={() => { remove(name) }} />
                                    </Col>
                                    <Col lg = {1} md = {24}>
                                        <PlusCircleOutlined className = {classes['Add']} onClick={() => {add()}} />
                                    </Col>
                                    <Col lg = {1} md = {24}>
                                        
                                    </Col>
                                    <Col lg = {24} md = {24}>
                                        <hr></hr>
                                    </Col>
                                </Row>                                                     
                            </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" className = {classes['DashedButton']}onClick={() => {add()}}>
                                    Add Salary Advance
                                </Button>
                            </Form.Item>
                        </>
                    )}}
                </Form.List>
            </Form>
            :
            <>
                {
                    props.data.map((element, index) => {
                        return(
                            <div key = {index}>
                                <strong>Salary Advance {index + 1}</strong>
                                <p></p>
                                <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                                    <SalaryAdvanceDataFeild editMode = {false} lg = {12} md = {24} options = {props.ad.ADV_MONTH} type = "Select" name = "PAID MONTH" value = {props.data.ADV_MONTH ? props.data.ADV_MONTH : null}/> 
                                    <SalaryAdvanceDataFeild editMode = {false} lg = {12} md = {24}  options = {props.ad.ADV_YEAR} type = "Select" name = "PAID YEAR" value = {props.data.ADV_YEAR ? props.data.ADV_YEAR : null}/> 
                                    <SalaryAdvanceDataFeild editMode = {false} lg = {12} md = {24} name = "AMOUNT" value = {element.AMOUNT ? element.AMOUNT : null}/> 
                                </Row>  
                                <p></p>
                                <hr></hr>
                            </div>
                        );
                    })
                }
            </>
    )
}

export default (AdvanceFormDetails);