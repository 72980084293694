import {Row,Col, Form, Select} from 'antd'
import classes from '../Pages.module.css';

const { Option } = Select;

const DAForm = (props) => {

    const handleSChange = (e,param) => {
        props.setForm(form => {
            let newform = form;
            newform[param] = e
            return({
                ...form,
                ...newform
            });
        })
    }

    return(
        <>
            <Form
                layout = "vertical">
                <Row gutter = {16} style = {{margin: "0.5rem"}} className = { classes['RowDEX']}>
                    <Col lg = {6} md = {12}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >LEAVE TYPE</div>}
                        >
                                <Select
                                    allowClear = {true}
                                    value = {props.form.LEAVE_TYPE}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "LEAVE_TYPE")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.leaveList.rows && props.leaveList.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.leaveList.metaData[0].name]} value={option[props.leaveList.metaData[0].name]}>{option[props.leaveList.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>      
                    <Col lg = {6} md = {12}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >LEAVE TYPE 2</div>}
                        >
                                <Select
                                    allowClear = {false}
                                    value = {props.form.LEAVE_TYPE2}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "LEAVE_TYPE2")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.leaveList.rows && props.leaveList.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.leaveList.metaData[0].name]} value={option[props.leaveList.metaData[0].name]}>{option[props.leaveList.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>   
                    <Col lg = {6} md = {12}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >LEAVE PERIOD</div>}
                        >
                               <Select
                                allowClear = {true}
                                    value = {props.form.LEAVE_PERIOD}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "LEAVE_PERIOD")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.periodList.rows && props.periodList.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.periodList.metaData[0].name]} value={option[props.periodList.metaData[0].name]}>{option[props.periodList.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>   
                    <Col lg = {6} md = {12}>
                        <Form.Item 
                            colon = {false}
                            style = {{margin: "0", padding: "0"}}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >LEAVE PERIOD 2</div>}
                        >
                                <Select
                                    allowClear = {true}
                                    value = {props.form.LEAVE_PERIOD2}
                                    showSearch
                                    onChange = {(value) => handleSChange(value, "LEAVE_PERIOD2")}
                                    bordered = {false}
                                    dropdownStyle = {{textTransform: "capitalize"}}
                                    style={{ textAlign: "left", width: "100%" , backgroundColor : "white",  textTransform: "capitalize", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    
                                    {
                                        props.periodList.rows && props.periodList.rows.map((option) => {
                                            return(
                                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.periodList.metaData[0].name]} value={option[props.periodList.metaData[0].name]}>{option[props.periodList.metaData[1].name]}</Option>
                                            );
                                        })
                                    }
                                </Select>
                                    
                        </Form.Item>
                        <p></p>
                    </Col>              
                </Row>
            </Form>
        </>
    )
}

export default DAForm;