import {Row,Col,Skeleton} from 'antd'
import axios from 'axios';
import classes from '../Pages.module.css';
import { useState, useEffect, useContext} from 'react';
import DataContext from '../../../Context/dataContext';
import TransactionTable from './TransactionTable';
import SalaryForm from './SalaryForm'
import moment from 'moment';

const SalaryProcessing = (props) => {

    const employeeData = useContext(DataContext)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState(null)
    const [ad, setAD] = useState(null)
    const [form, setForm] = useState({
        ATTEND_CODE: null,
        SITE: 1,
        ATTEND_DATE: moment(new Date()).format("DD/MM/YYYY"),
        DEPARTMENT: null,
        EMP_TYPE: null,
    })

    const handleSave = (event) => {

        
    }

    return(
        <>
            <Row className = {classes['Row']}>
                <Col md = {14}><p className = {classes['Title']}>Salary Processing</p></Col>
                <Col className = {classes['Col']} md = {10}>
                  
                </Col>
                <Col className = {classes['Col']} md = {24}>
                  
                </Col>
            </Row>
            <SalaryForm />
            {/* {
                form && ad
                ?
                <DAForm ad = {ad} form = {form} setForm = {setForm} handleSave = {handleSave}/>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            }
            <p></p> */}
            <hr></hr>
            {/* <p></p>
            {
                ad && rows && columns.length > 0
                ?
                <TransactionTable ad = {ad} data = {rows} columns = {columns}/>
                :
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
            } */}
        </>
    );
}

export default SalaryProcessing