import {Row, Col, Form, Input, Space, Button, Select} from 'antd';
import classes from '../Pages.module.css';
import { MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import NormDataField from './NormDataField';

const { Option } = Select;

const SalaryNormDetails = (props) => {

    const handleValueChanges = (changedValues, allValues) => {

        props.setData(data => {

            return({
                ...data,
                details: {
                    ...data.details,
                    rows: allValues.salaryNorms
                }
            })
        })
    }

    return(
            props.editMode
            ?
            <Form layout="vertical" name="filter_form" onValuesChange = {handleValueChanges} autoComplete="off">
                <Form.List name="salaryNorms" initialValue = {props.data}>
                    {(fields, { add, remove }) => { 
                        
                        return(
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField}) => (
                            <Space key={key} className = {classes['Space']} align="center">
                                <Row gutter = {16}>
                                    <p></p>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'ALLOWANCE_CODE']}
                                            fieldKey={[fieldKey, 'ALLOWANCE_CODE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ALLOWANCE DESCRIPTION</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                            {
                                                props.ad.ALLOWANCE_CODE.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.ALLOWANCE_CODE.metaData[0].name]} value={option[props.ad.ALLOWANCE_CODE.metaData[0].name]}>{option[props.ad.ALLOWANCE_CODE.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'ALLOWANCE_VALUE']}
                                            fieldKey={[fieldKey, 'ALLOWANCE_VALUE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ALLOWANCE VALUE</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Input
                                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                bordered = {false}
                                                placeholder="Enter Data"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'ALLOWANCE_TYPE_CODE']}
                                            fieldKey={[fieldKey, 'ALLOWANCE_TYPE_CODE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ALLOWANCE TYPE</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                                {
                                                    props.ad.ALLOWANCE_TYPE.rows.map((option) => {
                                                        return(
                                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.ALLOWANCE_TYPE.metaData[0].name]} value={option[props.ad.ALLOWANCE_TYPE.metaData[0].name]}>{option[props.ad.ALLOWANCE_TYPE.metaData[1].name]}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'ALLOWANCE_EFFECT']}
                                            fieldKey={[fieldKey, 'ALLOWANCE_EFFECT']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ALLOWANCE EFFECT</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                                {
                                                    props.ad.ALLOWANCE_EFFECT.rows.map((option) => {
                                                        return(
                                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.ALLOWANCE_EFFECT.metaData[0].name]} value={option[props.ad.ALLOWANCE_EFFECT.metaData[0].name]}>{option[props.ad.ALLOWANCE_EFFECT.metaData[1].name]}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'REFERRED_ALLOWANCE_CODE']}
                                            fieldKey={[fieldKey, 'REFERRED_ALLOWANCE_CODE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>REFERRED ALLOWANCE DESC</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                                {
                                                    props.ad.REFERRED_ALLOWANCE_CODE.rows.map((option) => {
                                                        return(
                                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.REFERRED_ALLOWANCE_CODE.metaData[0].name]} value={option[props.ad.REFERRED_ALLOWANCE_CODE.metaData[0].name]}>{option[props.ad.REFERRED_ALLOWANCE_CODE.metaData[1].name]}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'REFERENCE_TYPE']}
                                            fieldKey={[fieldKey, 'REFERENCE_TYPE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>REFERRENCE TYPE</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                                {
                                                    props.ad.REFERENCE_TYPE.rows.map((option) => {
                                                        return(
                                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.REFERENCE_TYPE.metaData[0].name]} value={option[props.ad.REFERENCE_TYPE.metaData[0].name]}>{option[props.ad.REFERENCE_TYPE.metaData[1].name]}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'CONDITION_ALLOWANCE_CODE']}
                                            fieldKey={[fieldKey, 'CONDITION_ALLOWANCE_CODE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>CONDITION ALLOWANC DESC</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                           <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                                {
                                                    props.ad.CONDITION_ALLOWANCE_CODE.rows.map((option) => {
                                                        return(
                                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.CONDITION_ALLOWANCE_CODE.metaData[0].name]} value={option[props.ad.CONDITION_ALLOWANCE_CODE.metaData[0].name]}>{option[props.ad.CONDITION_ALLOWANCE_CODE.metaData[1].name]}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'CONDITION_SIGN']}
                                            fieldKey={[fieldKey, 'CONDITION_SIGN']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>CONDITION SIGN</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            
                                            <Select
                                                bordered = {false}
                                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                placeholder="Select"
                                                optionFilterProp="children"
                                            >
                                                {
                                                    props.ad.CONDITION_SIGN.rows.map((option) => {
                                                        return(
                                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.ad.CONDITION_SIGN.metaData[0].name]} value={option[props.ad.CONDITION_SIGN.metaData[0].name]}>{option[props.ad.CONDITION_SIGN.metaData[1].name]}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {8} md = {12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'CONDITION_VALUE']}
                                            fieldKey={[fieldKey, 'CONDITION_VALUE']}
                                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>CONDITION VALUE</div>}
                                            rules={[{ required: true, message: 'Missing Name' }]}
                                        >
                                            <Input
                                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                                bordered = {false}
                                                placeholder="Enter Data"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg = {21} md = {0}>
                                        
                                    </Col>
                                    <Col lg = {1} md = {24}>
                                        <MinusCircleOutlined className = {classes['Remove']} onClick={() => { remove(name) }} />
                                    </Col>
                                    <Col lg = {1} md = {24}>
                                        <PlusCircleOutlined className = {classes['Add']} onClick={() => {add()}} />
                                    </Col>
                                    <Col lg = {1} md = {24}>
                                        
                                    </Col>
                                    <Col lg = {24} md = {24}>
                                        <hr></hr>
                                    </Col>
                                </Row>                                                     
                            </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" className = {classes['DashedButton']}onClick={() => {add()}}>
                                    Add Salary Norm
                                </Button>
                            </Form.Item>
                        </>
                    )}}
                </Form.List>
            </Form>
            :
            <>
                {
                    props.data.map((element, index) => {
                        return(
                            <div key = {index}>
                                <strong>SALARY NORM {index + 1}</strong>
                                <p></p>
                                <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                                    <NormDataField editMode = {false} lg = {24} md = {24} options = {props.ad.ALLOWANCE_CODE} type = "Select" name = "ALLOWANCE DESCRIPTION" value = {element.ALLOWANCE_CODE ? element.ALLOWANCE_CODE : null}/>
                                    <NormDataField editMode = {false} lg = {12} md = {24} name = "ALLOWANCE VALUE" value = {element.ALLOWANCE_VALUE ? element.ALLOWANCE_VALUE : null}/> 
                                    <NormDataField editMode = {false} lg = {12} md = {24} options = {props.ad.ALLOWANCE_TYPE} type = "Select" name = "ALLOWANCE TYPE" value = {element.ALLOWANCE_TYPE_CODE ? element.ALLOWANCE_TYPE_CODE : null}/> 
                                    <NormDataField editMode = {false} lg = {12} md = {24} options = {props.ad.ALLOWANCE_EFFECT} type = "Select" name = "ALLOWANCE EFFECT" value = {element.ALLOWANCE_EFFECT ? element.ALLOWANCE_EFFECT : null}/> 
                                    <NormDataField editMode = {false} lg = {12} md = {24} options = {props.ad.CONDITION_ALLOWANCE_CODE} type = "Select" name = "CONDITION ALLOWANCE DESC" value = {element.CONDITION_ALLOWANCE_CODE ? element.CONDITION_ALLOWANCE_CODE : null}/> 
                                    <NormDataField editMode = {false} lg = {12} md = {24} options = {props.ad.CONDITION_SIGN} type = "Select" name = "CONDITION SIGN" value = {element.CONDITION_SIGN ? element.CONDITION_SIGN : null}/> 
                                    <NormDataField editMode = {false} lg = {12} md = {24} name = "CONDITION VALUE" value = {element.CONDITION_VALUE ? element.CONDITION_VALUE : null}/>   
                                    <NormDataField editMode = {false} lg = {12} md = {24} options = {props.ad.REFERRED_ALLOWANCE_CODE} type = "Select" name = "REFERRED ALLOWANCE DESC" value = {element.REFERRED_ALLOWANCE_CODE ? element.REFERRED_ALLOWANCE_CODE : null}/> 
                                    <NormDataField editMode = {false} lg = {12} md = {24} options = {props.ad.REFERENCE_TYPE} type = "Select" name = "REFERRENCE TYPE" value = {element.REFERENCE_TYPE ? element.REFERENCE_TYPE : null}/>   
                                </Row>
                                <hr></hr>  
                                <p></p>
                            </div>
                        );
                    })
                }
            </>
    )
}

export default (SalaryNormDetails)