import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const BasicDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['salesOrder']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                salesOrder : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['salesOrder']]
            newdata[0][param] = val
            return({
                ...data,
                salesOrder : newdata
            })
        })
    }
    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['salesOrder']]
            newdata[0][param] = dateString
            return({
                ...data,
                salesOrder : newdata
            })
        })
    }



    return(
        
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                { !props.create && <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CUSTOMER CODE" param = "ORDER CODE" value = {props.data.ORDER_CODE}/> }
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.DISTRIBUTOR_NAME} type = "Select" name = "CUSTOMER NAME" param = "DISTRIBUTOR_NAME" value = {props.data.DISTRIBUTOR_NAME}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} name = "BOOKING DATE" type="Date" param = "BOOKING_DATE" value = {props.data.BOOKING_DATE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.DEALER_NAME} type = "Select" name = "DEALER NAME" param = "DEALER_NAME" value = {props.data.DEALER_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ORDER_TYPE} type = "Select" name = "ORDER TYPE" param = "ORDER_TYPE" value = {props.data.ORDER_TYPE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.INVOICE_TYPE_CODE} type = "Select" name = "INVOICE TYPE" param = "INVOICE_TYPE_CODE" value = {props.data.INVOICE_TYPE_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT DAYS" param = "PAYMENT_DAYS" value = {props.data.PAYMENT_DAYS}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.DEL_SITE_CODE} type = "Select" name = "SITE DELIVERY" param = "DEL_SITE_CODE" value = {props.data.DEL_SITE_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.AUTH_STATUS} type = "Select" name = "AUTH STATUS" param = "AUTH_STATUS" value = {props.data.AUTH_STATUS}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.FREIGHT_TYPE_CODE} type = "Select" name = "FREIGHT TYPE" param = "FREIGHT_TYPE_CODE" value = {props.data.FREIGHT_TYPE_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} name = "CUSTOMER PO DATE" type="Date" param = "CUSTOMER_PO_DATE" value = {props.data.CUSTOMER_PO_DATE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CUSTOMER PO NUMBER" type="str" param = "CUSTOMER_PO_NO" value = {props.data.CUSTOMER_PO_NO}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.BROKER_CODE} type = "Select" name = "BROKER NAME" param = "BROKER_CODE" value = {props.data.BROKER_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.PAYMENT_CODE} type = "Select" name = "PAYMENT" param = "PAYMENT_CODE" value = {props.data.PAYMENT_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TOLERANCE"  param = "TOLERANCE" value = {props.data.TOLERANCE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT AMOUNT"  param = "PAYMENT_AMT" value = {props.data.PAYMENT_AMT}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "REMARKS" type="str"  param = "REMARKS" value = {props.data.REMARKS}/> 
               
            </Row>
            
            
        </div>
    );
}

export default BasicDetails