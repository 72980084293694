import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import BoonkigSizeEdit from './BookingSizeEdit';

const BookingSize = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data, index) => {
            return(
                <div key = {index}>
                    <p></p>
                    <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.ITEM_CODE} type = "Select" name = "ITEM" value = {data.ITEM_CODE}/>
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.SIZE_CODE} type = "Select" name = "ITEM SIZE" value = {data.SIZE_CODE}/>
                        <DataField editMode = {false} lg = {12} md = {24} options = {props.ad.QUALITY} type = "Select" name = "QUALITY" value = {data.QUALITY}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "PCS NO" value = {data.NO_PCS}/> 
                        <DataField editMode = {false} lg = {12} md = {24} name = "QUANTITY" value = {data.QTY}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "ORDER RATE" value = {data.BOOK_RATE_GUAGE}/> 
                        <DataField editMode = {false} lg = {8} md = {24} options = {props.ad.DISCOUNT_ON} type = "Select" name = "DISCOUNT ON" value = {data.DISCOUNT_ON}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "DISCOUNT AMOUNT" value = {data.DISCOUNT_AMOUNT}/> 
                        <DataField editMode = {false} lg = {8} md = {24} options = {props.ad.DIS_TYPE} type = "Select" name = "DISCOUNT TYPE" value = {data.DIS_TYPE}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "ORDER AMT" value = {data.TOT_ITEM_AMT}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "NET RATE" value = {data.NET_RATE}/> 
                        <DataField editMode = {false} lg = {8} md = {24} name = "NET AMOUNT" value = {data.NET_SIZE_RATE}/> 
                            
                    </Row>  
                    <p></p>
                </div>
            );
        })
           
        :
        <>
            <p></p>
            <BoonkigSizeEdit 
            itemOptions = {props.ad.ITEM_CODE} 
            sizeOptions = {props.ad.SIZE_CODE} 
            qualityOptions = {props.ad.QUALITY} 
            options = {props.ad.DISCOUNT_ON} 
            options = {props.ad.DIS_TYPE} 
            data = {props.data} setData = {props.setData} />
        </>
    );
}

export default BookingSize;