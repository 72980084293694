import {Col, Form, Input,Row,Select,DatePicker,InputNumber} from 'antd';
import classes from '../Pages.module.css';
const {Option} = Select
const BasicDetails = (props) => {

 

  const handleChange = (e,param) => {

    props.setData(data => {
        const newdata = [...data['salesInvoice']]
        newdata = e.target.value
        return({
            ...data,
            salesInvoice : newdata
        })
    })
}
 
  return (
<>
   
    <Form
    layout = "vertical"
    autoComplete="off"
    onChange = {handleChange}
    name="salesInvoice"
    >
    <Row gutter={18}>
    <p></p>
    <Col lg = {8} md = {24}>
        <Form.Item 
        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >INVOICE TYPE</div>}
        >
        <Select
        bordered = {false}
        style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
        placeholder="Select"
        >
        {
          props.ad.BOOKING_INVOICE_CD.rows.map((option) => {
            return( 
           <Option  style = {{textTransform: "capitalize", color: "#1777C4"}}  key = {option[props.ad.BOOKING_INVOICE_CD.metaData[0].name]} value={option[props.ad.BOOKING_INVOICE_CD.metaData[0].name]}>{option[props.ad.BOOKING_INVOICE_CD.metaData[1].name]}</Option>
            );
          })
          }
          </Select>
        </Form.Item>
    </Col>
   
  
    <Col lg = {8} md = {24}>
       <Form.Item 
       style = {{margin: "0", padding: "0"}}
       label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >BOOKING DATE</div>}>
       <DatePicker 
       bordered = {false}
       style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
       />
       </Form.Item>

    </Col>
    <Col lg = {8} md = {24}>
       <Form.Item label="Select"
       label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >PLANNING/BOOKING_DET</div>}
       >
       <Select
       bordered = {false}
       style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
       placeholder="Select"
       >
       {
        props.ad.BOOKING_NO.rows.map((option) => {
          return( 
         <Option  style = {{textTransform: "capitalize", color: "#1777C4"}}  key = {option[props.ad.BOOKING_NO.metaData[0].name]} value={option[props.ad.BOOKING_NO.metaData[0].name]}>{option[props.ad.BOOKING_NO.metaData[0].name]}</Option>
          );
        })
      
       }
       </Select>
       </Form.Item>
    </Col>

    <Col lg = {8} md = {24}>
       <Form.Item label="Select"
       label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >DEALER NAME</div>}
       >
       <Select
       bordered = {false}
       style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
       placeholder="Select"
       >
       {
        props.ad.BOOKING_NO.rows.map((option) => {
          return( 
         <Option  style = {{textTransform: "capitalize", color: "#1777C4"}}  key = {option[props.ad.BOOKING_NO.metaData[0].name]} value={option[props.ad.BOOKING_NO.metaData[0].name]}>{option[props.ad.BOOKING_NO.metaData[0].name]}</Option>
          );
        })
      
       }
       </Select>
       </Form.Item>
    </Col>


    <Col lg = {8} md = {24}>
       <Form.Item 
       label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >INVOICE TYPE</div>}
       >
       <Select
       bordered = {false}
       style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
       placeholder="Select"
       >
       <Option  style = {{textTransform: "capitalize", color: "#1777C4"}} value="demo">Demo</Option>
       </Select>
       </Form.Item>
    </Col>

    <Col lg = {8} md = {24}>
        <Form.Item 
        style = {{margin: "0", padding: "0"}}
        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >INVOICE DATE</div>}>
       <DatePicker 
       bordered = {false}
       style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
       />
      </Form.Item>

    </Col>

    <Col lg = {8} md = {24}>
       <Form.Item
       name="GROSS_WT"
       colon = {false}
       style = {{margin: "0", padding: "0"}}
       label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >GROSS WEIGHT</div>}
       rules={[
       {
        required: true,
        message: 'Please input your gross weight!',
        },
             ]}
       >
       <InputNumber 
       bordered = {false}
       style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
       />
       </Form.Item>
    </Col>

  <Col lg = {8} md = {24}>
       <Form.Item
        name="TARE_WT"
        colon = {false}
        style = {{margin: "0", padding: "0"}}
        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >TARE WEIGHT</div>}
        rules={[
          {
            required: true,
            message: 'Please input your Tare Weight!',
          },
              ]}
        >
        <InputNumber 
        bordered = {false}
        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
        />
      </Form.Item>
    </Col>

    <Col lg = {8} md = {24}>
       <Form.Item
        name="NET_WEIGHT"
        colon = {false}
        style = {{margin: "0", padding: "0"}}
        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >NET WEIGHT</div>}
        rules={[
          {
            required: true,
            message: 'Please input your Net Weight!',
          },
              ]}
        >
        <InputNumber 
        bordered = {false}
        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
        />
      </Form.Item>
    </Col>

    <Col lg = {8} md = {24}>
       <Form.Item
        name="FACTORY_WEIGHT"
        colon = {false}
        style = {{margin: "0", padding: "0"}}
        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >FACTORY WEIGHT</div>}
        rules={[
          {
            required: true,
            message: 'Please input your Factory Weight!',
          },
              ]}
        >
        <InputNumber 
        bordered = {false}
        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
        />
      </Form.Item>
    </Col>
  
    <Col lg = {8} md = {24}>
         <Form.Item 
         name="BOOKING_DATE"
         style = {{margin: "0", padding: "0"}}
         label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >BOOKING S DATE</div>}>
        <DatePicker 
        bordered = {false}
        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
        />
        </Form.Item>

     </Col>
     

   
     <Col lg = {8} md = {24}>
        <Form.Item
        name="DEL_ADD"
        colon = {false}
        style = {{margin: "0", padding: "0"}}
        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >DELIVERY ADDRESS</div>}
        rules={[
         {
           required: true,
           message: 'Please input your delivery address!',
         },
             ]}
       >
       <Input 
        bordered = {false}
        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
        />
      </Form.Item>
  </Col>
  <Col lg = {8} md = {24}>
      <Form.Item
      name="CITY_CODE"
      colon = {false}
      style = {{margin: "0", padding: "0"}}
      label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className = {classes['Label']} >CITY</div>}
      rules={[
      {
      required: true,
      message: 'Please input your Party Weight!',
       },
           ]}
      >
     <Input
     bordered = {false}
    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
     />
    </Form.Item>
  </Col>
      </Row>
    
      </Form>
                                                
   
</>
   
  );
};

export default BasicDetails;
