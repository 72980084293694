import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const data = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = {...data['customerDetails']}
            newdata[param] = e.target.value
            return({
                ...data,
                customerDetails : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = {...data['customerDetails']}
            newdata[param] = val
            return({
                ...data,
                customerDetails : newdata
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = {...data['customerDetails']}
            newdata[param] = dateString
            return({
                ...data,
                customerDetails : newdata
            })
        })
    }

    return(
        <div>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} disabled = {true} name = "NAME" param = "DISTRIBUTOR_NAME" value = {props.data.DISTRIBUTOR_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "DEBITOR GROUP" param = "EXTERNAL_ENTITY_GROUP_CODE" value = {props.data.EXTERNAL_ENTITY_GROUP_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "CUSTOMER TYPE" param = "EXT_ENTITY_TYPE_CODE" value = {props.data.EXT_ENTITY_TYPE_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true} handleChange = {handleDChange} type = "Date" name = "DATE OF BIRTH" param = "DOB" value = {props.data.DOB}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} disabled = {true} handleChange = {handleDChange} type = "Date" name = "DATE OF JOINING" param = "DOM" value =  {props.data.DOM}/>            
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} name = "ADDRESS" param = "ADDRESS" value = {props.data.ADDRESS}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "LOCALITY" param = "LOCALITY" value = {props.data.LOCALITY}/>            
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "CITY" param = "CITY" value = {props.data.CITY}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "PIN" param = "PIN_NO" value ={props.data.PIN_NO}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "STATE" param = "STATE" value ={props.data.STATE}/>   
            </Row>
            <p></p>
            <Row className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "PHONE" param = "PHONE" value = {props.data.PHONE}/>            
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "MOBILE" param = "MOBILE" value = {props.data.MOBILE}/>  
            </Row>
            <p></p>
            <Row className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} disabled = {true} name = "EMAIL" param = "EMAIL" value = {props.data.EMAIL}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "URL" param = "URL" value ={props.data.URL}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "FAX" param = "FAX" value = {props.data.FAX}/>        
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HSN No." param = "HSN" value = {props.data.HSN}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAN No." param = "PAN_NO" value = {props.data.PAN_NO}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "GST No." param = "DISTRIBUTOR_NAME" value = "---"/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "ECC No." param = "ECC_NO" value = {props.data.ECC_NO}/>          
            </Row>
            <p></p>
            <Row className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "BANK NAME" param = "BANK_NAME" value = {props.data.BANK_NAME}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "ACCOUNT NO." param = "ACC_NO" value = {props.data.ACC_NO}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "IFSC Code" param = "IFSC_CODE" value = {props.data.IFSC_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "TAN No." param = "TAN_NO" value = {props.data.TAN_NO}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "CREDIT LIMIT" param = "CREDIT_LIMIT" value = {props.data.CREDIT_LIMIT}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "CREDIT DAYS" param = "CREDIT_DAYS" value = {props.data.CREDIT_DAYS}/>          
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDE'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "ACCOUNT GROUP CODE" param = "ACC_GROUP_CODE" value = {props.data.ACC_GROUP_CODE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT DAYS" param = "PAYMENT_DAYS" value = {props.data.PAYMENT_DAYS}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} disabled = {true} type = "Date" name = "CST. Date" param = "CST_DATE" value = {props.data.CST_DATE}/>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} disabled = {true} name = "CST No." param = "CST_NO" value = {props.data.CST_NO}/>  
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} disabled = {true} name = "MONTHLY INTEREST RATE" param = "MONTHLY_INTREST" value = {props.data.MONTHLY_INTEREST}/>
                <DataField editMode = {props.editMode} lg = {24} md = {24} handleChange = {handleChange} disabled = {true} name = "YEARLY INTEREST RATE." param = "YEARLY_INTREST" value = {props.data.YEARLY_INTEREST}/>          
            </Row>
            <p></p>
        </div>
    );
}

export default data