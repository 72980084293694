import {Row, Col, Form, Input, Space, Button, DatePicker, Select, InputNumber} from 'antd';
import classes from '../Pages.module.css';
import { MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';

const {Option} = Select

const BookingSizeEdit = (props) => {

    const handleValueChanges = (changedValues, allValues) => {
        props.setData(data => {
            return({
                ...data,
                bookingSize: allValues.bookingSize
            })
        })
    }

    return(
        <Form layout="vertical" name="filter_form" onValuesChange = {handleValueChanges} autoComplete="off">
            <Form.List name="bookingSize" initialValue = {props.data}>
                {(fields, { add, remove }) => { 
                    
                    return(
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField}) => (
                        <Space key={key} className = {classes['Space']} align="center">
                            <Row gutter = {16}>
                                <p></p>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'ITEM_CODE']}
                                        fieldKey={[fieldKey, 'ITEM_CODE']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ITEM</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Select
                                            bordered = {false}
                                            style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            placeholder="Select"
                                            optionFilterProp="children"
                                        >
                                            {
                                                props.itemOptions.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.itemOptions.metaData[0].name]} value={option[props.itemOptions.metaData[0].name]}>{option[props.itemOptions.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'SIZE_CODE']}
                                        fieldKey={[fieldKey, 'SIZE_CODE']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>ITEM SIZE</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Select
                                            bordered = {false}
                                            style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            placeholder="Select"
                                            optionFilterProp="children"
                                        >
                                            {
                                                props.sizeOptions.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.sizeOptions.metaData[0].name]} value={option[props.sizeOptions.metaData[0].name]}>{option[props.sizeOptions.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'QUALITY']}
                                        fieldKey={[fieldKey, 'QUALITY']}
                                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>QUALITY</div>}
                                        rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                        <Select
                                            bordered = {false}
                                            style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                            placeholder="Select"
                                            optionFilterProp="children"
                                        >
                                            {
                                                props.qualityOptions.rows.map((option) => {
                                                    return(
                                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.qualityOptions.metaData[0].name]} value={option[props.qualityOptions.metaData[0].name]}>{option[props.qualityOptions.metaData[1].name]}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg = {8} md = {24}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'NO_PCS']}
                                    fieldKey={[fieldKey, 'NO_PCS']}
                                    label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>NO PCS</div>}
                                    rules={[
                                        { required: true,
                                         message: 'Field should not be blank!!'
                                        },
                                        {
                                            type: 'number',
                                            message:"please enter only numeric value"
                                          },
                                    
                                    ]}
                                >
                                    <Input
                                        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                        bordered = {false}
                                        placeholder="Enter NO PCS"
                                    />
                                </Form.Item>
                            </Col>
                                <Col lg = {8} md = {24}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'QTY']}
                                    fieldKey={[fieldKey, 'QTY']}
                                    label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>QUANTITY</div>}
                                    rules={[
                                        { required: true,
                                         message: 'Field should not be blank!!'
                                        },
                                        {
                                            type: 'number',
                                            message:"please enter only numeric value"
                                          },
                                    
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                        bordered = {false}
                                        placeholder="Enter Earning"
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg = {8} md = {24}>
                            <Form.Item
                                {...restField}
                                name={[name, 'BOOK_RATE_GAUGE']}
                                fieldKey={[fieldKey, 'BOOK_RATE_GAUGE']}
                                label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>Order Rate</div>}
                                rules={[
                                    { required: true,
                                     message: 'Field should not be blank!!'
                                    },
                                    {
                                        type: 'number',
                                        message:"please enter only numeric value"
                                      },
                                
                                ]}
                            >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter order rate"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg = {6} md = {12}>
                        <Form.Item
                            {...restField}
                            name={[name, 'DISCOUNT_ON']}
                            fieldKey={[fieldKey, 'DISCOUNT_ON']}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DISCOUNT ON</div>}
                            rules={[{ required: true, message: 'Missing Name' }]}
                        >
                            <Select
                                bordered = {false}
                                style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                placeholder="Select"
                                optionFilterProp="children"
                            >
                            {
                                props.options.rows.map((option) => {
                                    return(
                                        <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.options.metaData[0].name]} value={option[props.options.metaData[0].name]}>{option[props.options.metaData[1].name]}</Option>
                                    );
                                })
                            }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg = {8} md = {24}>
                    <Form.Item
                        {...restField}
                        name={[name, 'DISCOUNT_AMOUNT']}
                        fieldKey={[fieldKey, 'DISCOUNT_AMOUNT']}
                        label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DISCOUNT</div>}
                        rules={[{ required: true, message: 'Missing Name' }]}
                    >
                        <Input
                            style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                            bordered = {false}
                            placeholder="Enter "
                        />
                    </Form.Item>
                </Col>
                <Col lg = {6} md = {12}>
                <Form.Item
                {...restField}
                name={[name, 'DIS_TYPE']}
                fieldKey={[fieldKey, 'DIS_TYPE']}
                label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DISCOUNT TYPE</div>}
                rules={[{ required: true, message: 'Missing Name' }]}
            >
                <Select
                    bordered = {false}
                    style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                    placeholder="Select"
                    optionFilterProp="children"
                >
                {
                    props.options.rows.map((option) => {
                        return(
                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.options.metaData[0].name]} value={option[props.options.metaData[0].name]}>{option[props.options.metaData[1].name]}</Option>
                        );
                    })
                }
                </Select>
            </Form.Item>
            </Col>

                        <Col lg = {8} md = {24}>
                        <Form.Item
                            {...restField}
                            name={[name, 'TOT_ITEM_AMT']}
                            fieldKey={[fieldKey, 'TOT_ITEM_AMT']}
                            label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>Order Amt</div>}
                            rules={[
                                { required: true,
                                 message: 'Field should not be blank!!'
                                },
                                {
                                    type: 'number',
                                    message:"please enter only numeric value"
                                  },
                            
                            ]}
                        >
                            <Input
                                style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                bordered = {false}
                                placeholder="Enter Earning"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg = {8} md = {24}>
                            <Form.Item
                                {...restField}
                                name={[name, 'NET_RATE']}
                                fieldKey={[fieldKey, 'NET_RATE']}
                                label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>Net Rate</div>}
                                rules={[
                                    { required: true,
                                     message: 'Field should not be blank!!'
                                    },
                                    {
                                        type: 'number',
                                        message:"please enter only numeric value"
                                      },
                                
                                ]}
                            >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Earning"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg = {8} md = {24}>
                            <Form.Item
                                {...restField}
                                name={[name, 'NET_SIZE_RATE']}
                                fieldKey={[fieldKey, 'NET_SIZE_RATE']}
                                label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>Net Amount</div>}
                                rules={[
                                    { required: true,
                                     message: 'Field should not be blank!!'
                                    },
                                    {
                                        type: 'number',
                                        message:"please enter only numeric value"
                                      },
                                
                                ]}
                            >
                                <Input
                                    style={{ width: "100%" , float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    bordered = {false}
                                    placeholder="Enter Net Amount"
                                />
                            </Form.Item>
                        </Col>
                              
                              
                               
                                <Col lg = {21} md = {0}>
                                    
                                </Col>
                                <Col lg = {1} md = {24}>
                                    <MinusCircleOutlined className = {classes['Remove']} onClick={() => { remove(name) }} />
                                </Col>
                                <Col lg = {1} md = {24}>
                                    <PlusCircleOutlined className = {classes['Add']} onClick={() => {add()}} />
                                </Col>
                                <Col lg = {1} md = {24}>
                                    
                                </Col>
                                <Col lg = {24} md = {24}>
                                    <hr></hr>
                                </Col>
                            </Row>                                                     
                        </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" className = {classes['DashedButton']}onClick={() => {add()}}>
                                Add Booking
                            </Button>
                        </Form.Item>
                    </>
                )}}
            </Form.List>
        </Form>
    )
}

export default (BookingSizeEdit)