import {Row} from 'antd';
import classes from '../Pages.module.css';
import SalaryAdvanceDataFeild from './SalaryAdvanceDataFeild'


const BasicSalaryAdvanceDetails = (props) => {



    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = dateString
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = val
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 

            {!props.create && <SalaryAdvanceDataFeild editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ADVANCE_CODE" param = "ADVANCED_CODE" value = {props.data.ADVANCE_CODE ? props.data.ADVANCE_CODE : null }/> }
                <SalaryAdvanceDataFeild editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.EMP_CD} type = "Select" name = "EMPLOYEE NAME" param = "EMP_CD" value = {props.data.EMP_CD ? props.data.EMP_CD : null }/>
                <SalaryAdvanceDataFeild editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ADV_MONTH} type = "Select" name = "ADVANCE MONTH" param = "ADV_MONTH" value = {props.data.ADV_MONTH ? props.data.ADV_MONTH: null }/> 
                <SalaryAdvanceDataFeild editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ADV_YEAR} type="Select" name = "ADVANCE YEAR" param = "ADV_YEAR" value = {props.data.ADV_YEAR ? props.data.ADV_YEAR: null }/> 
                <SalaryAdvanceDataFeild editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange}  name = "ADVANCE AMOUNT" param = "ADV_AMOUNT" value = {props.data.ADV_AMOUNT ? props.data.ADV_AMOUNT : null }/> 
                <SalaryAdvanceDataFeild editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CARD NO" param = "CARD_NO" value = {props.data.CARD_NO ? props.data.CARD_NO : null }/> 
 </Row>
            
        </div>
    );
}


export default BasicSalaryAdvanceDetails;
