import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField'


const BasicChargeDetails = (props) => {



    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = dateString
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = val
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 

            {!props.create && <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CHARGE CODE" param = "UNIQ_CODE" value = {props.data.UNIQ_CODE ? props.data.UNIQ_CODE : null }/> }
                <DataField editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.INVOICE_TYPE_CODE} type = "Select" name = "INVOICE_TYPE" param = "INVOICE_TYPE_CODE" value = {props.data.INVOICE_TYPE_CODE ? props.data.INVOICE_TYPE_CODE : null }/>
              
 </Row>
            
        </div>
    );
}


export default BasicChargeDetails;
