import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const BasicDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['itemMaster']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                itemMaster : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['itemMaster']]
            newdata[0][param] = val
            return({
                ...data,
                itemMaster : newdata
            })
        })
    }




    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                { !props.create && <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ITEM CODE" param = "ITEM_CODE" value = {props.data.ITEM_CODE}/> }
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ITEM NAME" param = "ITEM_NAME" value = {props.data.ITEM_NAME}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ITEM_UOM} type = "Select" name = "UOM" param = "ITEM_UOM" value = {props.data.ITEM_UOM}/>  
              <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ITEM_CATEGORY} type = "Select" name = "CATEGORY" param = "ITEM_CATEGORY" value = {props.data.ITEM_CATEGORY}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HSN NO" param = "HSN" value = {props.data.HSN}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_GROUP_CD} type = "Select" name = "GROUP" param = "ITEM_GROUP_CD" value = {props.data.ITEM_GROUP_CD}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_SUBGROUP_CODE} type = "Select" name = "SUBGROUP" param = "ITEM_SUBGROUP_CODE" value = {props.data.ITEM_SUBGROUP_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_RATING_CODE} type = "Select" name = "RATING" param = "ITEM_RATING_CODE" value = {props.data.ITEM_RATING_CODE}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ACTUAL NAME" param = "ACTUAL_NAME" value = {props.data.ACTUAL_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "MIN LEVEL" param = "ITEM_MIN_QTY" value = {props.data.ITEM_MIN_QTY}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "MAX LEVEL" param = "ITEM_MAX_QTY" value = {props.data.ITEM_MAX_QTY}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "REORDER LEVEL" param = "REORDER_LEVEL" value = {props.data.REORDER_LEVEL}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "AVG REODER LEVEL" param = "AVG_REORDERQTY" value = {props.data.AVG_REORDERQTY}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_HOME_YN} type = "Select" name = "PRIORITY" param = "ITEM_HOME_YN" value = {props.data.ITEM_HOME_YN}/>          
            </Row>
            
            
        </div>
    );
}

export default BasicDetails;
