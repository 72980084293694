import { useEffect, useContext, useState } from 'react';
import { Skeleton, Spin, Alert} from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import DataContext from '../../Context/dataContext';
import classes from './AllReports.module.css';
import {Link} from 'react-router-dom';
import Functions from './Functions';

const AllReports = (props) => {

    const mainData = useContext(DataContext);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [func, setFunc] = useState(mainData.allReports ? mainData.allReports.map((report) => {return(false);}) : [])
    const [exporting, setExporting] = useState(mainData.allReports ? mainData.allReports.map((report) => {return(false);}) : [])

    useEffect(() => {

        props.onModuleChangeHandler("All Reports");
        props.onTitleChangeHandler("All Reports");
        mainData.setParentReportData({
            report_cns: 0
        })
    },[]);    

    const HandleEnter = (event, index) => {
        setFunc(func => {
            let newFuncs = [...func]
            newFuncs[index] = true
            return newFuncs
        })
    }

    const HandleLeave = (event, index) => {
        setFunc(func => {
            let newFuncs = [...func]
            newFuncs[index] = false
            return newFuncs
        })
    }

    const HandleExport = (index) => {

        setExporting(exporting => {
            let newExporting = [...exporting]
            newExporting[index] = !newExporting[index]
            return newExporting
        })
    }

    return(
        <>
            
            {
                !mainData.allReports
                ?
                <>
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                    <Skeleton active = {true} />
                </>
                :
                <> 
                    {error&&<Alert style = {{backgroundColor: "#ff9494", color: "white"}} type="error" message="An Error Occurred" banner closable />}
                    {
                        mainData.allReports && mainData.allReports.map((report, index) => {
                            return(
                                <>  
                                    <Link key = {index} to = {'/reports/' + report.CATEGORY.toLowerCase() + '/' +report.SLUG}>
                                        <card onMouseEnter = {(event) => HandleEnter(event,index)} onMouseLeave = {(event) => HandleLeave(event,index)} className = {classes['MainCard']} key = {report.REPORT_CODE}>
                                            {
                                                !loading
                                                ?
                                                <>
                                                    <div className = {classes['Name']} >
                                                        {report.REPORT_NAME.toLowerCase()} | 
                                                        <span className = {classes['Span']}>{report.REPORT_FORMAT ? report.REPORT_FORMAT.toUpperCase() : ""}</span> |
                                                        <span style = {{ color: "#1777C4"}} className = {classes['Span']}>{report.CATEGORY}</span> |
                                                        <span className = {classes['Desc']}>{report.DESCRIPTION}</span>
                                                        <span>
                                                            <Link to = "#">
                                                                <Functions index = {index} setExporting = {HandleExport} del = {report.DELETE_POS} name = {report.REPORT_NAME} exporting = {exporting[index]} func = {func[index]} code = {report.REPORT_CODE} link = {'/reports/' + report.CATEGORY.toLowerCase() + '/' +report.SLUG} slug = {report.SLUG} setError = {setError} setLoading = {setLoading}/>  
                                                            </Link>
                                                        </span>
                                                    </div>
                                                </>
                                                :
                                                <Spin style = {{padding: "1rem 1rem"}} size="large" />
                                            }
                                        </card>
                                    </Link>                                   
                                </> 
                            );
                        })
                    }
                </>         
            }     

        </>
    );
}

export default AllReports