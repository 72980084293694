import {Row} from 'antd';
import classes from '../Pages.module.css';
import NormDataField from './NormDataField'

const BasicLeaveDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = dateString
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['header']['rows']]
            newdata[0][param] = val
            return({
                ...data,
                header : {
                    ...data.header,
                    rows: newdata
                }
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.EMP_CODE} type = "Select" name = "EMPLOYEE NAME" param = "EMP_CODE" value = {props.data.EMP_CODE ? props.data.EMP_CODE : null }/> 
                {!props.create && <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "NORMS CODE" param = "NORMS_HDR_CODE" value = {props.data.NORMS_HDR_CODE ? props.data.NORMS_HDR_CODE : null }/> }
                <NormDataField editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.EMP_TYPE} type = "Select" name = "EMPLOYEE TYPE" param = "EMP_TYPE" value = {props.data.EMP_TYPE ? props.data.EMP_TYPE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "AGE" param = "AGE" value = {props.data.AGE ? props.data.AGE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.GENDER} type = "Select" name = "GENDER" param = "GENDER" value = {props.data.GENDER ? props.data.GENDER : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.DEPARTMENT_CODE} type = "Select" name = "DEPARTMENT" param = "DEPARTMENT_CODE" value = {props.data.DEPARTMENT_CODE ? props.data.DEPARTMENT_CODE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.DESIGNATION_CODE} type = "Select" name = "DESIGNATION" param = "DESIGNATION_CODE" value = {props.data.DESIGNATION_CODE ? props.data.DESIGNATION_CODE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24}  handleChange = {handleSChange} options = {props.ad.UNIT_CODE} type = "Select" name = "SITE" param = "UNIT_CODE" value = {props.data.UNIT_CODE ? props.data.UNIT_CODE : null }/> 
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ASSOCIATED COMPANY" param = "ASSOCIATED_COMP" value = {props.data.ASSOCIATED_COMP ? props.data.ASSOCIATED_COMP : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ASSOCIATED COMPANY TO" param = "ASSOCIATED_COMP_TO" value = {props.data.ASSOCIATED_COMP_TO ? props.data.ASSOCIATED_COMP_TO : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "TO DATE" param = "TO_DATE" value = {props.data.TO_DATE ? props.data.TO_DATE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "FROM DATE" param = "FROM_DATE" value = {props.data.FROM_DATE ? props.data.FROM_DATE : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "DISTANCE FROM NATIVE PLACE" param = "DISTANCEFROM" value = {props.data.DISTANCEFROM ? props.data.DISTANCEFROM : null }/> 
                <NormDataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "DISTANCE TO NATIVE PLACE" param = "DISTANCETO" value = {props.data.DISTANCETO ? props.data.DISTANCETO : null }/> 
            </Row>
            <p></p>
        </div>
    );
}

export default BasicLeaveDetails