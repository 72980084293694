import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';

const OtherDetails = (props) => {

    const handleChange = (e,param) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = e.target.value
            return({
                ...data,
                employeeMaster : newdata
            })
        })
    }

    const handleDChange = (date, dateString, param) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = dateString
            return({
                ...data,
                employeeMaster : newdata
            })
        })
    }

    const handleSChange = (val,param) => {

        props.setData(data => {
            const newdata = [...data['employeeMaster']]
            newdata[0][param] = val
            return({
                ...data,
                employeeMaster : newdata
            })
        })
    }

    return(
        <div>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "DATE OF BIRTH" param = "DOB" value = {props.data.DOB}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.GENDER} type = "Select" name = "GENDER" param = "SEX" value = {props.data.SEX}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "FATHER'S NAME" param = "FATHER_NAME" value = {props.data.FATHER_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "MOTHER'S NAME" param = "MOTHER_NAME" value = {props.data.MOTHER_NAME}/>   
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.RELIGION_CODE} type = "Select" name = "RELIGION" param = "RELIGION_CODE" value = {props.data.RELIGION_CODE}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "NATIONALITY" param = "NATIONALITY" value = {props.data.NATIONALITY}/>    
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "WEDDING DATE" param = "DOW" value = {props.data.DOW}/>        
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.MARITIAL_STATUS} type = "Select" name = "MARITIAL STATUS" param = "MARITAL_STATUS" value = {props.data.MARITAL_STATUS}/>    
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "BLOOD GROUP" param = "BLOOD_GROUP" value = {props.data.BLOOD_GROUP}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.HANDICAPPED} type = "Select" name = "HANDICAPPED" param = "HANDICAPPED" value = {props.data.HANDICAPPED}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HEIGHT" param = "HEIGHT" value = {props.data.HEIGHT}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "WEIGHT" param = "WEIGHT" value = {props.data.WEIGHT}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "MEDICLAIM" param = "MEDICLAIM_AMOUNT" value = {props.data.MEDICLAIM_AMOUNT}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "DISPENSARY" param = "DISPENSARY" value = {props.data.DISPENSARY}/>          
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "BANK NAME" param = "BANK_NAME" value = {props.data.BANK_NAME}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "BRANCH" param = "BRANCH_ADDRESS" value = {props.data.BRANCH_ADDRESS}/> 
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "NAME in BANK" param = "NAME_IN_BANK" value = {props.data.NAME_IN_BANK}/> 
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "IFSC CODE" param = "IFSC_CODE" value = {props.data.IFSC_CODE}/> 
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "ACCOUNT NO." param = "BANK_ACCOUNT_NO" value = {props.data.BANK_ACCOUNT_NO}/>          
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "FILE NO." param = "FILE_NO" value = {props.data.FILE_NO}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ESI NO." param = "ESI_NUMBER" value = {props.data.ESI_NUMBER}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAN NO." param = "PAN_NUMBER" value = {props.data.PAN_NUMBER}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "CARD NO." param = "CARD_NO" value = {props.data.CARD_NO}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PROVIDENT FUND NO." param = "PROVIDENT_FUND_NO" value = {props.data.PROVIDENT_FUND_NO}/>    
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "UAN" param = "UAN" value = {props.data.UAN}/>              
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "JOINING DATE" param = "DOJ" value = {props.data.DOJ}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "LEAVING DATE" param = "DOL" value = {props.data.DOL}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} type = "Date" name = "CONFIRMATION DATE" param = "DOC" value = {props.data.DOC}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "INSURANCE AMOUNT" param = "INSURANCE_AMOUNT" value = {props.data.INSURANCE_AMOUNT}/> 
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TERM INSURANCE VALUE" param = "TERM_INSURANCE_VALUE" value = {props.data.TERM_INSURANCE_VALUE}/>    
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TERM INSURANCE PREMIUM" param = "TERM_INSURANCE_PREMIUM" value = {props.data.TERM_INSURANCE_PREMIUM}/>              
            </Row>
            <p></p>
            <Row className = {props.editMode ? classes['RowDEX'] : classes['RowD']}>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "REPORT TO" param = "REPORTING_TO" value = {props.data.REPORTING_TO}/> 
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "REFERENCE" param = "REFERENCE" value = {props.data.REFERENCE}/>
                <DataField editMode = {props.editMode} lg = {8} md = {24} handleChange = {handleChange} name = "OVER TIME" param = "OVERTIME" value = {props.data.OVERTIME}/>   
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PROBATION PERIOD" param = "PROBATION_PERIOD" value = {props.data.PROBATION_PERIOD}/>  
                <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "DISTANCE FROM NATIVE" param = "DISTANCE_FROM_NATIVE" value = {props.data.DISTANCE_FROM_NATIVE}/>                  
            </Row>
            <p></p>
        </div>
    );
}

export default OtherDetails