import {Row} from 'antd';
import classes from '../Pages.module.css';
import DataField from './DataField';
import ItemDetailsEdit from './ItemDetailsEdit';

const ContactDetails = (props) => {
    return(
        !props.editMode
        ?
        props.data.map((data,index) => {
            return(
                <div key = {index}>
                     <Row className = { props.editMode ? classes['RowDE'] : classes['RowD']}>
                        <DataField editMode = {props.editMode} lg = {24} md = {24} options = {props.ad.ITEM_CD} type = "Select" name = "ITEM NAME" value = {data.ITEM_CD}/> 
                        <DataField editMode = {props.editMode} lg = {12} md = {24} options = {props.ad.SIZE_CODE} type = "Select" name = "SIZE" value = {data.SIZE_CODE}/>  
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "ISI" value = {data.ISI_APPROVED}/> 
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "AUTHORIZED" value = {data.AUTHORIZED}/>    
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "WARRANTY" value ={data.WARRANTY}/>    
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "VENDOR STATUS" value = {data.VENDOR_STATUS}/> 
                        <DataField editMode = {props.editMode} lg = {12} md = {24} name = "APPROVAL DATE" value = {!data.APPROVAL_DATE ? null : data.APPROVAL_DATE.format('DD-MM-YYYY')}/>                
                    </Row>
                </div>
            );
        })
        :
        <ItemDetailsEdit itemOptions = {props.ad.ITEM_CD} sizeOptions = {props.ad.SIZE_CODE} data = {props.data} setData = {props.setData} />
    );
}

export default ContactDetails